import React from 'react';

const ThemeStyles = ({ theme }) => {
        return theme !== undefined  ? ( <style dangerouslySetInnerHTML={{__html: `
        * {
          --primary-color: ${theme.primary};
          --primary-color-dark: ${theme.primary_dark};
          --text-header: ${theme.text_header};
          --text-underline: ${theme.text_underline};
          --text-secondary: ${theme.text_secondary};
          --white-text-highlight: ${theme.white_highlight_color};
          --danger-text: ${theme.danger_text};
          --danger-text-dark: ${theme.danger_text_dark};
          --input-color: ${theme.input_color};
          --select-down-svg: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="80" height="55" viewBox="0 0 21.2 14.4"><g image-rendering="auto"><path d="M5.1 9.3 0 4.2v-2C0 1.1 0.1 0.1 0.2 0c0.1-0.1 2.5 2.1 5.2 4.9l5 5 5.1-5.1c2.9-2.8 5.2-5 5.4-4.9 0.2 0.1 0.3 1 0.3 2.1v1.9l-5.2 5.2c-2.9 2.9-5.3 5.2-5.5 5.2-0.1 0-2.6-2.3-5.4-5.1z" style="fill:${theme.input_color.replace('#', '%23')};stroke-width:0.3"/></g></svg>');
        }
        .app-container.show-bgi {
          background-image: url(${theme.box_bgi});
          background-repeat: no-repeat;
          background-size: cover;
          background-blend-mode: multiply;
        }
        
        .app-header *, .modal-header * {
          --header-color: ${theme.header_color};
          --header-color-dark: ${theme.header_color_dark};
          --header-text: ${theme.header_text};
        }
        `}} /> 
    ) : false
}
export default ThemeStyles;