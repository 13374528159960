import React from 'react';
import { 
    DropdownButton,
    MenuItem
} from 'react-bootstrap';

import AppContext from '../../appcontext';

export class LangChangeSelect extends React.PureComponent {
    static contextType = AppContext;

    render(){
        return(
            <div className={"langselect-container " + (this.props.containerClass !== undefined ? this.props.containerClass : '') } >
                <DropdownButton
                    bsStyle="link"
                    title={this.props.lang === 'fr'? 'Français': 'English'}
                    id={`lang-select-dropdown`}
                    >
                    <MenuItem eventKey="1" onClick={()=>this.context.changeLanguage('en')}>English</MenuItem>
                    <MenuItem eventKey="2" onClick={()=>this.context.changeLanguage('fr')}>Fran&ccedil;ais</MenuItem>
                </DropdownButton>
            </div>
        )
    }
}
