import React from 'react';
import { Route, Redirect, Switch, withRouter} from 'react-router-dom';

import AppContext from './appcontext';

import Login from './containers/login/login';
import ResetPassword from './containers/resetpassword/resetpassword';
import SQ from './containers/sq/sq';
import DeviceTokens from './containers/devicetokens/devicetokens';

import Activate from './containers/activate/activate';
import SelectAccount from './containers/selectaccount/selectaccount';

import Dashboard from './containers/dashboard/dashboard';
import Portfolios from './containers/portfolios/portfolios';
import Performance from './containers/performance/performance'
import Documents from './containers/documents/documents';

import AccountSettings from './containers/settings/accountsettings';
import SecuritySettings from './containers/settings/securitysettings';

import NotFound from './containers/notfound/notfound';

const state_routing = (state, state_order, location) => {

    /* The Routes for state redirection */
    let state_routes = { 
        'sq': '/profile/security-questions',
        'token': '/profile/device-token'
    }

    /* Loop over the state order*/
    for ( let s of state_order){
        /* Check State Status */
        if ( (state[s] !== undefined && state[s] === 0 && state_routes[s] !== undefined) || (state[s] === undefined && state_routes[s] !== undefined) ){
            /* Check we aren't currently there */
            if(location !== state_routes[s]){
                return <Redirect key="stateroute" to={state_routes[s]} />;
            }else{
                return false
            }
        }
    }

    return false;
}


class Routes extends React.Component{

    render(){
        const context = {...this.props.context, navigate: this.props.history.push}
        return (
            <AppContext.Provider value={context}>
                <Switch>

                    { /* Unauthenticated Routes */}
                    <Route path="/login" exact component={Login} />
                    <Route path="/resetpassword" exact component={ResetPassword} />
                    <Route path="/activate/:code" exact component={Activate} />

                    {/* Authenticated Routes */}
                    {this.props.context.isAuthenticated === true ? [

                        this.props.context.account_selected === false && window.location.pathname !== '/select-accounts' ? <Redirect key="selectaccountsredirect" to={'/select-accounts'} /> : <Route key="selectaccounts" path="/select-accounts" exact component={SelectAccount} />,

                        /* This manages the state based redirection */
                        this.props.context.user_state !== null ? state_routing(this.props.context.user_state, this.props.context.state_order, window.location.pathname) : false,

                        /* Base Routes */
                        <Route key="home" path="/" exact component={Dashboard} />,
                        
                        /* Profile Routes */
                        <Route key="securityquestions" path="/profile/security-questions" exact component={SQ} />,
                        <Route key="devicetokens" path="/profile/device-token" exact component={DeviceTokens} />,

                        <Route key="performance" path="/performance" exact component={Performance} />,
                        <Route key="portfolios" path="/portfolios" exact component={Portfolios} />,
                        <Route key="documents" path="/documents" exact component={Documents} />,

                        <Route key="accountsettings" path="/settings(/account)?" exact component={AccountSettings} />,
                        <Route key="securitysettings" path="/settings/security" exact component={SecuritySettings} />,

                        /* Finally, catch all unmatched routes */ 
                        <Route key="notfound" component={ NotFound } />
                    ] : <Redirect to={{
                        pathname: "/login",
                        state: { referrer: window.location.pathname }
                    }}/>}

                </Switch>
            </AppContext.Provider>
        )
    }
}

export default withRouter(Routes);