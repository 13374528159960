import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
//import DatePicker from 'react-datepicker';

// import { 
//     FormControl,
//     FormGroup,
//     OverlayTrigger,
//     Popover
// } from 'react-bootstrap'

import { FillLayout } from '../layout/layout.js';
import { invokeApi } from '../../libs/authlib';

//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Trans } from 'react-i18next';
import AppContext from '../../appcontext';

//import { GrowthOfWealthChart } from '../charts/growthofwealth';
import { PortfolioAssetBucketDoublePie } from '../portfolios/portfolioscharts';
import { ComponentLoadingPlaceholder } from '../components/loadingplaceholder';

import "./dashboard.css"

/* Dashboard with Container */
class Dashboard extends React.Component {
  static contextType = AppContext;

  render() {
    return (  
        <div className="dashboard-container">
          <FillLayout containerClassName="dashboard-box">
            <DashboardContainer />
          </FillLayout>
        </div>
    );
  }
}

export default Dashboard;

class DashboardContainer extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    
    this.state = {
      active_account: 'summary',
      accounts_loading: true,
      accounts_list: {},
      account_stats: {},
      pf_summary: {},
      pf_summary_loading: true,
      timewindow: 12,
      start_date: moment().subtract(12, 'months').subtract(1, 'days').toDate(),
      end_date: moment().subtract(1, 'days').toDate(),
      max_start: new Date(1970, 1, 1),
      max_end: moment().subtract(1, 'days').toDate(),
      disable_dates: false,
    }
  }

  componentDidMount(){
    this.signal = this.context.axiosCancel();

    if ( this.context.user_account !== undefined) {
      this.getAccounts(this.formatAccounts, moment().subtract(12, 'months').subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate());
      this.getPfSummary()
    } else {
      this.setState({ loading_error: this.context.i18n.t("No account provided.")  });
    }
  }


  componentWillUnmount() {
    this.signal.cancel();
  }

  async getAccounts(success_callback, start=null, end=null){
    try{
      let path = '/client/' + this.context.user_account + "/portfolios/detail?g=managed";

      if (start !== null || end !== null) {

        if (start !== null){
          path = path + "&s=" + moment(start).format("YYYY-MM-DD");
        }
        if (start !== null && end !== null) {
          path = path + "&"
        }

        if (end !== null){
          path = path + "e=" + moment(end).format("YYYY-MM-DD");
        }
      }

      await invokeApi({
         path: path,
         method: 'GET',
         onSuccess: r => success_callback(r),
         onError: e => this.setState({ accounts_loading : false, loading_error : e })
      });
    }catch(e){
      console.error(e);
    }
  }

  formatAccounts = (accounts) => {
    let accounts_list = {};
    let acct_stats = {};
    let dates = { start_date: false, end_date: false };

    if ( accounts === null) {
      accounts = [];
    }

    for (let i=0; i < accounts.length; i++) {
      let acct = accounts[i];
      if (acct['pfDataFeed']) {
        accounts_list[acct['pf_uuid']] = acct;
        if ( acct['pfTimeStats'] !== undefined ){
          acct_stats[acct['pf_uuid']] = acct['pfTimeStats'];
        }
      }
      if (acct['pfSummary'] !== undefined && acct['pfSummary']) {
        
        dates = acct['pfDates'];
        accounts_list['summary'] = acct;
        acct_stats['summary'] = acct['pfTimeStats'];
      }
      if (acct['pfGroup'] !== undefined && acct['pfGroup']) {

        accounts_list[acct['pf_uuid']] = acct;
        acct_stats[acct['pf_uuid']] = acct['pfTimeStats'];
      }
    }

    this.setState({ 
      accounts_list: accounts_list,
      account_stats: acct_stats, 
      accounts_loading : false,
      disable_dates: !(accounts.length > 0),
      start_date: dates['start_date'] !== undefined ? moment.max(moment(new Date(dates['start_date'])), moment().subtract(12, 'months').subtract(1, 'days')).toDate(): moment().subtract(1, 'days').toDate(),
      end_date: moment().subtract(1, 'days').toDate(),
      max_start: dates['start_date'] !== undefined ? moment.min(moment(new Date(dates['start_date'])), moment().subtract(12, 'months').subtract(1, 'days')).toDate(): moment().subtract(1, 'days').toDate()
    });
  }


  updateAccounts = (accounts) => {
    let acct_stats = {};

    for (let i=0; i < accounts.length; i++) {
      let acct = accounts[i];
      if (acct['pfDataFeed']) {
        if ( acct['pfTimeStats'] !== undefined ){
          acct_stats[acct['pf_uuid']] = acct['pfTimeStats'];
        }
      }
      if (acct['pfSummary'] !== undefined && acct['pfSummary']) {
        acct_stats['summary'] = acct['pfTimeStats'];
      }
      if (acct['pfGroup'] !== undefined && acct['pfGroup']) {
        acct_stats[acct['pf_uuid']] = acct['pfTimeStats'];
      }
    }

    this.setState({
      account_stats: acct_stats, 
    });
  }

  async getPfSummary(){
    try{
      let path = '/client/' + this.context.user_account + "/chartdata/dashpfsummary";

      await invokeApi({
         path: path,
         method: 'POST',
         body: {},
         onSuccess: r => this.setState({ pf_summary: r, pf_summary_loading: false }),
         onError: e => this.setState({ accounts_loading : false, accounts_updating : false, loading_error : e })
      });
    }catch(e){
      console.error(e);
    }
  }

  changeActiveAccount=(account)=>{
    if (this.state.accounts_list[account]['pfDates'] !== undefined){
      this.setState({ active_account: account, disable_dates: false });
      this.updateTimewindow(this.state.timewindow, account);
    }else{
      this.setState({ active_account: account, disable_dates: true });
    }
  }


  handleChangeStart = (v) => {
    this.getAccounts(this.updateAccounts, v, this.state.end_date);

    this.setState({ start_date: v, timewindow: 0});

  }

  handleChangeEnd = (v) => {
    this.getAccounts(this.updateAccounts, this.state.start_date, v);

    this.setState({ end_date: v , timewindow: 0});
  }

  updateTimewindow = (tw, account=this.state.active_account) => {
    if(Object.keys(this.state.accounts_list).length > 0){
      if(tw === 1){
        let start = new Date(new Date().getFullYear(), 0, 1);
        let end = moment().subtract(1, 'days').toDate();

        if (this.state.accounts_list[account]['pfDates'] !== undefined) {
          if (moment(start).isBefore(moment(this.state.accounts_list[account]['pfDates']['start_date'], "YYYY-MM-DD"))) {
            start = moment(this.state.accounts_list[account]['pfDates']['start_date'], "YYYY-MM-DD").toDate();
          }
        }

        this.getAccounts(this.updateAccounts, start, end);

        this.setState({ start_date: start, end_date: end, timewindow: tw });

      }else if(tw === 12){
        let start = moment().subtract(12, 'months').subtract(1, 'days').toDate();
        let end = moment().subtract(1, 'days').toDate();

        if (this.state.accounts_list[account]['pfDates'] !== undefined) {
          if (moment(start).isBefore(moment(this.state.accounts_list[account]['pfDates']['start_date'], "YYYY-MM-DD"))) {
            start = moment(this.state.accounts_list[account]['pfDates']['start_date'], "YYYY-MM-DD").toDate();
          }
        }

        this.getAccounts(this.updateAccounts, start, end);

        this.setState({ start_date: start, end_date: end, timewindow: tw });
      }else if(tw === 36){
        let start = moment().subtract(36, 'months').subtract(1, 'days').toDate();
        let end = moment().subtract(1, 'days').toDate();

        if (this.state.accounts_list[account]['pfDates'] !== undefined) {
          if (moment(start).isBefore(moment(this.state.accounts_list[account]['pfDates']['start_date'], "YYYY-MM-DD"))) {
            start = moment(this.state.accounts_list[account]['pfDates']['start_date'], "YYYY-MM-DD").toDate();
          }
        }

        this.getAccounts(this.updateAccounts, start, end);

        this.setState({ start_date: start, end_date: end, timewindow: tw });
      }else if(tw === 60){
        let start = moment().subtract(60, 'months').subtract(1, 'days').toDate();
        let end = moment().subtract(1, 'days').toDate();

        if (this.state.accounts_list[account]['pfDates'] !== undefined) {
          if (moment(start).isBefore(moment(this.state.accounts_list[account]['pfDates']['start_date'], "YYYY-MM-DD"))) {
            start = moment(this.state.accounts_list[account]['pfDates']['start_date'], "YYYY-MM-DD").toDate();
          }
        }

        this.getAccounts(this.updateAccounts, start, end);

        this.setState({ start_date: start, end_date: end, timewindow: tw });
      }
    }
  }

  getNetContributions(){
      if(this.state.account_stats[this.state.active_account] !== undefined) {
        let c =0;
        c += this.state.account_stats[this.state.active_account]['since_inception_net_contributions']
        return numeral(c).format('$ 0,0')
    } else{ 
      return '--' 
    }
  }

  // getEarnings(){
  //   if(this.state.account_stats[this.state.active_account] !== undefined) {
  //     let c =0;
  //     c += this.state.account_stats[this.state.active_account]['since_inception_contributions'];
  //     c += this.state.account_stats[this.state.active_account]['since_inception_withdrawals'];
  //     c += this.state.account_stats[this.state.active_account]['since_inception_dividends_interest'];
  //     c += this.state.account_stats[this.state.active_account]['since_inception_growth'];
  //     c -= this.state.account_stats[this.state.active_account]['since_inception_fee_amount'];
  //     return numeral(c).format('$ 0,0')
  //   } else{ 
  //     return '--' 
  //   }
  // }

  getFeeValue(){
    if(this.state.account_stats[this.state.active_account] !== undefined && this.state.account_stats[this.state.active_account]['period_fee_ebps'] !== undefined) {
      let c = this.state.account_stats[this.state.active_account]['period_fee_ebps'];
      return (<>{numeral(c/100).format('0.00')} <span className="small-symbol">%</span></>)
    } else{ 
      return '--' 
    }
  }


  getNetEarnings(){
    if(this.state.account_stats[this.state.active_account] !== undefined) {
      let c =0;
      c += this.state.account_stats[this.state.active_account]['since_inception_net_earnings'];
      return numeral(c).format('$ 0,0')
    } else{ 
      return '--' 
    }
  }

  render(){
    // const netContribHelp = (
    //   <Popover id="dashboard-stats-help-box-contrib" className="dashboard-stats-help-box">
    //     <Trans>This is the amount of money that you have added or that have been deposited to your account, minus withdrawals you have made or charges, including fees, that have occurred.</Trans>
    //   </Popover>
    // );
    // const netEarningsHelp = (
    //   <Popover id="dashboard-stats-help-box-earn" className="dashboard-stats-help-box">
    //     <Trans>This is the difference between the ending market value of the period you have chosen and the sum of the opening market value of the period you have chosen plus net contributions during the period.</Trans>
    //   </Popover>
    // );
    // const feesHelp = (
    //   <Popover id="dashboard-stats-help-box-fee" className="dashboard-stats-help-box">
    //     <Trans>This is the fee you paid to PWL over the selected period, expressed as an annual percentage. (This does not include the management fees for the funds in your portfolio.)</Trans>
    //   </Popover>
    // );

    return (
      <div className="dashboard-grid">
        <div className="dashboard-sidebar">
          {/* <div className="dashboard-sidebar-select-account-container">
          { !this.state.accounts_loading ? (
              [
              <FormGroup key='account' className="dashboard-sidebar-select-account">
                <FormControl componentClass="select" value={this.state.active_account} onChange={(e)=>this.changeActiveAccount(e.target.value)}>
                  <option value="summary">{this.context.i18n.t('All Accounts')}</option>
                  {
                    Object.keys(this.state.accounts_list).map((acct) => {
                        if (acct !== 'summary') {
                          let acct_info = this.state.accounts_list[acct];
                          return (<option key={acct+'-option'}  value={acct}>{acct_info['pfGroup'] === undefined ? acct_info['pfName'] : this.context.i18n.t(acct_info['pfName'])}</option>);
                        }
                        return false;
                    })
                  }
                </FormControl>
              </FormGroup>,
              // <div key="date-picker" className="dashboard-date-picker-container">
              //   <DatePicker key="start-month"
              //       locale={this.context.currentLanguage()}
              //       disabled={this.state.disable_dates}
              //       className="dashboard-date-picker"
              //       customInput={<DatePickerInput />}
              //       selected={this.state.start_date}
              //       minDate={this.state.max_start}
              //       maxDate={this.state.end_date}
              //       onChange={this.handleChangeStart}
              //       popperPlacement="bottom"
              //       popperModifiers={{
              //           flip: {
              //               behavior: ["bottom"] // don't allow it to flip to be above
              //           },
              //           preventOverflow: {
              //               enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              //           },
              //           hide: {
              //               enabled: false // turn off since needs preventOverflow to be enabled
              //           }
              //       }}
              //       showMonthDropdown
              //       showYearDropdown
              //       dropdownMode="select"
              //   />
              //   <span>&nbsp;</span>
              //   <DatePicker key="end-month"
              //       locale={this.context.currentLanguage()}
              //       disabled={this.state.disable_dates}
              //       className="dashboard-date-picker"
              //       customInput={<DatePickerInput />}
              //       selected={this.state.end_date}
              //       minDate={this.state.start_date}
              //       maxDate={this.state.max_end}
              //       onChange={this.handleChangeEnd}
              //       popperPlacement="bottom"
              //       popperModifiers={{
              //           flip: {
              //               behavior: ["bottom"] // don't allow it to flip to be above
              //           },
              //           preventOverflow: {
              //               enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
              //           },
              //           hide: {
              //               enabled: false // turn off since needs preventOverflow to be enabled
              //           }
              //       }}
              //       showMonthDropdown
              //       showYearDropdown
              //       dropdownMode="select"
              //   />
              // </div>,
              <div key="timespan" className={"dashboard-sidebar-select-timespan-container "+this.context.currentLanguage()}>
                <div className={"dashboard-sidebar-select-timespan-item" + (this.state.timewindow === 12 ? " active" : "") + (this.state.disable_dates? " disabled": "")} onClick={()=>this.updateTimewindow(12)}><span>1&nbsp;<Trans>Year</Trans></span></div>
                <div className={"dashboard-sidebar-select-timespan-item" + (this.state.timewindow === 36 ? " active" : "") + (this.state.disable_dates? " disabled": "")} onClick={()=>this.updateTimewindow(36)}><span>3&nbsp;<Trans>Years</Trans></span></div>
                <div className={"dashboard-sidebar-select-timespan-item" + (this.state.timewindow === 60 ? " active" : "") + (this.state.disable_dates? " disabled": "")} onClick={()=>this.updateTimewindow(60)}><span>5&nbsp;<Trans>Years</Trans></span></div>
                <div className={"dashboard-sidebar-select-timespan-item" + (this.state.timewindow === 1 ? " active" : "") + (this.state.disable_dates? " disabled": "")} onClick={()=>this.updateTimewindow(1)}><span><Trans>YTD</Trans></span></div>
              </div>
            ]
            ) : (<div className="dashboard-sidebar-nav-accounts-loading"><FontAwesomeIcon icon={["far", "circle-notch"]}  className="fa-ease-in-out-spin" /> <Trans>Loading Accounts</Trans></div>) 
          }
          </div>
          <div className="dashboard-sidebar-stats">
            <div className="dashboard-sidebar-stats-item">
              <div className="dashboard-sidebar-stats-item-header"><div><Trans>Net Contributions</Trans></div> <div className="dashboard-sidebar-stats-item-header-help"><OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={netContribHelp}><FontAwesomeIcon icon={["fal", "question-circle"]} /></OverlayTrigger></div></div>
              <div className="dashboard-sidebar-stats-item-value">{this.getNetContributions()}</div>
            </div>
            <div className="dashboard-sidebar-stats-item">
              <div className="dashboard-sidebar-stats-item-header"><div><Trans>Net Earnings</Trans></div> <div className="dashboard-sidebar-stats-item-header-help"><OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={netEarningsHelp}><FontAwesomeIcon icon={["fal", "question-circle"]} /></OverlayTrigger></div></div>
              <div className="dashboard-sidebar-stats-item-value">{this.getNetEarnings()}</div>
            </div>
          </div> */}
        </div>
        <DashboardContent dateRange={[this.state.start_date, this.state.end_date]} 
                          accounts={this.state.accounts_list[this.state.active_account] !== undefined ? this.state.accounts_list[this.state.active_account]['pfList'] !== undefined? this.state.accounts_list[this.state.active_account]['pfList']: [this.state.active_account] : []} 
                          accountsInfo={this.state.accounts_list}
                          selectedAccount={this.state.active_account}
                          pfSummary={this.state.pf_summary}
                          pfSummaryLoading={this.state.pf_summary_loading}/>
      </div>
    );
  }
}

// class DatePickerInput extends React.Component {
//   render() {
//     return (
//       <div className={"dashboard-date-picker-input" + 
//                       (this.props.className !== undefined ? " " + this.props.className : "") +
//                       (this.props.disabled ? " disabled" : "")} 
//            onClick={this.props.onClick}>
//         {moment(this.props.value, 'MM/DD/YYYY').format("ll")}
//         <span className="dashboard-date-picker-input-arrow" ><FontAwesomeIcon icon={["far", "angle-down"]} /></span>
//       </div>
//     )
//   }
// }

class DashboardContent extends React.Component {
  static contextType = AppContext;

  render(){
    return (
      <div className="dashboard-content-container">
        {/* <div className="dashbaord-content-gow-container">
          <div className="dashboard-content-gow-header"><Trans>Growth of Wealth</Trans> - {this.props.accounts.length > 1 ? this.props.accountsInfo[this.props.selectedAccount] !== undefined ? (<Trans>{this.props.accountsInfo[this.props.selectedAccount]['pfName'] !== undefined ? this.props.accountsInfo[this.props.selectedAccount]['pfName'] : ''}</Trans>) : (<Trans>Multiple Accounts</Trans>)
                                                                                           : this.props.accountsInfo[this.props.accounts[0]] !== undefined ? this.props.accountsInfo[this.props.accounts[0]].pfName
                                                                                           : false}</div>
          <GrowthOfWealthChart startDate={this.props.dateRange[0]} endDate={this.props.dateRange[1]} containerClassName="gow-chart" accounts={this.props.accounts} />
        </div> */}
        <div className="dashboard-content-pfsummary-container">
          <div className="dashboard-content-pfsummary-header"><Trans>Portfolio Summary</Trans> <span><Trans>as of</Trans> {this.props.accountsInfo[this.props.accounts.length !== 1? 'summary': this.props.accounts[0]] !== undefined ? moment(this.props.accountsInfo[this.props.accounts.length !== 1? 'summary': this.props.accounts[0]]['pfDates']['end_date'], 'YYYY-MM-DD').utc().format('LL') : false}</span></div>
          <div className="dashboard-content-pfsummary-content-container">
            <div className="dashboard-content-pfsummary-chart">
              <div className="dashboard-content-pfsummary-chart-header"><Trans>Current Allocation</Trans></div>
              <div className="dashboard-content-pfsummary-chart-container" ref={container => this.assetcontainer = container}>
              {!this.props.pfSummaryLoading ? (<PortfolioAssetBucketDoublePie  size={Math.min(this.assetcontainer.getBoundingClientRect().width, this.assetcontainer.getBoundingClientRect().height) } data={this.props.pfSummary[this.props.accounts.length !== 1? 'summary': this.props.accounts[0]]}/>) 
                                            : <ComponentLoadingPlaceholder text={(<><Trans>Loading</Trans> <Trans>Portfolio Summary</Trans></>)} />}
              </div>
            </div>
            <div className="dashboard-content-pfsummary-table">
              <div className="dashboard-content-pfsummary-table-header">
                <div className="dashboard-content-pfsummary-table-header-item box-item"></div>
                <div className="dashboard-content-pfsummary-table-header-item title-item"><Trans>Type</Trans></div>
                <div className="dashboard-content-pfsummary-table-header-item value-item"><Trans>Market Value (CAD)</Trans></div>
                <div className="dashboard-content-pfsummary-table-header-item pct-item"><Trans>% of Portfolio</Trans></div>
                <div className="dashboard-content-pfsummary-table-header-item target-item"></div>
              </div>
              <div className="dashboard-content-pfsummary-table-rows">
                {Object.entries({'CASH & EQUIVALENT': '#a4de6c',
                                 'INCOME INVESTMENTS': '#8dd1e1',
                                 'ALTERNATIVE INVESTMENTS': '#a4de6c',
                                 'BALANCED FUNDS': '#83a6ed',
                                 'EQUITY': '#8884d8',
                                 'UNKNOWN': '#FFCC62'}).map(ac=>{
                  let cat = ac[0];
                  let color = ac[1];
                  let acct = this.props.pfSummary[this.props.accounts.length !== 1? 'summary': this.props.accounts[0]];
                  if (acct !== undefined && acct[cat] !== undefined) {
                    return (
                      <div key={(this.props.accounts.length !== 1? 'summary': this.props.accounts[0])+'-'+cat} className="dashboard-content-pfsummary-table-row">
                        <div className="dashboard-content-pfsummary-table-row-item box-item"><div className="dashboard-content-pfsummary-table-legend-box" style={{backgroundColor: color}}></div></div>
                        <div className="dashboard-content-pfsummary-table-row-item title-item">{this.context.i18n.t(cat).toLocaleLowerCase()}</div>
                        <div className="dashboard-content-pfsummary-table-row-item value-item">{numeral(acct[cat]['value']).format('$ 0,0')}</div>
                        <div className="dashboard-content-pfsummary-table-row-item pct-item">{numeral(acct[cat]['pct']).format('0.0')} <span className='small-symbol'>%</span></div>
                        <div className="dashboard-content-pfsummary-table-row-item target-item">&nbsp;</div>
                      </div>
                    )
                  }else {
                    return false
                  }
                }, this)}
              </div>
              {!this.props.pfSummaryLoading ? (
              <div className="dashboard-content-pfsummary-table-total">
                <div className="dashboard-content-pfsummary-table-total-item box-item"></div>
                <div className="dashboard-content-pfsummary-table-total-item title-item"><Trans>Total</Trans></div>
                <div className="dashboard-content-pfsummary-table-total-item value-item">{this.props.pfSummary[this.props.accounts.length !== 1? 'summary': this.props.accounts[0]] !== undefined ? numeral(Object.values(this.props.pfSummary[this.props.accounts.length !== 1? 'summary': this.props.accounts[0]]).reduce((a,c)=> a + c['value'], 0)).format('$ 0,0') : (<>&nbsp;</>)}</div>
                <div className="dashboard-content-pfsummary-table-total-item pct-item">100 %</div>
                <div className="dashboard-content-pfsummary-table-total-item target-item">&nbsp;</div>
              </div>) : false }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
