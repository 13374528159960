import React, { Component } from 'react';
import AppContext from '../../appcontext';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import {
  MenuItem,
  Nav,
  NavDropdown,
  NavItem,
  Navbar
} from 'react-bootstrap';

import { RouteNavItem } from '../components/routenavitem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './header.css'

class Header extends Component {
  static contextType = AppContext;

  handleNavLink = (event) => {
    event.preventDefault();
    this.context.navigate(event.currentTarget.getAttribute('href'));
  }


  handleLogout = event => {
    this.context.signout_user();
  }

  render() {
    return (
      <Navbar fixedTop collapseOnSelect inverse 
              fluid={this.props.fluid !== undefined} 
              className={'app-header ' + this.props.headerClassName} 
              style={{backgroundColor: this.context.theme.header_color}}>
        <Navbar.Header>
          <Link to="/"><img src={this.context.currentLogo()} alt="" className="header-logo" /></Link>
          <Navbar.Toggle />
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight className="system-box">
            { this.context.isAuthenticated
              ? [
                  <RouteNavItem key={"dashboard"} onClick={this.handleNavLink}  href="/"><Trans>Dashboard</Trans></RouteNavItem>,
                  <RouteNavItem key={"documents"} onClick={this.handleNavLink}  href="/documents"><Trans>Documents</Trans></RouteNavItem>,
                  <RouteNavItem key={"portfolios"} onClick={this.handleNavLink}  href="/portfolios"><Trans>Portfolio</Trans></RouteNavItem>,
                  <RouteNavItem key={"activity"} onClick={this.handleNavLink}  href="/performance"><Trans>Performance</Trans></RouteNavItem>,
                  /*<NavItem key={"alerts"} className={"navbar-alerts"} disabled><FontAwesomeIcon icon={["fal", "envelope"]} /> {this.context.isMobile ? (<Trans>Notifications</Trans>) : false}</NavItem>,*/
                  <NavDropdown key={'settings'} title={<span><FontAwesomeIcon icon={["fal", "cog"]} /> {this.context.isMobile ? (<Trans>Settings</Trans>) : false}</span>} noCaret className="navbar-settings" id="settings-dropdown">
                    <MenuItem key={"settings-account"} onClick={this.handleNavLink} href="/settings/account"><Trans>Account Settings</Trans></MenuItem>
                    <MenuItem key={"settings-security"} onClick={this.handleNavLink} href="/settings/security"><Trans>Security Settings</Trans></MenuItem>
                  </NavDropdown>
                ] : false }
              { this.context.isAuthenticated && Object.keys(this.context.user_accounts).length > 1 
              ? [
                  <NavItem key={"select-account"} onClick={()=>this.context.unsetActiveAccount()} title={this.context.i18n.t('Change Account')}><FontAwesomeIcon icon={["far", "exchange-alt"]} /></NavItem>
                ] : false }
              <NavDropdown key={'langselect'} title={ !this.context.isMobile ? this.context.currentLanguage() === 'en' ? 'FR' : 'EN' : this.context.currentLanguage() === 'en' ? <>Fran&ccedil;ais</> : 'English'} noCaret className="navbar-lang" id="lang-dropdown">
                <MenuItem key={"lang-en"} onClick={()=>this.context.changeLanguage('en-CA')}>English</MenuItem>
                <MenuItem key={"lang-fr"} onClick={()=>this.context.changeLanguage('fr-CA')}>Fran&ccedil;ais</MenuItem>
              </NavDropdown>
               { !this.context.isAuthenticated
                ? [ <RouteNavItem key={2} onClick={this.handleNavLink} href="/login" className="navbar-auth-text"><Trans>Sign in</Trans></RouteNavItem> ] 
                : [ <NavItem key={"logout"} onClick={this.handleLogout} className="navbar-auth-text"><Trans>Sign Out</Trans></NavItem> ] }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
