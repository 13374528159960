import React from 'react';
import * as recharts from 'recharts';

import numeral from 'numeral';

import AppContext from '../../appcontext';

export class PerformanceAnnualReturnBar extends React.Component {
  static contextType = AppContext;

  render() {
    let data = [];
    let fields = [];
    let cat_list = ['YTD'];
    const { startDate } = this.props;
    if (this.props.data['annualreturnthreeyearsago'] !== undefined && this.props.data['annualreturnthreeyearsago'] !== null) {
      fields = ['ytd', 'annualreturnoneyearago', 'annualreturntwoyearsago', 'annualreturnthreeyearsago', 'annualreturnfouryearsago', 'annualreturnfiveyearsago'];

      // Calculate the years to display
      for (let i = (startDate.getFullYear() - 1); i >= (startDate.getFullYear() - 5); i--) {
        cat_list.push('' + i);
      }
    } else {
      fields = ['ytd', 'month_03', 'month_06', 'month_09', 'year_01', 'year_02'];

      cat_list = [
        'YTD',
        "3 " + this.context.i18n.t('Months'),
        "6 " + this.context.i18n.t('Months'),
        "9 " + this.context.i18n.t('Months'),
        "1 " + this.context.i18n.t('Year'),
        "2 " + this.context.i18n.t('Years')
      ]

    }


    for (let i = 0; i < cat_list.length; i++) {
      let itm = {};

      if (cat_list[i] === 'YTD') {
        itm['year'] = this.context.i18n.t('YTD');
        itm['value'] = this.props.data[fields[i]];
        itm['fill'] = '#bac95a';
      } else {
        itm['year'] = cat_list[i] + '';
        itm['value'] = this.props.data[fields[i]];
        itm['fill'] = '#648ce0';
      }

      data.push(itm);
    }

    let height = this.props.height !== undefined ? this.props.height : 300

    const renderCustomizedBarLabel = (props) => {
      const {
        x, y, width, height, value, fill
      } = props;
      const radius = 10;
      let ele = false;
      if (height < 40 && height >= 0) {
        ele = (
          <g>
            <text x={x + width / 2} y={y - 2} fill={fill} textAnchor="middle" alignmentBaseline="text-top" className="bar-label-text">
              {numeral(value).format('0.00')} <tspan fontSize="0.8em" className="small-symbol">%</tspan>
            </text>
          </g>
        );
      }
      if (height > 40) {
        ele = (
          <g>
            <text x={x + width / 2} y={y + height - 2 - radius} fill="#fff" textAnchor="middle" alignmentBaseline="text-top" className="bar-label-text">
              {numeral(value).format('0.00')} <tspan fontSize="0.8em" className="small-symbol">%</tspan>
            </text>
          </g>
        );
      }
      if (height > -40 && height < 0) {
        ele = (
          <g>
            <text x={x + width / 2} y={y + 15} fill={fill} textAnchor="middle" alignmentBaseline="text-bottom" dominantBaseline="text-bottom" className="bar-label-text">
              {numeral(value).format('0.00')} <tspan fontSize="0.8em" dy="0em" className="small-symbol">%</tspan>
            </text>
          </g>
        );
      }
      if (height < -40) {
        ele = (
          <g>
            <text x={x + width / 2} y={y + height + 12 + radius} fill="#fff" textAnchor="middle" alignmentBaseline="text-bottom" dominantBaseline="text-bottom" className="bar-label-text">
              {numeral(value).format('0.00')} <tspan fontSize="0.8em" dy="0em" className="small-symbol">%</tspan>
            </text>
          </g>
        );
      }

      return ele;
    };
    return (
      <recharts.ResponsiveContainer width="99%"
        height={height} debounce={100}>
        <recharts.BarChart data={data}
          margin={{ top: 40, right: 30, left: 0, bottom: 10 }}>


          <recharts.XAxis dataKey="year"
            tickLine={false}
            tickMargin={20}
            axisLine={false} />

          <recharts.YAxis dataKey="value"
            tickLine={false}
            tickMargin={10}
            allowDecimals={false}
            axisLine={false}
            domain={[dm => Math.ceil(Math.min(dm - Math.min(Math.floor(Math.abs(dm * 0.25), 2)), 0)), dm => Math.ceil(dm + Math.min(Math.floor(Math.abs(dm * 0.25), 2)))]} />


          <recharts.Customized key={'yaxis-labeled'} component={(props) => {
            return (
              <g>
                <text x={45} className="y-axis-label">(%)</text>
                <line x1={60} x2={60} y1={height - 30} className="y-axis-line"></line>
              </g>
            )
          }} />

          <recharts.ReferenceLine y={0} stroke="var(--text-secondary)" isFront={true} />

          <recharts.Bar dataKey="value"
            barSize={70}
            isAnimationActive={false}>
            <recharts.LabelList dataKey="value" content={renderCustomizedBarLabel} />
          </recharts.Bar>
        </recharts.BarChart>
      </recharts.ResponsiveContainer>
    );
  }
}
