var config = {}
var default_config = {
  apiGateway: {
    URL: "",
    REGION: ''
  },
  jwt: {
    sign_key: "sign_key_here"
  },
  docUpload: {
    statusInterval: 3,
    statusDuration: 30
  }
};

try {
  var local = require("./config_local.js");
  config = {...default_config, ...local.config}
} catch(e) {
  config = default_config
}

export default config

