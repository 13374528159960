import React from 'react';
import {
  FormGroup,
  FormControl,
  Glyphicon
} from 'react-bootstrap';
import LoaderButton from '../components/loaderbutton';
import { invokeApi } from '../../libs/authlib';

import PasswordCriteria from '../components/passwordCriteriaWidget/passwordCriteriaWidget';
import { LangChangeSelect } from '../../containers/components/changelang';
import queryString from 'query-string';

import { BoxLayout } from '../layout/layout';

import AppContext from '../../appcontext';
import { Trans } from 'react-i18next';

import './resetpassword.css';

class Resetpassword extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    let q_params = queryString.parse(props.location.search);

    this.state = {
      isLoading: false,
      email: q_params.e ? q_params.e : '',
      code: '',
      password: '',
      confirm_password: '',
      err: undefined,
      isSent: false,
      isCoded: false,
      inputType: "password"
    };
  }

  validateEmail() {
    return this.state.email.length > 0
      // eslint-disable-next-line 
      && this.state.email.match(/^([a-zA-Z0-9_\-\+\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/);
  }

  validateCode(){
    return this.state.code.length > 5;
  }

  validatePassword(){
    // Does the password include 1 uppercase
    return /.*[A-Z]{1,}.*/.test(this.state.password)
    // Does the password include 1 lowercase
    && /.*[a-z]{1,}.*/.test(this.state.password)
    // Does the password include special characters
    && /.*[$*.\^[\]{}()?"!@#%&/\\,><':;|_=\-+~`]{1,}.*/.test(this.state.password)
    // Does the password include numbers
    && /.*[0-9]{1,}.*/.test(this.state.password)
    // Is the password at least 8 characters
    && this.state.password.length >= 8 
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleEmail = async (event) => {
    event.preventDefault();

    this.setState({ err: undefined, isLoading: true });
    let payload = {
      username: this.state.email
    }

    try{
      await invokeApi({
          path: "/client/auth/password",
          method: "post",
          body: payload,
          unauth: true
      }); 
      
      this.setState({ isLoading: false, isSent: true  });
    }catch(e){
      if (e.message === "invalid_credentials_user_not_found") {
        this.setState({ err: this.context.i18n.t("Account not found"), isLoading: false });
      } else if (e.message === "invalid_credentials_limit_exceed") {
        this.setState({ err: this.context.i18n.t("Too many reset attempts have been made on this account, please try again later"), isLoading: false });
      } else {
        this.setState({ err: 'error.reset.' + e.message, isLoading: false });
      }
    }

  }

  handleCode = async (event) => {
    event.preventDefault();

    this.setState({ err: undefined, isCoded: true, isLoading: false });
    
  }

  handlePassword = async (event) => {
    event.preventDefault();

    this.setState({ err: undefined, isLoading: true });
    let payload = {
      username: this.state.email,
      confirm_code: this.state.code,
      password: this.state.password
    }
    try{
      let rtn = await invokeApi({
          path: "/client/auth/password/reset",
          method: "post",
          body: payload,
          unauth: true
      }); 

      if(rtn === ""){
        this.props.history.push('/login');
      }else{
        this.setState({ isLoading: false });
      }
    }catch(e){
      if (this.validatePassword) {
        this.setState({ err: this.context.i18n.t("The verification code entered, did not match our records"), isLoading: false, isCoded: false });
      } else {
        this.setState({ err: this.context.i18n.t("The password you have entered is invalid"), isLoading: false });
      }
    }
  }

  renderForm(){
    return(
      <>
        <h1><Trans>Forgot your password?</Trans></h1>
        <h2><Trans>Please enter the email address associated with your account to reset your password.</Trans></h2>
        <form onSubmit={this.handleEmail}>
          <FormGroup controlId="email" bsSize="large" className="form-input reset-email-input">
            <FormControl
              autoFocus
              type="text"
              placeholder={this.context.i18n.t("Email")}
              value={this.state.email}
              onChange={this.handleChange} />
          </FormGroup>
          <LoaderButton
              block
              className="reset-submit-btn"
              disabled={ ! this.validateEmail() }
              type="submit"
              isLoading={this.state.isLoading}
              text={this.context.i18n.t("Send reset email")}
              loadingText={this.context.i18n.t("Sending email…")} />
        </form>
      </>
    );
  }

  renderCode(){
    return (
      <>
        <h3><Trans>An email with a verification code has been sent to your inbox. Please enter the code below.</Trans></h3>
        <form onSubmit={this.handleCode}>
          <FormGroup controlId="code" bsSize="large" className="form-input reset-email-input">
            <FormControl
              autoFocus
              type="text"
              placeholder={this.context.i18n.t("Enter Code")}
              value={this.state.code}
              onChange={this.handleChange} />
          </FormGroup>
          <LoaderButton
              block
              className="reset-submit-btn"
              disabled={ ! this.validateCode() }
              type="submit"
              isLoading={this.state.isLoading}
              text={this.context.i18n.t("Continue")}
              loadingText={this.context.i18n.t("Continuing")} />
        </form>
      </>
    );
  }

  renderPassword(){
     return (
      <>
        <form onSubmit={this.handlePassword}>
          <FormGroup controlId="password" bsSize="large" className="form-input reset-email-input">
            <FormControl
              autoFocus
              type={this.state.inputType}
              placeholder={this.context.i18n.t("New Password")}
              value={this.state.password}
              onChange={this.handleChange} />
              <Glyphicon 
                glyph={ ((this.state.inputType === "password") ? "eye-open" : "eye-close") }
                onClick={() => this.flipPasswordInputState()}
                className="password-eye-icon"
                />
          </FormGroup>

          <PasswordCriteria className="password-criteria-container" password={this.state.password} />
          <LoaderButton
              block
              className="reset-submit-btn"
              disabled={ ! this.validatePassword() }
              type="submit"
              isLoading={this.state.isLoading}
              text={this.context.i18n.t("Reset Password")}
              loadingText={this.context.i18n.t("Resetting Password")} />
        </form>
      </>
    );
  }

  flipPasswordInputState(){
    if(this.state.inputType === "password"){
      this.setState({inputType: "text"});
    }else{
      this.setState({inputType: "password"});
    }
  }

  render() {
    return (
      <div className="resetpassword-container">
        <BoxLayout type="box" errmsg={this.state.err} sub={<><Trans>Return to <a href="/login" onClick={(e)=>{e.preventDefault(); this.context.navigate('/login')}}>login</a></Trans></>}>
          {
            !this.state.isSent ? this.renderForm()
              : !this.state.isCoded ? this.renderCode()
              : this.renderPassword()
          }
          <LangChangeSelect lang={this.context.currentLanguage()} containerClass='resetpassword-lang-select' />
       </BoxLayout>
      </div>
    );
  }
}

export default Resetpassword;
