import React from 'react';
import AppContext from '../../appcontext';

import numeral from 'numeral';
import moment from 'moment';

import { FillLayout } from '../layout/layout.js';
import { invokeApi } from '../../libs/authlib';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from 'react-i18next';

import { ComponentLoadingPlaceholder } from '../components/loadingplaceholder';

import { PerformanceAnnualReturnBar } from './performancecharts.js';
import './performance.css';


/* Performance with Container */
class Performance extends React.Component {
    static contextType = AppContext;

    render() {
        return (  
            <div className="performance-container">
            <FillLayout containerClassName="performance-box">
                <PerformanceContainer />
            </FillLayout>
            </div>
        );
    }
}

export default Performance;

class PerformanceContainer extends React.Component {
    static contextType = AppContext;
  
    constructor(props) {
      super(props);
      
      this.state = {
        active_group: '',
        portfolios_loading: true,
        portfolios: {},
        groups_loading: true,
        groups: {}
      }
    }
  
    componentDidMount(){
      this.signal = this.context.axiosCancel();
  
      if ( this.context.user_account !== undefined) {
        this.getGroups(this.formatGroupList);
        this.getPortfolios(this.formatPortfoliosList);
      } else {
        this.setState({ loading_error: this.context.i18n.t("No account provided.")  });
      }
    }
  
  
    componentWillUnmount() {
      this.signal.cancel();
    }
  
    async getGroups(success_callback){
      try{
        let path = '/client/' + this.context.user_account + "/groups/portfolio";
  
        await invokeApi({
           path: path,
           method: 'GET',
           onSuccess: r => success_callback(r),
           onError: e => this.setState({ groups_loading : false, loading_error : e })
        });
      }catch(e){
        console.error(e);
      }
    }

    formatGroupList = (groups) => {
        let group_list= {};

        for (let i=0; i < groups.length; i++) {
            group_list[groups[i].uuid] = groups[i];
        }

        this.setState({ groups: group_list, groups_loading: false, active_group: (groups.length > 0 ? groups[0].uuid : '') });
    }

    async getPortfolios(success_callback){
      try{
        let path = '/client/' + this.context.user_account + "/portfolios";
  
        await invokeApi({
           path: path,
           method: 'GET',
           onSuccess: r => success_callback(r),
           onError: e => this.setState({ portfolios_loading : false, loading_error : e })
        });
      }catch(e){
        console.error(e);
      }
    }

    formatPortfoliosList = (pfs) => {
        let pf_list= {};

        for (let i=0; i < pfs.length; i++) {
          pf_list[pfs[i].pf_uuid] = pfs[i];
        }

        this.setState({ portfolios: pf_list, portfolios_loading: false });
    }

    changeActiveGroup=(group)=>{
        this.setState({ active_group: group });
    }

    scrollControl=(id) => {
      let elm = document.getElementById(id+'-container');
      let scrollto = elm.offsetTop - 55;
      window.scrollTo(0, scrollto);
    }

    render(){

      return (
        <div className="performance-grid">
          <div className="performance-sidebar">
            <div className="performance-sidebar-group-header"><Trans>Groups</Trans></div>
            <div className="performance-sidebar-group-container unselectable">
            { !this.state.groups_loading ? (
                Object.keys(this.state.groups).map((grp) => {
                  let grp_info = this.state.groups[grp]
                  return (<div key={grp_info['uuid']+'-option'} className="performance-sidebar-group-item"><span onClick={()=>this.scrollControl(grp)}>{grp_info['group_name']}</span></div>);
                })
              ) : (<div className="performance-sidebar-nav-groups-loading"><FontAwesomeIcon icon={["far", "circle-notch"]}  className="fa-ease-in-out-spin" /> <Trans>Loading</Trans> <Trans>Groups</Trans></div>) 
            }
            </div>
          </div>
          <div className="performance-content">
            {!this.state.groups_loading ? Object.keys(this.state.groups).map(grp=> this.state.groups[grp] !== undefined ? (
              <PerformanceGroup key={grp+'-item'}
                                groupInfo={this.state.groups[grp]}
                                portfoliosLoading={this.state.portfolios_loading} portfoliosInfo={this.state.portfolios}/>
            ) : false) : (<ComponentLoadingPlaceholder text={(<><Trans>Loading</Trans> <Trans>Groups</Trans></>)} />) }
          </div>
        </div>
      );
    }
  }

  class PerformanceGroup extends React.Component {
    static contextType = AppContext;

    scrollToTop=()=>{
      window.scrollTo(0, 0);
    }
  

    getAccountListOrder(accounts){
      let apnum = ['A','B','S','R','T','W','Z','Y','N','P','0']

      let sort_items = Object.values(accounts).map((a,i)=>{
          let item = {root: '', last: '', id: ''}

          if(a.pfSummary !== true){
              item['root'] = (''+a.pfAccount.account_number).slice(0, 3);
              item['last'] = (''+a.pfAccount.account_number).slice(-1);
              item['id'] = a.pf_uuid;
  
              return item;
          } else {
              return null;
          }
      });

      sort_items = sort_items.filter((v)=> v !== null);

      sort_items.sort(function (a,b){
          let root = a.root.localeCompare(b.root);
          let last = a.last === b.last ? 0
                     : apnum.indexOf(a.last) !== -1 &&  apnum.indexOf(b.last) !== -1 ? apnum.indexOf(a.last) > apnum.indexOf(b.last) ? 1 : -1
                     : apnum.indexOf(a.last) === -1 &&  apnum.indexOf(b.last) === -1 ? a.last.localeCompare(b.last)
                     : apnum.indexOf(a.last) === -1 ? 1 : -1 ;
          
          return root !== 0? root : last;
      });

      return sort_items.map(a => a.id);
    }

    renderAccountList=(pf_list)=>{
        let pfs = pf_list.map(pf=>this.props.portfoliosInfo[pf]);
        let pfol = this.getAccountListOrder(pfs);

        return pfol.map(pf=>(
            <div key={pf.pf_uuid+'-item'} className="performance-portfolios-content-item">
                <div className="performance-portfolios-content-item-field acct-item">{this.props.portfoliosInfo[pf] !==undefined && this.props.portfoliosInfo[pf].pfAccount !== undefined && this.props.portfoliosInfo[pf].pfAccount !== null && this.props.portfoliosInfo[pf].pfAccount.account_number !== undefined ? this.props.portfoliosInfo[pf].pfAccount.account_number : ''}</div>
                <div className="performance-portfolios-content-item-field type-item">{this.props.portfoliosInfo[pf] !==undefined && this.props.portfoliosInfo[pf].pfType !== undefined ? this.props.portfoliosInfo[pf].pfType : ''}</div>
                <div className="performance-portfolios-content-item-field name-item">{this.props.portfoliosInfo[pf] !==undefined && this.props.portfoliosInfo[pf].pfAccount !== null && this.props.portfoliosInfo[pf].pfAccount.account_name !== undefined ? this.props.portfoliosInfo[pf].pfAccount.account_name : this.props.portfoliosInfo[pf].pfName !== undefined ? this.props.portfoliosInfo[pf].pfName : ''}</div>
                <div className="performance-portfolios-content-item-field curr-item">{this.props.portfoliosInfo[pf] !==undefined && this.props.portfoliosInfo[pf].pfAccount !== undefined && this.props.portfoliosInfo[pf].pfAccount !== null && this.props.portfoliosInfo[pf].pfAccount.currency !== undefined ? this.props.portfoliosInfo[pf].pfAccount.currency : ''}</div>
            </div>
            ));
    }

    render(){
      return (
        <div className="performance-content-group" id={this.props.groupInfo.uuid !== undefined? this.props.groupInfo.uuid + '-container' : false}>
            <div className="performance-annual-returns-container">
                <div className="performance-annual-returns-header"><Trans>Annual Returns</Trans> <span className="performance-content-returns-sub-header"><Trans>as of</Trans> {this.props.groupInfo !== undefined && this.props.groupInfo.performance_date !== undefined ? moment.unix(this.props.groupInfo.performance_date).utc().format('LL') : false}</span></div>
                <div className="performance-annual-returns-content">
                    <PerformanceAnnualReturnBar data={this.props.groupInfo !== undefined && this.props.groupInfo.stats !== undefined? this.props.groupInfo.stats : {} } startDate={this.props.groupInfo !== undefined && this.props.groupInfo.performance_date !== undefined ? new Date(moment.unix(this.props.groupInfo.performance_date)) : new Date()}/>
                </div>
            </div>
            <div className="performance-content-compound-returns-container">
                <div className="performance-compound-returns-header"><Trans>Annualized Compound Returns</Trans> <span className="performance-content-compound-returns-sub-header"><Trans>Consolidated Performance</Trans> <Trans>as of</Trans> {this.props.groupInfo !== undefined && this.props.groupInfo.performance_date !== undefined ? moment.unix(this.props.groupInfo.performance_date).utc().format('LL') : false}</span></div>
                <div className="performance-compound-returns-content">
                    <div className="performance-compound-returns-headers">
                        <div className="performance-compound-returns-header-item ytd-item"><Trans>YTD</Trans></div>
                        <div className="performance-compound-returns-header-item 1yr-item">1 <Trans>Year</Trans></div>
                        <div className="performance-compound-returns-header-item 3yr-item">3 <Trans>Years</Trans></div>
                        <div className="performance-compound-returns-header-item 5yr-item">5 <Trans>Years</Trans></div>
                        <div className="performance-compound-returns-header-item 10yr-item">10 <Trans>Years</Trans></div>
                        <div className="performance-compound-returns-header-item si-item"><Trans>Since Inception</Trans></div>
                    </div>
                    <div className="performance-compound-returns-items">
                      <div className="performance-compound-returns-item">{this.props.groupInfo !== undefined && this.props.groupInfo.stats !== undefined && this.props.groupInfo.stats['ytd'] ? (<span>{numeral(this.props.groupInfo.stats['ytd']).format('0.00')} <span className="small-symbol">%</span></span>) : '--' }</div>
                      <div className="performance-compound-returns-item">{this.props.groupInfo !== undefined && this.props.groupInfo.stats !== undefined && this.props.groupInfo.stats['year_01'] ? (<span>{numeral(this.props.groupInfo.stats['year_01']).format('0.00')} <span className="small-symbol">%</span></span>) : '--' }</div>
                      <div className="performance-compound-returns-item">{this.props.groupInfo !== undefined && this.props.groupInfo.stats !== undefined && this.props.groupInfo.stats['year_03'] ? (<span>{numeral(this.props.groupInfo.stats['year_03']).format('0.00')} <span className="small-symbol">%</span></span>) : '--' }</div>
                      <div className="performance-compound-returns-item">{this.props.groupInfo !== undefined && this.props.groupInfo.stats !== undefined && this.props.groupInfo.stats['year_05'] ? (<span>{numeral(this.props.groupInfo.stats['year_05']).format('0.00')} <span className="small-symbol">%</span></span>) : '--' }</div>
                      <div className="performance-compound-returns-item">{this.props.groupInfo !== undefined && this.props.groupInfo.stats !== undefined && this.props.groupInfo.stats['year_10'] ? (<span>{numeral(this.props.groupInfo.stats['year_10']).format('0.00')} <span className="small-symbol">%</span></span>) : '--' }</div>
                      <div className="performance-compound-returns-item">{this.props.groupInfo !== undefined && this.props.groupInfo.stats !== undefined && this.props.groupInfo.stats['since_inception'] ? (<span>{numeral(this.props.groupInfo.stats['since_inception']).format('0.00')} <span className="small-symbol">%</span></span>) : '--' }</div>
                    </div>
                </div>
            </div>
            <div className="performance-content-header"><Trans>Portfolio Performance</Trans>&nbsp;<div className="performance-content-group-header">{this.props.groupInfo['group_name'] !== undefined ? this.props.groupInfo['group_name'] : false}</div></div>
            <div className="performance-portfolios-container">
                <div className="performance-portfolios-header"><Trans>Group Accounts</Trans></div>
                <div className="performance-portfolios-content">
                  <div className="performance-portfolios-content-header">
                    <div className="performance-portfolios-content-header-item acct-item"><Trans>Account</Trans></div>
                    <div className="performance-portfolios-content-header-item type-item"><Trans>Type</Trans></div>
                    <div className="performance-portfolios-content-header-item name-item"><Trans>Name</Trans></div>
                    <div className="performance-portfolios-content-header-item curr-item"><Trans>Currency</Trans></div>
                  </div>
                  { !this.props.portfoliosLoading ? (
                    <div className="performance-portfolios-content-items">
                      {this.props.groupInfo !== undefined && this.props.groupInfo.pf_list !== undefined ? this.renderAccountList(this.props.groupInfo.pf_list) : false }
                    </div> 
                  ) : (<ComponentLoadingPlaceholder text={(<><Trans>Loading</Trans> <Trans>Portfolios</Trans></>)} />) }
                </div>
            </div>
            <div className="performance-group-scroll-to-top"><span onClick={()=>this.scrollToTop()}><Trans>Scroll to top</Trans></span></div>
        </div>
      )
    }
  }
