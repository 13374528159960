import React, { Component } from 'react';
import LoaderButton from '../../containers/components/loaderbutton';
import { LangChangeSelect } from '../../containers/components/changelang';
import {
  FormGroup,
  FormControl,
  ControlLabel,
  Modal,
  Button,
  Glyphicon
} from 'react-bootstrap';
import { BoxLayout } from '../layout/layout';

import AppContext from '../../appcontext';
import { Trans } from 'react-i18next';

import './login.css';

class Login extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      username: '',
      password: '',
      confirmPassword: '',
      confirmationCode: '',
      user: null,
      showError: false,
      error: '',
      inputType: "password"
    };
  }

  componentWillMount(){
    if ( this.context.isAuthenticated) {
      this.props.history.push('/');
    } 
  }

  validateForm() {
    return this.state.username.length > 0
      && this.state.password.length > 0;
  }

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      if(await this.context.login_user(this.state.username, this.state.password)){
        this.context.user_has_authenticated(true);
        await this.context.updateUserStatus();
        if (this.props.location.state !== undefined && this.props.location.state.referrer !== undefined){
          this.props.history.push(''+this.props.location.state.referrer)
        }else{
          this.props.history.push('/')
        }

      }else{
        this.setState({ isLoading: false });
      }
    }catch(e) { 
      this.setState({
        isLoading: false,
        showError: true,
        error: this.context.i18n.t((e.data.message === undefined?"There was a problem connecting to the server, please try refreshing. If the issue persists contact support.":'error.login.'+e.data.message))
      });
    }
  }

  renderForm(){
    return (
      <div className="login-content">
        <BoxLayout sub={
          <div>
            <div><Trans>Reset your password <a href="/resetpassword" onClick={(e)=>{e.preventDefault(); this.context.navigate('/resetpassword')}}>here</a></Trans></div>
          </div>
        }>
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="username" bsSize="large">
              <ControlLabel><Trans>Email</Trans></ControlLabel>
              <FormControl
                autoFocus
                type="email"
                value={this.state.username}
                onChange={this.handleChange} />
            </FormGroup>
            <FormGroup controlId="password" bsSize="large">
              <ControlLabel><Trans>Password</Trans></ControlLabel>
              <FormControl
                value={this.state.password}
                onChange={this.handleChange}
                type={this.state.inputType} />
              <Glyphicon 
                glyph={ ((this.state.inputType === "password") ? "eye-open" : "eye-close") }
                onClick={() => this.flipPasswordInputState()}
                className="password-eye-icon"
                />
            </FormGroup>
            <LoaderButton
              block
              bsSize="large"
              disabled={ ! this.validateForm() }
              className="loginButton"
              type="submit"
              isLoading={this.state.isLoading}
              text={<Trans>Login</Trans>}
              loadingText={<Trans>Logging in…</Trans>} />

              
            <LangChangeSelect lang={this.context.currentLanguage()} containerClass='login-lang-select' />
          </form>
          <Modal show={this.state.showError}>
            <Modal.Header>
              <Modal.Title><Trans>Error</Trans></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Trans>{this.state.error}</Trans>
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={function(e){ this.setState({showError:false})}.bind(this) } ><Trans>Close</Trans></Button>
            </Modal.Footer>
          </Modal>
        </BoxLayout> 
      </div>
    );
  }

  render() {
    return (
      <div className="Login">
        { this.renderForm() }
      </div>
    );
  }

  flipPasswordInputState(){
    if(this.state.inputType === "password"){
      this.setState({inputType: "text"});
    }else{
      this.setState({inputType: "password"});
    }
  }
}

export default Login;