import React, { Component, Suspense } from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from "react-helmet";
import { isIE, isEdge, isWinPhone, browserVersion } from 'react-device-detect';
import moment from 'moment';
import numeral from 'numeral';

import 'moment/locale/fr.js';
import 'numeral/locales/fr-ca.js';

import Routes from './routes';
import fourlytics from './fourlytics.js';

import { authUser, loginUser, getUserState, removeAuthToken, setDeviceToken, axiosCancel } from './libs/authlib';

import './App.css';
import ThemeStyles from './themestyles';

import { registerLocale } from 'react-datepicker';
import { default as dnfsENCA } from 'date-fns/locale/en-CA';
import { default as dnfsFR } from 'date-fns/locale/fr';

import { library, icon } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons'

import { faBars, faTimes, faCaretUp, faCaretDown, faUserCircle, faCircle as faCircleS, faQuestion as faQuestionS} from '@fortawesome/pro-solid-svg-icons';
import { faCopy, faCheckCircle, faCircle, faSyncAlt, faCircleNotch, faChevronDown, faDotCircle, faAngleDown, faExclamationTriangle, faToggleOff, faToggleOn, faFileDownload, faExchangeAlt } from '@fortawesome/pro-regular-svg-icons';
import { faUser, faCog, faBell, faCircle as faCircleL, faEnvelope, faChevronLeft, faChevronRight,
         faSquare, faCheckSquare, faPencil, faTimes as faTimesL, faCheck, faTrashAlt, faQuestion,
         faTv, faDesktop, faMobile, faTablet, faQuestionCircle } from '@fortawesome/pro-light-svg-icons';

import { withTranslation } from 'react-i18next';

import { ComponentLoadingPlaceholder } from './containers/components/loadingplaceholder';

library.add(fab, faBars, faTimes, faCopy, faCheckCircle, faCircle, faCircleS, faCircleL, 
            faCaretUp, faCaretDown, faSyncAlt, faCircleNotch, faUserCircle, faExclamationTriangle, faQuestion,
            faChevronDown, faCog, faBell, faUser, faDotCircle, faEnvelope, faAngleDown, faFileDownload,
            faChevronLeft, faChevronRight, faToggleOff, faToggleOn, faSquare, faCheckSquare,
            faPencil, faTimesL, faCheck, faTrashAlt, faTv, faDesktop, faMobile, faTablet,
            faQuestionS, faQuestionCircle, faExchangeAlt)

registerLocale('en', dnfsENCA);
registerLocale('fr', dnfsFR);

class AppContent extends Component {
  constructor(props) {
    super(props);

    const { i18n } = props;

    this.i18n = i18n;

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      loading_state: 'authenticating',
      user_state: null,
      state_order: ['sq', 'token'],
      user_account: null,
      user_accounts: [],
      account_selected: false,
      role: '',
      isMobile: this.is_mobile(),
      isTablet: this.is_tablet(),
      isDesktop: this.is_desktop(),
      show_bgi: false,
      theme: {
        default_title: 'PWL Capital',
        primary: '#007696',
        primary_dark: '#003a4a',
        white_highlight_color: '#D6EAEF',
        danger_text: '#E53935',
        danger_text_dark: '#AB000D',
        header_color: '#007696',
        header_color_dark: '#007696',
        header_text: '#FFF',
        text_header: '#99908a',
        text_underline: '#AFC900',
        text_secondary: '#A2A2A2',
        input_color: '#007696',
        background_color: '#f9f8f7',
        default_background_color: '#f9f8f7',
        box_background_color: '#007696',
        box_bgi: '',
        logo: { en: '/imgs/logo.svg', fr: '/imgs/logo.svg' },
        dark_logo: { en: '/imgs/logo.svg', fr: '/imgs/logo.svg' }
      }
    }
  }

  async componentDidMount() {
    await this.update_user_state();
    this.setState({isAuthenticating: false, loading_state: 'complete'});
    window.addEventListener("resize", this.update_device_type.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.update_device_type.bind(this));
  }

  update_device_type = () => {
    this.setState({
      isMobile: this.is_mobile(),
      isTablet: this.is_tablet(),
      isDesktop: this.is_desktop()
    });
  }

  update_user_state = async () => {
    if (await authUser()) {
      var userState = await getUserState();
      let tempAccountSelected = this.state.user_account;
      if (tempAccountSelected !== null && userState.user_account !== tempAccountSelected) {
        userState['user_account'] = tempAccountSelected;
      }
      this.setState({ isAuthenticated: true, loading_state: 'user_data', ...userState});
    }
  }

  user_has_authenticated = (value) => {
    this.setState({isAuthenticated: value})
  }

  signoutUser = () => {
    removeAuthToken();
    this.setState({isAuthenticated: false, account_selected: false, user_account: null });
  }

  changeLanguage = lng => {
    this.i18n.changeLanguage(lng);
  }

  currentLanguage = () => {
    let lang = localStorage.getItem('fef.user.language');
    if ( lang !== null ){
      lang = (lang.split('-'))[0];
    }else{
      lang = 'en';
    }
    return lang
  }

  currentLogo = (type='light') => {
    let lang = this.currentLanguage();
    let logo = '';
    if ( type === 'dark' ) {
      if ( this.state.theme.logo.hasOwnProperty(lang) ){
        logo = this.state.theme.dark_logo[lang];
      }else{
        logo = this.state.theme.dark_logo.en;
      }
    } else {
      if ( this.state.theme.logo.hasOwnProperty(lang) ){
        logo = this.state.theme.logo[lang];
      }else{
        logo = this.state.theme.logo.en;
      }
    }
    return logo
  }

  changeBackground = clr => {
    const node = ReactDOM.findDOMNode(this);
    node.style.backgroundColor = clr;
  }

  changeActiveAccount = acct => {
    this.setState({ user_account: acct, account_selected: true, header_balances: this.state.user_accounts[acct].pf_summary });
  }

  unsetActiveAccount = () => {
    this.setState({ user_account: null, account_selected: false, header_balances: {} });
  }

  greetingText = () => {
    const currentHour = moment().hour();
    return (currentHour >= 12 && currentHour <=17) ? this.i18n.t('Good Afternoon,')
      : (currentHour >= 18) ? this.i18n.t('Good Evening,')
      : this.i18n.t('Good Morning,')
  }

  toggleBGI = show => {
    this.setState({show_bgi: show});
  }

  is_mobile = () => {
    return window.innerWidth < 768
  }

  is_tablet = () => {
    return ( window.innerWidth < 991 && window.innerWidth > 768 )
  }

  is_desktop = () => {
    return window.innerWidth > 991
  }

  ieCheck = () => {
    let version = isNaN(parseInt(browserVersion)) ? 0 : parseInt(browserVersion);
    
    return isIE || (isEdge && version < 79) || isWinPhone;
  }

  check_icon_imported = (is) => {
    return icon({ prefix: is[0], iconName: is[1] }) !== undefined
  }

  render() {
    moment.locale(this.currentLanguage() === 'en'? 'en' : 'fr-ca');
    numeral.locale(this.currentLanguage() === 'en'? 'en' : 'fr-ca');

    // Monkeypatch Numeral to support fr-ca currency
    if (numeral.patched === undefined) {
      numeral.old_curr = numeral.formats.currency.format;
      numeral.formats.currency.format = (value, format, roundingFunction) => {
          let output;

          let svg = false;
          let string = false;

          if (/\$s/.test(format)) {
            svg = true;
            format = format.replace(/\$s/, "$");
          }
          if (/s\$/.test(format)) {
            string = true;
            format = format.replace(/s\$/, "$");
          }

          let match = /(.*)([\s]*)(\$)([\s]*)([\d,.]*)(.*)$/.exec(format);

          let locale = numeral.locales[numeral.options.currentLocale];

          if(this.currentLanguage() === 'fr'){
            format = match[1] + match[5] + match[4] + match[3] + match[2] + match[6]
          }

          output = numeral.old_curr(value, format, roundingFunction);

          if( svg ){
            let split = output.split(locale.currency.symbol);
            if ( string ){
              output = `<tspan>${split[0]}<tspan class="small-symbol">${locale.currency.symbol}</tspan>${split[1] !== undefined ? split[1] : ''}</tspan>`;
            }else {
              output = (<tspan>{split[0]}<tspan className="small-symbol">{locale.currency.symbol}</tspan>{split[1]}</tspan>);
            }
          }else{
            let split = output.split(locale.currency.symbol);
            if ( string ){
              output = `${split[0]}<span class="small-symbol">${locale.currency.symbol}</span>${split[1] !== undefined ? split[1] : ''}`;
            }else {
              output = (<>{split[0]}<span className="small-symbol">{locale.currency.symbol}</span>{split[1]}</>);
            }
          }

          return output;
      }
      numeral.patched = true;
    }

    let context = {
      ...this.state,
      app_container: this.app_container,
      login_user: loginUser,
      signout_user: this.signoutUser,
      updateUserStatus: this.update_user_state,
      user_has_authenticated: this.user_has_authenticated,
      currentLogo: this.currentLogo,
      currentLanguage: this.currentLanguage,
      changeLanguage: this.changeLanguage,
      changeBackground: this.changeBackground,
      changeActiveAccount: this.changeActiveAccount,
      unsetActiveAccount: this.unsetActiveAccount,
      toggleBGI: this.toggleBGI,
      setDeviceToken: setDeviceToken,
      axiosCancel: axiosCancel,
      getGreeting: this.greetingText,
      ieCheck: this.ieCheck,
      checkIconImported: this.check_icon_imported,
      i18n: this.i18n
    }

    return !this.state.isAuthenticating && (
      <div className={"app-container" + (!this.ieCheck() && this.state.show_bgi? ' show-bgi': '')} style={{backgroundColor: this.state.theme.background_color }}>
        <Helmet>
          <title>{this.state.theme.default_title}</title>
        </Helmet>
        <ThemeStyles theme={this.state.theme} />
        <BrowserRouter>
          <Routes context={context} />
        </BrowserRouter>
      </div>
    )
  }
}

const AppTrans = withTranslation()(AppContent);

const AppLoader = () => (
  <div className="app-loader-placeholder">
    <ComponentLoadingPlaceholder /> 
  </div>
);

export default class App extends React.Component{
  render(){
    fourlytics();
    return (
      <Suspense fallback={<AppLoader />}>
        <AppTrans />
      </Suspense>
    );
  }
}
