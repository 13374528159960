import React from 'react';
import { FillLayout } from '../layout/layout';
import { Trans } from "react-i18next";

import './notfound.css';

const NotFound = () => (
  <FillLayout headerClassName="notfound" containerClassName="notfound">
    <h3><Trans>Sorry, can't seem to find this page.</Trans></h3>
    <h1><span className="notfoundicon">\(&ordm;&#126;&ordm;)/</span></h1>
  </FillLayout>
);

export default NotFound;