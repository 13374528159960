import React from 'react';

import { BoxLayout } from '../layout/layout';
import { invokeApi } from '../../libs/authlib'; 
import { LangChangeSelect } from '../../containers/components/changelang';
import { ComponentLoadingPlaceholder } from '../../containers/components/loadingplaceholder';

import LoaderButton from '../../containers/components/loaderbutton';
import PasswordCriteria from '../../containers/components/passwordCriteriaWidget/passwordCriteriaWidget';

import Terms from './terms'; 

import {
    FormGroup,
    FormControl,
    InputGroup,
    Glyphicon,
    HelpBlock,
    Checkbox
  } from 'react-bootstrap';

import { Trans } from 'react-i18next';
import AppContext from '../../appcontext';

import './activate.css';

class Activate extends React.Component {

    render(){
        return(
            <div className="activate-container">
                <BoxLayout containerClassName="activate-box">
                    <ActivateContent code={this.props.match.params.code}/>
                </BoxLayout>
            </div>
        )
    }
}

export default Activate;

class ActivateContent extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        
        this.state = {
          isLoading: true,
          isSubmitting: false,
          code: props.code,
          code_status:null,
          activate_status: null,
          verify: "",
          showVerify: true,
          passwordMasked: true,
          password: "",
          isValid: false,
          showTerms: true,
          termsAgree: false,
          showLang: true
        };
    }

    async componentDidMount() {
        try {
          await this.checkRegCode();
          this.setState({code_status: 'active'});
        } catch (e) {
            if(e.data.status_code === 400){
                this.setState({code_status: "Expired"});
            } else {
                console.error(e);
            }
        }
        this.setState({isLoading: false});
    }

    checkRegCode() {
        return invokeApi({
                path: "/client/auth/regcode/"+ this.state.code,
                method: "GET",
                unauth: true
        });  
    }

    handleSubmit= async (event) => {
        event.preventDefault();
        this.setState({ isSubmitting: true });
        try {
            await invokeApi({
                path: "/client/auth/regcode/"+ this.state.code,
                method: "POST",
                body: { password: this.state.password, verify: this.state.verify },
                unauth: true
            }); 
            this.context.navigate('/');
        } catch (e) {
            if(e.data.message === 'invalid_verification'){
                this.setState({ isSubmitting: false, activate_status: e.data.message, showVerify: true, password: '' });
            }else if(e.data.message === 'code_invalid'){ 
                this.setState({ isSubmitting: false, activate_status: e.data.message, showVerify: true, verify: '', password: '' });
            }
            console.error(e);
            
        }
    }

    handleChange = (event) => {
        this.setState({
          [event.target.id]: event.target.value,
          activate_status: null
        });
    }

    validate_password = () => {
        return /.*[A-Z]{1,}.*/.test(this.state.password) 
               && /.*[a-z]{1,}.*/.test(this.state.password) 
               && /.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_=~`]{1,}.*/.test(this.state.password) 
               && /.*[+-]{1,}.*/.test(this.state.password) === false
               && /.*[0-9]{1,}.*/.test(this.state.password)
               && this.state.password.length >= 8
    }

    automaticRedirect() {
        setTimeout(function (){ document.querySelector("#return-to-login").click()}, 4000);
    }

    render(){
        return  (
            <div className="activate-content-container">
                { this.state.isLoading ? <ComponentLoadingPlaceholder text='Verifying Code' /> : false }
                { !this.state.isLoading && this.state.code_status === 'code_invalid' ? (
                    <div className="activate-content-error-container">
                        <div className="activate-content-error-text"><Trans>The activation code provided is incorrect or has expired</Trans></div>
                    </div>
                ) : false }
                { !this.state.isLoading && this.state.code_status === 'active' ? 
                    this.state.showLang ? (
                        <div className="activate-content-lang-container">
                            <div className="activate-content-title">
                                <span>Language / Langue</span>
                            </div>
                            <div className="activate-content-lang-select-btn" onClick={()=>{this.context.changeLanguage('en'); this.setState({showLang: false, showTerms: true});}}>
                                <span>English</span>
                            </div>
                            <div className="activate-content-lang-select-btn" onClick={()=>{this.context.changeLanguage('fr'); this.setState({showLang: false, showTerms: true});}}>
                                <span>Fran&ccedil;ais</span>
                            </div>
                        </div>
                    ) : this.state.showTerms ? (
                        <div className="activate-content-toc-container">
                            <h3 style={{textAlign: 'center'}}><Trans>Terms and Conditions</Trans></h3>
                            <div className="activate-content-terms">
                                <Terms/>
                            </div>
                            <div className="activate-content-terms-actions">
                                <div className="activate-content-terms-agree">
                                    <Checkbox value={this.state.termsAgree} onChange={e=>this.setState({termsAgree: e.target.checked})}>
                                        <Trans>I have read and agree to the above Terms and Conditions</Trans>
                                    </Checkbox>
                                </div>
                                <div className="activate-content-terms-btns">
                                    <LoaderButton
                                        block
                                        bsSize="large"
                                        disabled={ !this.state.termsAgree }
                                        className="submit-password-button"
                                        type="button"
                                        isLoading={false}
                                        text={this.context.i18n.t('Continue')}
                                        loadingText={this.context.i18n.t('Agreeing') + '…'}
                                        onClick={()=>this.setState({ showVerify: true, showTerms: false })} />
                                </div>

                            </div>
                        </div>
                    ) : this.state.showVerify ? (
                        <div className="activate-content-verify-container">
                            <h3><Trans>To confirm your identity, please enter the first 5 characters of any of your PWL accounts (41XXX)</Trans></h3>
                            <form className="activate-content-verify-form" onSubmit={(e)=>e.preventDefault()}>
                                <FormGroup controlId="verify" bsSize="large"  validationState={this.state.activate_status === 'invalid_verification'? 'error': null}>
                                    <FormControl
                                        placeholder={this.context.i18n.t('Account Number')}
                                        value={this.state.verify}
                                        maxLength={5}
                                        onChange={this.handleChange} />
                                    {this.state.activate_status === 'invalid_verification'? <HelpBlock><Trans>Invalid Account Number</Trans></HelpBlock> : false }
                                </FormGroup>
                                <LoaderButton
                                    block
                                    bsSize="large"
                                    disabled={ this.state.verify.length !== 5 }
                                    className="submit-password-button"
                                    type="button"
                                    isLoading={false}
                                    text={this.context.i18n.t('Next')}
                                    loadingText={this.context.i18n.t('Checking') + '…'}
                                    onClick={()=>this.setState({ showVerify: false })} />
                            </form>
                        </div>
                    ) : (
                        <div className="activate-content-password-container">
                            <h3><Trans>Please enter a password to use to access your account</Trans></h3>
                            <form className="activate-content-password-form" onSubmit={this.handleSubmit}>
                                <FormGroup controlId="password" bsSize="large">
                                    <InputGroup>
                                        <FormControl
                                            placeholder={this.context.i18n.t('Password')}
                                            value={this.state.password}
                                            onChange={this.handleChange}
                                            type={this.state.passwordMasked? "password": "text"} />
                                        <InputGroup.Addon>
                                            <Glyphicon 
                                                glyph={ (!this.state.passwordMasked ? "eye-open" : "eye-close") }
                                                onClick={() => this.setState({passwordMasked: this.state.passwordMasked? false : true})}
                                                className="password-eye-icon"
                                                />
                                        </InputGroup.Addon>
                                    </InputGroup>
                                </FormGroup>
                                <PasswordCriteria className="activate-content-password-criteria" password={this.state.password}/>
                                <LoaderButton
                                    block
                                    bsSize="large"
                                    disabled={ ! this.validate_password() }
                                    className="submit-password-button"
                                    type="submit"
                                    isLoading={this.state.isSubmitting}
                                    text={this.context.i18n.t('Submit')}
                                    loadingText={this.context.i18n.t('Submitting') + '…'} />
                            </form>
                        </div>
                    ) : this.state.code_status === 'Expired'? (
                        <div>
                            <div className="activate-content-error-container">
                                <div className="activate-content-error-text">
                                    <Trans>The activation code provided is incorrect or has expired</Trans>
                                </div>
                            </div>
                            <div className="activate-content-redirect-text">
                                <Trans>You will be redirected automatically to the login page, or click the link below</Trans>
                            </div>
                            <div className="activate-content-redirect-login-link"><a id='return-to-login' href="/login"><Trans>Click here to login</Trans></a></div>
                            {this.automaticRedirect()}
                        </div>
                    ) : false }
                <LangChangeSelect lang={this.context.currentLanguage()} />
            </div>
        )
    }
}
