import React from 'react';
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { LangChangeSelect } from '../../containers/components/changelang';

import numeral from 'numeral';
import moment from 'moment';

import { BoxLayout } from '../layout/layout';

import AppContext from '../../appcontext';
import { Trans } from 'react-i18next';

import './selectaccount.css';

class SelectAccount extends React.PureComponent {
  static contextType = AppContext;

  changeAccount(acct){
    this.context.changeActiveAccount(acct);
    this.context.navigate('/')
  }

  renderAccounts(){

    return (
      <div className="selectaccount-content">
        <BoxLayout>
            <ListGroup className="selectaccount-accounts">
              {
                Object.values(this.context.user_accounts).map((acct ,i)=>{
                  return (
                  <ListGroupItem key={acct.uuid} header={acct.name !== undefined ? acct.name : <span><Trans>Account</Trans> {i+1}</span>} 
                                 className="selectaccount-accounts-item" onClick={()=>this.changeAccount(acct.uuid)}>
                    <span className="selectaccount-accounts-item-asset-container">
                      <span className="selectaccount-accounts-item-asset-value">{acct.pf_summary !== undefined && acct.pf_summary.total !== undefined ? numeral(acct.pf_summary.total).format('$ 0,0') : '--'}</span>
                      <span className="selectaccount-accounts-item-asset-asof">{acct.pf_summary.date !== undefined? <span>&nbsp;<Trans>as of</Trans> {moment(acct.pf_summary.date, 'YYYY-MM-DD').format('ll')}</span> : false}</span>
                    </span>
                  </ListGroupItem>
                  )
                })
              }
            </ListGroup>
            <LangChangeSelect lang={this.context.currentLanguage()} containerClass='login-lang-select' />
        </BoxLayout>    
      </div>
    );
  }

  render() {
    return (
      <div className="SelectAccount">
        { this.renderAccounts() }
      </div>
    );
  }

}

export default SelectAccount;