import React from 'react';
import { Route } from 'react-router-dom';
import { NavItem, MenuItem } from 'react-bootstrap';

export const RouteNavItem = (props) => (
  <Route path={props.href} exact children={({ match, history }) => (
    <NavItem {...props} active={ match ? true : false }>{ props.children }</NavItem>
  )}/>
);

export const RouteMenuItem = (props) => (
  <Route path={props.href} exact children={({ history }) => (
    <MenuItem {...props} >{ props.children }</MenuItem>
  )}/>
);