import config from './config'

export default function insertFourlytics() {
  if (config.fourlyticsId !== undefined && document.querySelector("#fourlyticsAdd") === null) {
    const script = document.createElement("script");
    script.id = 'fourlyticsAdd';

    script.innerHTML = `//Fourlytics
    //Tracker code
    let _paq = window._paq = window._paq || [];
    let previousURL = '';
  
    //Used to set the title and delay for pushing information to Mamoto
    function addToMamoto(title) {
      if (title === "Sign In Attempted") {
        newSession();
      }
      _paq.push(['setReferrerUrl', previousURL]);
      _paq.push(['setCustomUrl', document.URL]);
      _paq.push(['setDocumentTitle', title]);
      // remove all previously assigned custom variables, requires Matomo (formerly Piwik) 3.0.2
      _paq.push(['deleteCustomVariables', 'page']);
      _paq.push(['setGenerationTimeMs', 0]);
      //sets the userID if available
      try { _paq.push(['setUserId', getMuuid()]); } catch{ };
      _paq.push(['trackPageView']);
      // make Matomo aware of newly added content
      let content = document.getElementById('root');
      _paq.push(['MediaAnalytics::scanForMedia', content]);
      _paq.push(['FormAnalytics::scanForForms', content]);
      _paq.push(['trackContentImpressionsWithinNode', content]);
      _paq.push(['enableLinkTracking']);
      _paq.push(['appendToTrackingUrl', '']);
      previousURL = '';
      previousURL = document.URL + " ";
    }
  
    //gets muuid for the userid
    function getMuuid() {
      return localStorage.getItem("muuid");
    }
  
    //starts a new session to start recording in Matomo
    function newSession() {
      _paq.push(['resetUserId']);
      _paq.push(['appendToTrackingUrl', 'new_visit=1']);
    }
  
    //triggers anytime anything is clicked on the screen
    window.addEventListener('click', function (e) {
      //for any tabs the user clicks over to
      if (e.target.tagName === "A" && e.target.text !== "EN" && e.target.text !== "FR" && e.target.text !== "English" && e.target.text !== "French") {
        addToMamoto(e.target.text);
      //if the user clicks the sign in button
      } else if (e.target.tagName === "BUTTON" && (e.target.innerHTML === "Login" || e.target.innerHTML === "Connexion" || e.target.innerHTML === "Logging In..." || e.target.innerHTML === "Connexion en cours…")) {
        addToMamoto("Sign In Attempted");
      //if the user guesses the answer of the security question
      } else if (e.target.tagName === "BUTTON" && (e.target.innerHTML === "Continue" || e.target.innerHTML === "Poursuivre" || e.target.innerHTML === "Authorizing…" || e.target.innerHTML === "Autorisation en cours…")) {
        addToMamoto("Security Question Answered");
      }
    });
  
    //checks to see if the user is on the login page
    let temp = document.URL.split("/");
    if (temp[temp.length - 1] === "login") {
      newSession();
    }
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
      let u = "https://fourlytics.foureyes.financial/";
      _paq.push(['setTrackerUrl', u + 'js/tracker.php']);
      _paq.push(['setSiteId', '` + config.fourlyticsId + `']);
      _paq.push(['appendToTrackingUrl', '']);
      previousURL = document.URL + " ";
      let d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
      g.type = 'text/javascript'; g.async = true; g.src = u + 'js/tracker.php'; s.parentNode.insertBefore(g, s);
    })();
    //End Fourlytics Code`;

    document.body.appendChild(script);
  }
}