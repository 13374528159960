import React from 'react';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from 'react-datepicker';

import { 
    FormControl,
    FormGroup
} from 'react-bootstrap'

import { FillLayout } from '../layout/layout.js';
import { invokeApi } from '../../libs/authlib';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Trans } from 'react-i18next';
import AppContext from '../../appcontext';

import { PortfolioAssetBucketDoublePie } from './portfolioscharts';
import { ComponentLoadingPlaceholder } from '../components/loadingplaceholder'

import "./portfolios.css"

/* Portfolios with Container */
class Portfolios extends React.Component {
    static contextType = AppContext;

    render() {
        return (  
            <div className="portfolios-container">
            <FillLayout containerClassName="portfolios-box">
                <PortfoliosContainer />
            </FillLayout>
            </div>
        );
    }
}
  
export default Portfolios;

class PortfoliosContainer extends React.Component {
    static contextType = AppContext;
  
    constructor(props) {
      super(props);
      
      this.state = {
        active_account: 'summary',
        accounts_loading: true,
        accounts_updating: true,
        accounts_list: {},
        accounts_historical: {},
        max_start: new Date(1970, 1, 1),
        default_start: new Date(1970, 1, 1),
        end_date: moment().subtract(1, 'days').toDate(),
        max_end: moment().subtract(1, 'days').toDate(),
        disable_dates: false,
        pf_summary: {},
        pf_summary_loading: true
      }
    }
  
    componentDidMount(){
      this.signal = this.context.axiosCancel();
  
      if ( this.context.user_account !== undefined) {
        this.getAccounts(this.formatAccountList);
        this.getHistorical(this.formatAccounts, null, 'summary');
        this.getPfSummary();
      } else {
        this.setState({ loading_error: this.context.i18n.t("No account provided.")  });
      }
    }
  
  
    componentWillUnmount() {
      this.signal.cancel();
    }
  
    async getAccounts(success_callback){
      try{
        let path = '/client/' + this.context.user_account + "/portfolios";
  
        await invokeApi({
           path: path,
           method: 'GET',
           onSuccess: r => success_callback(r),
           onError: e => this.setState({ accounts_loading : false, loading_error : e })
        });
      }catch(e){
        console.error(e);
      }
    }

    formatAccountList = (accounts) => {
      let accounts_list = {};

      for (let i=0; i < accounts.length; i++) {
        accounts_list[accounts[i].pf_uuid] = accounts[i];
      }

      this.setState({accounts_list: accounts_list});
    }

    async getHistorical(success_callback, date=null, acct=null){
      try{
        let path = '/client/' + this.context.user_account + "/portfolios/historical?assets=";

          if (date !== null){
            path = path + "&d=" + moment(date).format("YYYY-MM-DD");
          }

        await invokeApi({
           path: path,
           method: 'GET',
           onSuccess: r => success_callback(r, acct),
           onError: e => this.setState({ accounts_loading : false, accounts_updating : false, loading_error : e })
        });
      }catch(e){
        console.error(e);
      }
    }
  
    formatAccounts = (accounts, acct=null) => {
      let dates = { start_date: false, end_date: false };
  
      if ( acct !== null && accounts[acct] !== undefined ){
        dates = accounts[acct]['pfDates'];
      }
      if (accounts[this.state.active_account] !== undefined) {
        dates = accounts[this.state.active_account]['pfDates'];
      }

      let start = dates['start_date'] !== undefined ? moment.min(moment(dates['start_date'], 'YYYY-MM-DD'), moment().subtract(1, 'days')).toDate(): moment().subtract(1, 'days').hour(12).toDate();

      this.setState({ 
        accounts_historical: accounts,
        accounts_loading : false,
        accounts_updating : false,
        disable_dates: !(Object.keys(accounts).length > 0),
        max_start: start,
        default_start: start
      });
    }

    updateAccounts = (accounts) => {
      let acct_stats = {};
  
      for (let i=0; i < accounts.length; i++) {
        let acct = accounts[i];
        if (acct['pfDataFeed']) {
          if ( acct['pfTimeStats'] !== undefined ){
            acct_stats[acct['pf_uuid']] = acct['pfTimeStats'];
          }
        }
        if (acct['pfSummary'] !== undefined && acct['pfSummary']) {
          acct_stats['summary'] = acct['pfTimeStats'];
        }
      }
  
      this.setState({
        account_stats: acct_stats, 
      });
    }

    async getPfSummary(date=null){
      try{
        this.setState({pf_summary_loading: true})

        let path = '/client/' + this.context.user_account + "/chartdata/dashpfsummary";
        let body = {}

        if (date !== null){
          body['asof'] = moment(date).format("YYYY-MM-DD");
        }
      
        await invokeApi({
           path: path,
           method: 'POST',
           body: body,
           onSuccess: r => this.setState({ pf_summary: r, pf_summary_loading: false }),
           onError: e => this.setState({ pf_summary_loading : false, loading_error : e })
        });
      }catch(e){
        console.error(e);
      }
    }

    changeActiveAccount=(account)=>{
      let max_start = this.state.default_start;
      let max_end = moment().subtract(1, 'days').toDate();

      if (account !== 'summary'){
        let acct_info = this.state.accounts_list[account];
        if(acct_info !== undefined){
          if (acct_info.pfAccount !== undefined && acct_info.pfAccount !== null && acct_info.pfAccount.account_status !== undefined){
            if(acct_info.pfAccount.account_status.toLowerCase() === 'closed'){
              if(acct_info.pfAccount.last_txn_dt !== undefined && acct_info.pfAccount.last_txn_dt !== null){
                max_end = moment.unix(acct_info.pfAccount.last_txn_dt).toDate();
              }
            }
            if(acct_info.pfAccount.ts_created !== undefined && acct_info.pfAccount.ts_created !== null){
              max_start = moment.unix(acct_info.pfAccount.ts_created).toDate();
            }
          }
        }
      }

      this.setState({ active_account: account, max_end: max_end, max_start: max_start });
      this.handleUpdateEnd(this.state.end_date, account);
    }


    handleUpdateEnd = (v, acct=null) => {
      this.setState({ accounts_updating: true});
      this.getHistorical(this.formatAccounts, v, acct);
      this.getPfSummary(v);

      this.setState({ end_date: v , timewindow: 0});
    }

    handleChangeEnd = (v) => {
      this.setState({ accounts_updating: true});
      this.getHistorical(this.formatAccounts, v);
      this.getPfSummary(v);

      this.setState({ end_date: v , timewindow: 0});
    }



    getAccountListOrder(accounts){
      let apnum = ['A','B','S','R','T','W','Z','Y','N','P','0']

      let sort_items = Object.values(accounts).map((a,i)=>{
          let item = {root: '', last: '', id: ''}

          if(a !== null && a['pfSummary'] !== true){
              item['root'] = (''+a.pfAccount.account_number).slice(0, 3);
              item['last'] = (''+a.pfAccount.account_number).slice(-1);
              item['id'] = a.pf_uuid;
  
              return item;
          } else {
              return null;
          }
      });

      sort_items = sort_items.filter((v)=> v !== null);

      sort_items.sort(function (a,b){
          let root = a.root.localeCompare(b.root);
          let last = a.last === b.last ? 0
                     : apnum.indexOf(a.last) !== -1 &&  apnum.indexOf(b.last) !== -1 ? apnum.indexOf(a.last) > apnum.indexOf(b.last) ? 1 : -1
                     : apnum.indexOf(a.last) === -1 &&  apnum.indexOf(b.last) === -1 ? a.last.localeCompare(b.last)
                     : apnum.indexOf(a.last) === -1 ? 1 : -1 ;

          return root !== 0? root : last;
      });

      return sort_items.map(a => a.id);
    }

    render(){

      return (
        <div className="portfolios-grid">
          <div className="portfolios-sidebar">
            <div className="portfolios-sidebar-select-account-container">
            { !this.state.accounts_loading ? (
                [
                <FormGroup key='account' className="portfolios-sidebar-select-account">
                  <FormControl componentClass="select" value={this.state.active_account} onChange={(e)=>this.changeActiveAccount(e.target.value)}>
                    <option value="summary">{this.context.i18n.t('All Accounts')}</option>
                    {
                      this.getAccountListOrder(this.state.accounts_list).map((acct) => {
                          if (acct !== 'summary') {
                            let acct_info = this.state.accounts_list[acct];
                            if (acct_info !== undefined){
                              if(
                                 acct_info.pfAccount !== undefined && acct_info.pfAccount !== null 
                                 && ( 
                                    //Check Status is Set, default pass
                                    acct_info.pfAccount.account_status === undefined 
                                    || (
                                       // if status is set check the set status
                                       acct_info.pfAccount.account_status !== undefined 
                                       && ( 
                                          // if the status is active check that the selected date is after the created date
                                            (
                                              acct_info.pfAccount.account_status.toLowerCase() === 'active' 
                                              && acct_info.pfAccount.ts_created !== undefined 
                                              && acct_info.pfAccount.ts_created !== null
                                              && moment.unix(acct_info.pfAccount.ts_created).isSameOrBefore(moment(this.state.end_date), 'day') 
                                            ) 
                                            || 
                                            (
                                              // if the status is closed check that the selected date is before the closed date
                                              acct_info.pfAccount.account_status.toLowerCase() === 'closed' 
                                              && acct_info.pfAccount.last_txn_dt !== undefined 
                                              && acct_info.pfAccount.last_txn_dt !== null
                                              && moment.unix(acct_info.pfAccount.last_txn_dt).isSameOrAfter(moment(this.state.end_date), 'day') 
                                              && acct_info.pfAccount.ts_created !== undefined 
                                              && acct_info.pfAccount.ts_created !== null
                                              && moment.unix(acct_info.pfAccount.ts_created).isSameOrBefore(moment(this.state.end_date), 'day') 
                                            )
                                          )
                                      )
                                    )
                              ){
                              
                                return (<option key={acct+'-option'}  value={acct}>
                                  {acct_info.pfAccount !== undefined && acct_info.pfAccount !== null && acct_info.pfAccount.account_name !== undefined ? acct_info.pfAccount.account_name : ''}&nbsp;-&nbsp;
                                  {acct_info.pfType !== undefined ? acct_info.pfType : '' }&nbsp;-&nbsp;
                                  {acct_info.pfAccount !== undefined && acct_info.pfAccount !== null && acct_info.pfAccount.account_number !== undefined ? acct_info.pfAccount.account_number : '' }
                                </option>);
                              }
                            }
                          }
                          return false;
                      })
                    }
                  </FormControl>
                </FormGroup>,
                <div key="date-picker" className="portfolios-date-picker-container">
                  <DatePicker key="end-month"
                      locale={this.context.currentLanguage()}
                      disabled={this.state.disable_dates}
                      className="portfolios-date-picker"
                      customInput={<DatePickerInput />}
                      selected={this.state.end_date}
                      minDate={this.state.max_start}
                      maxDate={this.state.max_end}
                      onChange={this.handleChangeEnd}
                      popperPlacement="bottom"
                      popperModifiers={{
                          flip: {
                              behavior: ["bottom"] // don't allow it to flip to be above
                          },
                          preventOverflow: {
                              enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
                          },
                          hide: {
                              enabled: false // turn off since needs preventOverflow to be enabled
                          }
                      }}
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                  />
                </div>
              ]
              ) : (<div className="portfolios-sidebar-nav-accounts-loading"><FontAwesomeIcon icon={["far", "circle-notch"]}  className="fa-ease-in-out-spin" /> <Trans>Loading Accounts</Trans></div>) 
            }
            </div>
          </div>
          <PortfoliosContent 
            accountsUpdating={this.state.accounts_updating}
            activeAccount={this.state.active_account}
            accounts={this.state.active_account==='summary'? (this.state.accounts_list["summary"] !== undefined ? this.state.accounts_list["summary"]['pfPortfolios'] : Object.keys(this.state.accounts_list) ) : [this.state.active_account]}
            accountsInfo={this.state.accounts_list}
            accountsHistorical={this.state.accounts_historical}
            pfSummary={this.state.pf_summary}
            pfSummaryLoading={this.state.pf_summary_loading}/>
        </div>
      );
    }
  }


  class DatePickerInput extends React.Component {
    render() {
      return (
        <div className={"portfolios-date-picker-input" + 
                        (this.props.className !== undefined ? " " + this.props.className : "") +
                        (this.props.disabled ? " disabled" : "")} 
            onClick={this.props.onClick}>
          {moment(this.props.value, 'MM/DD/YYYY').format("ll")}
          <span className="portfolios-date-picker-input-arrow" ><FontAwesomeIcon icon={["far", "angle-down"]} /></span>
        </div>
      )
    }
  }

  class PortfoliosContent extends React.Component {
    static contextType = AppContext;
  
    constructor(props) {
      super(props);
      
      this.state = {
        holding_map: {}
      }
    }

    componentDidMount(){
      this.get_holding_map();
    }

    componentDidUpdate(prevProps){
      if (prevProps.activeAccount !== this.props.activeAccount || JSON.stringify(prevProps.accountsHistorical) !== JSON.stringify(this.props.accountsHistorical)){
        this.get_holding_map();
      }
    }

    get_holding_map = () => {
      let hm = {}

      if(this.props.accountsHistorical[this.props.activeAccount] !== undefined){
        let match_keys = Object.keys(this.props.accountsHistorical[this.props.activeAccount].pfHoldingMatch);
        let uuid_match = this.props.accountsHistorical[this.props.activeAccount].pfMatchGuide;

        for(let i=0; i < match_keys.length; i++){
          let holding = this.props.accountsHistorical[this.props.activeAccount].pfHoldingMatch[match_keys[i]];

          for(let c=0; c < holding['asset_allocation'].length; c++){
              if (hm[holding['asset_allocation'][c].bucket] === undefined) {
                hm[holding['asset_allocation'][c].bucket] = {};
              }
              if (hm[holding['asset_allocation'][c].bucket][holding['asset_allocation'][c].name] === undefined) {
                hm[holding['asset_allocation'][c].bucket][holding['asset_allocation'][c].name] = {};
              }
              if (hm[holding['asset_allocation'][c].bucket][holding['asset_allocation'][c].name][uuid_match[match_keys[i]]] === undefined){
                let {name, pf_uuid, symbol, type} = holding
                let meta = { name: name, pf_uuid: pf_uuid, symbol: symbol, type: type, value: 0}

                meta['value'] += holding['value'] * (holding['asset_allocation'][c].percent/100);

                hm[holding['asset_allocation'][c].bucket][holding['asset_allocation'][c].name][uuid_match[match_keys[i]]] = meta;
              }else{
                hm[holding['asset_allocation'][c].bucket][holding['asset_allocation'][c].name][uuid_match[match_keys[i]]]['value'] += holding['value'] * (holding['asset_allocation'][c].percent/100);
              }
          }
        }
      }

      this.setState({holding_map: hm});

    }

    get_bucket_values = (acct) => {
      let bv = {}
      if(this.props.accountsHistorical[acct] !== undefined){
        let match_keys = Object.keys(this.props.accountsHistorical[acct].pfHoldingMatch);

        for(let i=0; i < match_keys.length; i++){
          let holding = this.props.accountsHistorical[acct].pfHoldingMatch[match_keys[i]];
          let hb = Object.keys(holding['bucket_allocation']);

          for(let c=0; c < hb.length; c++){
            if (bv[hb[c]] === undefined) {
              bv[hb[c]] = 0;
            }
            bv[hb[c]] += holding.value;

          }
        }
      }
      return bv;
    }

    getAccountListOrder(accounts){
      let apnum = ['A','B','S','R','T','W','Z','Y','N','P','0']

      let sort_items = Object.values(accounts).map((a,i)=>{
          let item = {root: '', last: '', id: ''}

          if(a !== null && a.pfSummary !== true){
              item['root'] = (''+a.pfAccount.account_number).slice(0, 3);
              item['last'] = (''+a.pfAccount.account_number).slice(-1);
              item['id'] = a.pf_uuid;
  
              return item;
          } else {
              return null;
          }
      });

      sort_items = sort_items.filter((v)=> v !== null);

      sort_items.sort(function (a,b){
          let root = a.root.localeCompare(b.root);
          let last = a.last === b.last ? 0
                     : apnum.indexOf(a.last) !== -1 &&  apnum.indexOf(b.last) !== -1 ? apnum.indexOf(a.last) > apnum.indexOf(b.last) ? 1 : -1
                     : apnum.indexOf(a.last) === -1 &&  apnum.indexOf(b.last) === -1 ? a.last.localeCompare(b.last)
                     : apnum.indexOf(a.last) === -1 ? 1 : -1 ;

          return root !== 0? root : last;
      });

      return sort_items.map(a => a.id);
    }

    render(){
      return (
        <div className="portfolios-content">
            <div className="portfolios-content-container">
              <div className="portfolios-content-summary-container">
                <div className="portoflios-content-summary-header"><Trans>Portfolio Summary</Trans>&nbsp;<div className="portfolios-content-account-header">{(this.props.accounts.length > 1 || this.props.accounts.length === 0)? (<Trans>All Accounts</Trans>): this.props.accountsInfo[this.props.accounts[0]] !== undefined ? (<span>{this.props.accountsInfo[this.props.accounts[0]] !== undefined && this.props.accountsInfo[this.props.accounts[0]].pfAccount !== undefined && this.props.accountsInfo[this.props.accounts[0]].pfAccount !== null && this.props.accountsInfo[this.props.accounts[0]].pfAccount.account_name !== undefined ? this.props.accountsInfo[this.props.accounts[0]].pfAccount.account_name : ''}&nbsp;-&nbsp;{this.props.accountsInfo[this.props.accounts[0]] !== undefined && this.props.accountsInfo[this.props.accounts[0]].pfType !== undefined ? this.props.accountsInfo[this.props.accounts[0]].pfType : '' }&nbsp;-&nbsp;{this.props.accountsInfo[this.props.accounts[0]] !== undefined && this.props.accountsInfo[this.props.accounts[0]].pfAccount !== undefined && this.props.accountsInfo[this.props.accounts[0]].pfAccount !== null && this.props.accountsInfo[this.props.accounts[0]].pfAccount.account_number !== undefined ? this.props.accountsInfo[this.props.accounts[0]].pfAccount.account_number : '' }</span>) : false}</div></div>
                <div className="dashboard-content-pfsummary-content-container">
                  <div className="dashboard-content-pfsummary-chart">
                    <div className="dashboard-content-pfsummary-chart-header"><Trans>Current Allocation</Trans></div>
                    <div className="dashboard-content-pfsummary-chart-container" ref={container => this.assetcontainer = container}>
                    {!this.props.pfSummaryLoading ? (<PortfolioAssetBucketDoublePie  size={Math.min(this.assetcontainer.getBoundingClientRect().width, this.assetcontainer.getBoundingClientRect().height) } data={this.props.pfSummary[this.props.accounts.length !== 1? 'summary': this.props.accounts[0]]}/>) 
                                                  : <ComponentLoadingPlaceholder text={(<><Trans>Loading</Trans> <Trans>Portfolio Summary</Trans></>)} />}
                    </div>
                  </div>
                  <div className="dashboard-content-pfsummary-table">
                    <div className="dashboard-content-pfsummary-table-header">
                      <div className="dashboard-content-pfsummary-table-header-item box-item"></div>
                      <div className="dashboard-content-pfsummary-table-header-item title-item"><Trans>Type</Trans></div>
                      <div className="dashboard-content-pfsummary-table-header-item value-item"><Trans>Market Value (CAD)</Trans></div>
                      <div className="dashboard-content-pfsummary-table-header-item pct-item"><Trans>% of Portfolio</Trans></div>
                      <div className="dashboard-content-pfsummary-table-header-item target-item"></div>
                    </div>
                    <div className="dashboard-content-pfsummary-table-rows">
                      {!this.props.pfSummaryLoading ? Object.entries(
                                     {'CASH & EQUIVALENT': '#a4de6c',
                                      'INCOME INVESTMENTS': '#8dd1e1',
                                      'ALTERNATIVE INVESTMENTS': '#a4de6c',
                                      'BALANCED FUNDS': '#83a6ed',
                                      'EQUITY': '#8884d8',
                                      'UNKNOWN': '#FFCC62'}).map(ac=>{
                        let cat = ac[0];
                        let color = ac[1];
                        let acct = this.props.pfSummary[this.props.accounts.length !== 1? 'summary': this.props.accounts[0]];
                        if (acct !== undefined && acct[cat] !== undefined) {
                          return (
                            <div key={(this.props.accounts.length !== 1? 'summary': this.props.accounts[0])+'-'+cat} className="dashboard-content-pfsummary-table-row">
                              <div className="dashboard-content-pfsummary-table-row-item box-item"><div className="dashboard-content-pfsummary-table-legend-box" style={{backgroundColor: color}}></div></div>
                              <div className="dashboard-content-pfsummary-table-row-item title-item">{this.context.i18n.t(cat).toLocaleLowerCase()}</div>
                              <div className="dashboard-content-pfsummary-table-row-item value-item">{numeral(acct[cat]['value']).format('$ 0,0')}</div>
                              <div className="dashboard-content-pfsummary-table-row-item pct-item">{numeral(acct[cat]['pct']).format('0.0')} <span className='small-symbol'>%</span></div>
                              <div className="dashboard-content-pfsummary-table-row-item target-item">&nbsp;</div>
                            </div>
                          )
                        }else {
                          return false
                        }
                      }, this): false}
                    </div>
                    {!this.props.pfSummaryLoading ? (
                    <div className="dashboard-content-pfsummary-table-total">
                      <div className="dashboard-content-pfsummary-table-total-item box-item"></div>
                      <div className="dashboard-content-pfsummary-table-total-item title-item"><Trans>Total</Trans></div>
                      <div className="dashboard-content-pfsummary-table-total-item value-item">{this.props.pfSummary[this.props.accounts.length !== 1? 'summary': this.props.accounts[0]] !== undefined ? numeral(Object.values(this.props.pfSummary[this.props.accounts.length !== 1? 'summary': this.props.accounts[0]]).reduce((a,c)=> a + c['value'], 0)).format('$ 0,0') : (<>&nbsp;</>)}</div>
                      <div className="dashboard-content-pfsummary-table-total-item pct-item">100 %</div>
                      <div className="dashboard-content-pfsummary-table-total-item target-item">&nbsp;</div>
                    </div>) : false }
                  </div>
                </div>
              </div>
              {(this.props.accounts.length > 0 || this.props.accounts.length === 0)?(
              <div className="portfolios-content-details-container">
                <div className="portoflios-content-details-header"><Trans>Portfolio Details</Trans></div>
                <div className="portfolios-content-details-content">
                  {Object.keys(this.props.accountsHistorical).length > 0 && !this.props.accountsUpdating ? (
                    <div className="portfolios-content-details-portfolios-container">
                      <div className="portfolios-content-details-portfolios-header-item account-item"><Trans>Account</Trans></div>
                      <div className="portfolios-content-details-portfolios-header-item type-item"><Trans>Type</Trans></div>
                      <div className="portfolios-content-details-portfolios-header-item curr-item"><Trans>Currency</Trans></div>
                      <div className="portfolios-content-details-portfolios-header-item name-item"><Trans>Account Name</Trans></div>
                      <div className="portfolios-content-details-portfolios-header-item mv-item"><Trans>Market Value (CAD)</Trans></div>
                      <div className="portfolios-content-details-portfolios-header-item pct-item"><Trans>% of Your Total Portfolio</Trans></div>
                      {
                        (this.props.accounts.length > 1 ? this.getAccountListOrder(this.props.accountsHistorical) : this.props.accounts).map(id=>{
                          if (this.props.accountsHistorical[id] !== undefined && this.props.accountsHistorical[id] !== null){
                            return [
                              <div key={id+'-a'} className="portfolios-content-details-portfolios-item account-item">{this.props.accountsHistorical[id] !== undefined && this.props.accountsHistorical[id].pfAccount !== undefined && this.props.accountsHistorical[id].pfAccount.account_number !== undefined ? this.props.accountsHistorical[id].pfAccount.account_number : '' }</div>,
                              <div key={id+'-t'} className="portfolios-content-details-portfolios-item type-item">{this.props.accountsHistorical[id] !== undefined && this.props.accountsHistorical[id].pfType !== undefined ? this.props.accountsHistorical[id].pfType : '' }</div>,
                              <div key={id+'-c'} className="portfolios-content-details-portfolios-item curr-item">{this.props.accountsHistorical[id] !== undefined && this.props.accountsHistorical[id].pfAccount !== undefined && this.props.accountsHistorical[id].pfAccount.currency !== undefined ? this.props.accountsHistorical[id].pfAccount.currency : '' }</div>,
                              <div key={id+'-n'} className="portfolios-content-details-portfolios-item name-item">{this.props.accountsHistorical[id] !== undefined && this.props.accountsHistorical[id].pfAccount !== undefined && this.props.accountsHistorical[id].pfAccount.account_name !== undefined ? this.props.accountsHistorical[id].pfAccount.account_name : '' }</div>,
                              <div key={id+'-m'} className="portfolios-content-details-portfolios-item mv-item">{this.props.accountsHistorical[id] !== undefined && this.props.accountsHistorical[id].pfTotalValue !== undefined ? (<span>{numeral(this.props.accountsHistorical[id].pfTotalValue).format('$ 0,0')}</span>) : '' }</div>,
                              <div key={id+'-p'} className="portfolios-content-details-portfolios-item pct-item">{this.props.accountsHistorical[id] !== undefined && this.props.accountsHistorical[id].pfTotalValue !== undefined && this.props.accountsHistorical['summary'] !== undefined && this.props.accountsHistorical['summary'].pfTotalValue !== undefined? this.props.accountsHistorical['summary'].pfTotalValue !== 0 ? (<span>{numeral((this.props.accountsHistorical[id].pfTotalValue / this.props.accountsHistorical['summary'].pfTotalValue)*100).format('0.0')} <span className='small-symbol'>%</span></span>) : '--' : false}</div>
                            ]
                          } else {
                            return false;
                          }
                        })
                      }
                    </div>) : (<ComponentLoadingPlaceholder text={(<><Trans>Loading</Trans> <Trans>Portfolio Details</Trans></>)} />)
                  }
                </div>
              </div>):false}
              <div className="portfolios-content-holdings-container">
                <div className="portoflios-content-holdings-header">{(this.props.accounts.length > 1 || this.props.accounts.length === 0)?(<Trans>Consolidated Portfolio Holdings</Trans>):(<Trans>Portfolio Holdings</Trans>)}&nbsp;<div className="portfolios-content-account-header">{(this.props.accounts.length > 1 || this.props.accounts.length === 0)? (<Trans>All Accounts</Trans>): this.props.accountsInfo[this.props.accounts[0]] !== undefined ? (<span>{this.props.accountsInfo[this.props.accounts[0]] !== undefined && this.props.accountsInfo[this.props.accounts[0]].pfAccount !== undefined && this.props.accountsInfo[this.props.accounts[0]].pfAccount !== null && this.props.accountsInfo[this.props.accounts[0]].pfAccount.account_name !== undefined ? this.props.accountsInfo[this.props.accounts[0]].pfAccount.account_name : ''}&nbsp;-&nbsp;{this.props.accountsInfo[this.props.accounts[0]] !== undefined && this.props.accountsInfo[this.props.accounts[0]].pfType !== undefined ? this.props.accountsInfo[this.props.accounts[0]].pfType : '' }&nbsp;-&nbsp;{this.props.accountsInfo[this.props.accounts[0]] !== undefined && this.props.accountsInfo[this.props.accounts[0]].pfAccount !== undefined && this.props.accountsInfo[this.props.accounts[0]].pfAccount !== null && this.props.accountsInfo[this.props.accounts[0]].pfAccount.account_number !== undefined ? this.props.accountsInfo[this.props.accounts[0]].pfAccount.account_number : '' }</span>) : false}</div></div>
                <div className="portfolios-content-holdings-content">
                {Object.keys(this.props.accountsHistorical).length > 0 && !this.props.accountsUpdating ? (
                  <div className="portfolios-content-holdings-container">
                    <div className="portfolios-content-holdings-header-item name-item"><Trans>Type</Trans></div>
                    <div className="portfolios-content-holdings-header-item value-item"><Trans>Market Value (CAD)</Trans></div>
                    <div className="portfolios-content-holdings-header-item pct-item"><Trans>% of Portfolio</Trans></div>
                    {['CASH & EQUIVALENT', 'INCOME INVESTMENTS', 'ALTERNATIVE INVESTMENTS', 'BALANCED FUNDS', 'EQUITY', 'Unassigned', 'UNKNOWN'].map(cat=>{
                      let elements = []
                      let pftotal = this.props.accountsHistorical[this.props.activeAccount].pfTotalValue !== undefined? this.props.accountsHistorical[this.props.activeAccount].pfTotalValue : false;
                      
                      if (this.state.holding_map[cat] !== undefined){
                        let scs = Object.keys(this.state.holding_map[cat]);
                        let sch = this.state.holding_map[cat];

                        
                        elements.push(<div key={cat} className="portfolios-content-holdings-cat-header"><Trans>{cat}</Trans></div>)

                        let order_override = cat === 'EQUITY'? ["CANADIAN EQUITY ", "CANADIAN EQUITY", "U.S. EQUITY", "INTERNATIONAL EQUITY"] : [];
                        
                        // eslint-disable-next-line
                        order_override.map(sc=>{
                          if(sch[sc] !== undefined){
                            elements.push(<div key={cat+'-'+sc} className="portfolios-content-holdings-subcat-header"><Trans>{sc}</Trans></div>);
                            let sct=0;

                            if (cat !== 'CASH & EQUIVALENT' && sc !== 'CASH & EQUIVALENT'){
                              for (let [id, data] of Object.entries(sch[sc])){
                                if(data.value !== undefined){
                                  sct+=data.value;
                                }
                                elements = elements.concat([
                                  <div key={cat+'-'+sc+'-'+id+'-t'} className="portfolios-content-holdings-item name-item" title={data.name !== undefined? data.name : false}>{data.name !== undefined? data.name : ''}</div>,
                                  <div key={cat+'-'+sc+'-'+id+'-m'} className="portfolios-content-holdings-item value-item">{data.value !== undefined? (<span>{numeral(data.value).format('$ 0,0')}</span>) : ''}</div>,
                                  <div key={cat+'-'+sc+'-'+id+'-p'} className="portfolios-content-holdings-item pct-item">{data.value !== undefined && pftotal !== false && pftotal !== 0? (<span>{numeral((data.value / pftotal) * 100).format('0.0')} <span className='small-symbol'>%</span></span>)  : ''}</div>,
                                ]);
                              }
                            }else{
                              let orderList = ["CAD-CASH", "USD-CASH"]
                              let h_list = [];
                              for (let [id, data] of Object.entries(sch[sc])){
                                data['id'] = id;
                                h_list.push(data);
                              }

                              h_list.sort((a,b)=>{
                                  return a.symbol === b.symbol ? 0
                                      : a.symbol === null ? 1
                                      : b.symbol === null ? -1
                                      : orderList.indexOf(a.symbol) !== -1 &&  orderList.indexOf(b.symbol) !== -1 ? orderList.indexOf(a.symbol) > orderList.indexOf(b.symbol) ? 1 : -1
                                      : orderList.indexOf(a.symbol) === -1 &&  orderList.indexOf(b.symbol) === -1 ? a.symbol.localeCompare(b.symbol)
                                      : orderList.indexOf(a.symbol) === -1 ? 1 : -1;
                              });
                              // eslint-disable-next-line
                              h_list.map(data=>{
                                if(data.value !== undefined){
                                  sct+=data.value;
                                }
                                elements = elements.concat([
                                  <div key={cat+'-'+sc+'-'+data.id+'-t'} className="portfolios-content-holdings-item name-item" title={data.name !== undefined? data.name : false}>{data.name !== undefined? <Trans>{data.name}</Trans> : ''}</div>,
                                  <div key={cat+'-'+sc+'-'+data.id+'-m'} className="portfolios-content-holdings-item value-item">{data.value !== undefined? (<span>{numeral(data.value).format('$ 0,0')}</span>) : ''}</div>,
                                  <div key={cat+'-'+sc+'-'+data.id+'-p'} className="portfolios-content-holdings-item pct-item">{data.value !== undefined && pftotal !== false && pftotal !== 0? (<span>{numeral((data.value / pftotal) * 100).format('0.0')} <span className='small-symbol'>%</span></span>)  : ''}</div>,
                                ]);
                              })

                            }

                            elements = elements.concat([
                              <div key={cat+'-'+sc+'-total-t'} className="portfolios-content-holdings-total-item name-item"><Trans>Total</Trans></div>,
                              <div key={cat+'-'+sc+'-total-m'} className="portfolios-content-holdings-total-item value-item"><span>{numeral(sct).format('$ 0,0')}</span></div>,
                              <div key={cat+'-'+sc+'-total-p'} className="portfolios-content-holdings-total-item pct-item">{pftotal !== false && pftotal !== 0? (<span>{numeral((sct / pftotal) * 100).format('0.0')} <span className='small-symbol'>%</span></span>) : ''}</div>
                            ]);
                          }
                        })

                        for (let sc of scs ) {
                          if (order_override.indexOf(sc) === -1){
                            elements.push(<div key={cat+'-'+sc} className="portfolios-content-holdings-subcat-header"><Trans>{sc}</Trans></div>);
                            let sct=0;
                          
                            if (cat !== 'CASH & EQUIVALENT' && sc !== 'CASH & EQUIVALENT'){
                              for (let [id, data] of Object.entries(sch[sc])){
                                if(data.value !== undefined){
                                  sct+=data.value;
                                }
                                elements = elements.concat([
                                  <div key={cat+'-'+sc+'-'+id+'-t'} className="portfolios-content-holdings-item name-item" title={data.name !== undefined? data.name : false}>{data.name !== undefined? data.name : ''}</div>,
                                  <div key={cat+'-'+sc+'-'+id+'-m'} className="portfolios-content-holdings-item value-item">{data.value !== undefined? (<span>{numeral(data.value).format('$ 0,0')}</span>) : ''}</div>,
                                  <div key={cat+'-'+sc+'-'+id+'-p'} className="portfolios-content-holdings-item pct-item">{data.value !== undefined && pftotal !== false && pftotal !== 0? (<span>{numeral((data.value / pftotal) * 100).format('0.0')} <span className='small-symbol'>%</span></span>)  : ''}</div>,
                                ]);
                              }
                            }else{
                              let orderList = ["CAD-CASH", "USD-CASH"]
                              let h_list = [];
                              for (let [id, data] of Object.entries(sch[sc])){
                                data['id'] = id;
                                h_list.push(data);
                              }

                              h_list.sort((a,b)=>{
                                  return a.symbol === b.symbol ? 0
                                      : a.symbol === null ? 1
                                      : b.symbol === null ? -1
                                      : orderList.indexOf(a.symbol) !== -1 &&  orderList.indexOf(b.symbol) !== -1 ? orderList.indexOf(a.symbol) > orderList.indexOf(b.symbol) ? 1 : -1
                                      : orderList.indexOf(a.symbol) === -1 &&  orderList.indexOf(b.symbol) === -1 ? a.symbol.localeCompare(b.symbol)
                                      : orderList.indexOf(a.symbol) === -1 ? 1 : -1;
                              });
                              // eslint-disable-next-line
                              h_list.map(data=>{
                                if(data.value !== undefined){
                                  sct+=data.value;
                                }
                                elements = elements.concat([
                                  <div key={cat+'-'+sc+'-'+data.id+'-t'} className="portfolios-content-holdings-item name-item" title={data.name !== undefined? data.name : false}>{data.name !== undefined? <Trans>{data.name}</Trans> : ''}</div>,
                                  <div key={cat+'-'+sc+'-'+data.id+'-m'} className="portfolios-content-holdings-item value-item">{data.value !== undefined? (<span>{numeral(data.value).format('$ 0,0')}</span>) : ''}</div>,
                                  <div key={cat+'-'+sc+'-'+data.id+'-p'} className="portfolios-content-holdings-item pct-item">{data.value !== undefined && pftotal !== false && pftotal !== 0? (<span>{numeral((data.value / pftotal) * 100).format('0.0')} <span className='small-symbol'>%</span></span>)  : ''}</div>,
                                ]);
                              })

                            }

                            elements = elements.concat([
                              <div key={cat+'-'+sc+'-total-t'} className="portfolios-content-holdings-total-item name-item"><Trans>Total</Trans></div>,
                              <div key={cat+'-'+sc+'-total-m'} className="portfolios-content-holdings-total-item value-item"><span>{numeral(sct).format('$ 0,0')}</span></div>,
                              <div key={cat+'-'+sc+'-total-p'} className="portfolios-content-holdings-total-item pct-item">{pftotal !== false && pftotal !== 0? (<span>{numeral((sct / pftotal) * 100).format('0.0')} <span className='small-symbol'>%</span></span>) : ''}</div>
                            ]);
                          }
                        }

                        return elements;
                      }else{
                        return false;
                      }
                    })}
                  </div>) : (<ComponentLoadingPlaceholder text={(<><Trans>Loading</Trans> <Trans>Portfolio Holdings</Trans></>)} />)
                }
                </div>
              </div>
            </div>
        </div>
      )
    }
  }
