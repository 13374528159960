import React from 'react';
import {
    FormGroup,
    FormControl,
    HelpBlock
} from 'react-bootstrap';
import { BoxLayout } from '../layout/layout';
import { invokeApi } from '../../libs/authlib'; 
import { deviceDetect } from 'react-device-detect';

import LoaderButton from '../components/loaderbutton';
import { LangChangeSelect } from '../../containers/components/changelang';
import { ComponentLoadingPlaceholder } from '../../containers/components/loadingplaceholder';

import { Trans } from 'react-i18next';
import AppContext from '../../appcontext';

import './devicetokens.css';

class DeviceTokens extends React.Component {

    render(){
        return(
            <div className="device-token-container">
                <BoxLayout containerClassName="device-token-box">
                    <DeviceTokensContent />
                </BoxLayout>
            </div>
        )
    }
}

export default DeviceTokens;

class DeviceTokensContent extends React.Component {
    static contextType = AppContext;
    constructor(props) {
        super(props);
        
        this.state = {
          sqReady: false,
          isLoading: false,
          sqList: null,
          question: '',
          answer: '',
          loading_error: null
        };
    }

    async componentDidMount() {
        try {
          const results = await this.getSQList();
          this.setState({
            sqList: Object.values(results),
            question: Object.values(results)[0],
            sqReady: true
          });
        } catch (e) {
          console.error(e);
        }
    }

    getSQList() {
        return invokeApi({
                path: "/client/"+ this.context.user_account +"/sq",
                method: "GET"
        });  
    }

    handleChange= (event) => {
        this.setState({
          [event.target.id]: event.target.value,
          loading_error: null
        });
    }

    validateForm() {
        return this.state.question.length > 0 && this.state.answer.length > 0
    }

    handleSubmit= async (event) => {
        event.preventDefault();
    
        this.setState({ isLoading: true });
        try {
            let response = await invokeApi({
                path: "/client/" + this.context.user_account + "/devices/token/create",
                method: "post",
                body: { 
                    question: this.state.question, 
                    answer: this.state.answer,
                    data: deviceDetect()
                }
            });
            this.context.setDeviceToken(response);
            localStorage.setItem('user_selected', this.context.user_account);
            await this.context.updateUserStatus();
            localStorage.removeItem('user_selected');
            this.context.navigate('/');
    
        } catch (e) {
          console.error(e.message);
          this.setState({ isLoading: false, loading_error: e.message });
        }
    }

    render(){
        return  (
            <div className="device-token-content-container">
                { this.state.sqReady ? (
                    <div className="device-token-content">
                        <h1><Trans>To help secure your account you must authorize this device, please answer a question below.</Trans></h1>
                        <form className="dt-form" onSubmit={this.handleSubmit}>
                            <FormGroup controlId="question" bsSize="large">
                                <FormControl
                                componentClass="select"
                                value={this.state.question}
                                onChange={this.handleChange} >
                                {this.state.sqList.map(function(sq, index){
                                    return <option key={ index } value={this.context.i18n.t(sq)}>{this.context.i18n.t(sq)}</option>
                                }, this)}
                                </FormControl>
                            </FormGroup>
                            <FormGroup controlId="answer" bsSize="large" validationState={this.state.loading_error === 'invalid_security_response'? 'error': null}>
                                <FormControl
                                placeholder={this.context.i18n.t('Answer')}
                                value={this.state.answer}
                                onChange={this.handleChange} />
                                <HelpBlock>{ this.state.loading_error === 'invalid_security_response' ? <Trans>Incorrect answer, please try again</Trans> : false }</HelpBlock>
                            </FormGroup>
                            <LoaderButton
                                block
                                className="dt-submit-btn"
                                disabled={ ! this.validateForm() }
                                type="submit"
                                isLoading={this.state.isLoading}
                                text={this.context.i18n.t("Continue")}
                                loadingText={this.context.i18n.t("Authorizing…")} />
                        </form>
                    </div>
                ) : <ComponentLoadingPlaceholder /> }
                <LangChangeSelect lang={this.context.currentLanguage()} />
            </div>
        )
    }
}