import React, { Component } from 'react';
import {
  FormGroup,
  FormControl
} from 'react-bootstrap';
import { BoxLayout } from '../layout/layout';
import { invokeApi } from '../../libs/authlib'; 

import LoaderButton from '../components/loaderbutton';
import { LangChangeSelect } from '../../containers/components/changelang';

import { Trans } from 'react-i18next';
import AppContext from '../../appcontext';

import './sq.css';

class SQ extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    
    this.state = {
      sqReady: false,
      isLoading: false,
      sqList: null,
      question_one: '',
      question_two: '',
      answer_one: '',
      answer_two: ''
    };
  }

  async componentDidMount() {
    try {
      const results = await this.getSQList();
      this.setState({
        sqList: results
      });
    } catch (e) {
      console.error(e);
    }
    this.setState({ sqReady: true});
  }



  getSQList() {
    return invokeApi({
          path: "/client/app/securityquestions",
          method: "GET"
    });  
  }

  handleChange= (event) => {
    this.setState({
      [event.target.id]: event.target.value
    });
  }

  handlePhoneChange= (event) => {
    let s2 = (""+event.target.value).replace(/\D/g, '');
    this.setState({
      [event.target.id]: s2
    });
  }

  handleSubmit= async (event) => {
    event.preventDefault();

    this.setState({ isLoading: true });
    try {
      let response = await invokeApi({
        path: "/client/" + this.context.user_account + "/sq",
        method: "post",
        body: { 
          q1: this.state.question_one, 
          a1: this.state.answer_one,
          q2: this.state.question_two,
          a2: this.state.answer_two
        }
      });
      if(response === ""){ 
        await invokeApi({
          path: "/client/" + this.context.user_account + "/state",
          method: "POST",
          body: { sq: 1 }
        })
        await this.context.updateUserStatus();
        this.context.navigate('/');
      }
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  }

  validateForm() {
    return this.state.question_one.length > 0
      && this.state.question_two.length > 0 
      && this.state.answer_one.length > 0
      && this.state.answer_two.length > 0
      && this.state.question_one !== this.state.question_two
  }

  renderForm() {
    return (
      <div className="sq-content">
        <BoxLayout>
          <div className="sq-container">
            <h1><Trans>To help secure your account please enter a recovery phones number, choose your security questions, and enter your responses.</Trans></h1>
            <form className="sq-form" onSubmit={this.handleSubmit}>
              <FormGroup controlId="question_one" bsSize="large">
                <FormControl
                  componentClass="select"
                  value={this.state.question_one}
                  onChange={this.handleChange} >
                  <option value='' disabled>{this.context.i18n.t('First Security Question')}</option>
                  {this.state.sqList.map(function(sq, index){
                    return <option key={ index } value={this.context.i18n.t(sq)}>{this.context.i18n.t(sq)}</option>
                  }, this)}
                </FormControl>
              </FormGroup>
              <FormGroup controlId="answer_one" bsSize="large">
                <FormControl
                  placeholder={this.context.i18n.t('First Question Response')}
                  value={this.state.answer_one}
                  onChange={this.handleChange} />
              </FormGroup>
              <FormGroup controlId="question_two" bsSize="large">
                <FormControl
                  componentClass="select"
                  value={this.state.question_two}
                  onChange={this.handleChange} >
                  <option value='' disabled>{this.context.i18n.t('Second Security Question')}</option>
                  {this.state.sqList.map(function(sq, index){
                    return <option key={ index } value={sq}>{this.context.i18n.t(sq)}</option>
                  }, this)}
                </FormControl>
              </FormGroup>
              <FormGroup controlId="answer_two" bsSize="large">
                <FormControl
                  type="text"
                  placeholder={this.context.i18n.t('Second Question Response')}
                  value={this.state.answer_two}
                  onChange={this.handleChange} />
              </FormGroup>
              <LoaderButton
                block
                className="sq-submit-btn"
                disabled={ ! this.validateForm() }
                type="submit"
                isLoading={this.state.isLoading}
                text={this.context.i18n.t("Continue")}
                loadingText={this.context.i18n.t("Updating Account…")} />
            </form>

            <LangChangeSelect lang={this.context.currentLanguage()} />
          </div>
        </BoxLayout>
      </div>
    )
  }

  render() {
    return this.state.sqReady && (
      <div className="sq">
        { 
          this.renderForm()
        }
      </div>
    );
  }
}

export default SQ;