import config from "../config";
import jwt from 'jsonwebtoken';
import axios from 'axios';
import { deviceDetect } from 'react-device-detect';

export async function loginUser(username, password) {
  try{
    let res = await invokeApi({
        path: "/client/auth",
        method: "post",
        body: {
          'username': username,
          'password': password
        },
        unauth: true
    }); 

    if( setCurrentUser(res)) {
      let uuid = res['account_uuid'];

      let dt = getDeviceToken();

      if (dt !== null) {
        try {
          await invokeApi({
            path: "/client/" + uuid + "/devices/token",
            method: "post",
            queryParams: {t:dt},
            body: deviceDetect()
          }); 
        } catch (e) {}
      }

      return true;
    }else{
      return false;
    }
  }catch(e){
    throw e;
  }
  
}

export async function authUser() {
  let credentials = getCurrentUser();
  if (credentials !== null && Date.now()/1000 < credentials.expires) {
    return true;
  }
  return false;
}

export function getCurrentUser(){
  let cred = localStorage.getItem('fef.auth.credentials')
  if(cred !== null){
      cred = jwt.decode(localStorage.getItem('fef.auth.credentials'));
  }
  return cred;
}

export function getUserUuid(){
  return new Promise((resolve, reject) => {
    let credentials = getCurrentUser();
    resolve(credentials['uuid']);
  });
}

export function setCurrentUser(credentials){
  if (credentials !== null & Date.now()/1000 < credentials.expires) {
    localStorage.setItem('fef.auth.credentials', jwt.sign(credentials, config.jwt.sign_key));
    return true
  }else {
    return false
  }
}

export function removeAuthToken() {
  localStorage.removeItem('fef.auth.credentials');
  localStorage.removeItem("muuid");
}

export async function getUserState(){
  let credentials = getCurrentUser();

  var userState = {
    user_uuid: null,
    user_account: null
  }

  try {
    userState["user_uuid"] = credentials['uuid'];
    if (localStorage.getItem('user_selected') === null) {
      userState["user_account"] = credentials['account_uuid'];
    } else {
      userState["user_account"] = localStorage.getItem('user_selected');
    }
    userState["user_accounts"] = credentials['user_accounts'];
    if (Object.keys(credentials['user_accounts']).length === 1) {
      userState['account_selected'] = true;
      userState['header_balances']= credentials['user_accounts'][credentials['account_uuid']].pf_summary;
    }

    let devicet = getDeviceToken();
    var result = await invokeApi({
      path: "/client/" + userState.user_account + "/state",
      method: 'GET',
      queryParams: { t: devicet }
    });
    userState["user_state"] = result;
    result = await invokeApi({
      path: "/client/me",
      method: 'GET'
    });
    userState["user_info"] = result;
    result = await invokeApi({
      path: "/client/"+ userState.user_account + "/advisor",
      method: 'GET'
    });
    userState["advisor_info"] = result;
    result = await invokeApi({
      path: "/client/" + userState.user_account + "/document/counts",
      method: 'GET'
    });
    userState["document_counts"] = result;
    localStorage.setItem("muuid", userState["user_info"]["muuid"])
  } catch (e) {
    if(e.code === "UserNotFoundException"){
      removeAuthToken();
    }else{
      console.error(e);
    }
  }
  return userState;
}

function getDeviceToken(){
  return localStorage.getItem('fef.auth.device');
}

export function setDeviceToken(token){
  localStorage.setItem('fef.auth.device', token);
}

export const axiosCancel = () => axios.CancelToken.source();

export async function invokeApi({
  path,
  method = "get",
  headers = {},
  queryParams = {},
  body,
  unauth = false,
  cancelToken = axios.CancelToken.source().token,
  onSuccess=false,
  onError=false
}) {
  if (unauth !== true && !await authUser()) {
    window.location.reload();
  }

  var params = {}
  axios.defaults.headers.get['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  if(unauth === true){
    params = {
      baseURL: config.apiGateway.URL,
      method: method.toLowerCase(),
      url: path,
      params: queryParams,
      data : body ? JSON.stringify(body) : body,
      headers: headers,
      cancelToken: cancelToken
    }
  }else{
    let credentials = getCurrentUser();
    params = {
      baseURL: config.apiGateway.URL,
      method: method.toLowerCase(),
      url: path,
      params: queryParams,
      data : body,
      headers: { 'x-fef-auth': credentials.access_token, ...headers},
      cancelToken: cancelToken
    }
  }

  try{
    let result = await axios(params).catch();
    if(onSuccess !== false) { onSuccess(result.data) }
    return result.data
    
  } catch (error) {
    if (axios.isCancel(error)) {
      console.debug('Request Canceled')
    } else {
      
      if (error.response) {
        // The request was made and the server responded with an error
        if(onError !== false){ onError(error.response.data.message) }
        let e = new Error(error.response.data.message);
        e.data = error.response.data;
        throw e;
      } else if (error.request) {
        // The request was made but no response was received
        if(onError !== false){ onError(error.request) }
        throw new Error(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        if(onError !== false){ onError(error.message) }
        throw new Error(error.message);
      }
      
      
    }
  }
}
