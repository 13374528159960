import React, { Component } from 'react';
import AppContext from '../../appcontext';
import AlertDismissable from '../components/alertdismissable';
import Header from '../header/header';
import PageHeader from '../header/pageheader';

import './layout.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Layout extends Component{
  render() {
    if(this.props.type === 'box'){
      return <BoxLayout {...this.props} />;
    }else if(this.props.type === 'fill'){
      return <FillLayout {...this.props} />;
    }else if(this.props.type === 'fakemodal'){
      return <FakeModalLayout {...this.props} />;
    }else{
      return (
        <div className="no-layout">
          {this.props.children}
        </div>
      );
    }

  }

}

export class BoxLayout extends Component {
  static contextType = AppContext;

  componentDidMount(){
    this.context.changeBackground(this.context.theme.box_background_color);
    this.context.toggleBGI(false);
  }

  componentWillUnmount(){
    this.context.toggleBGI(false);
  }

  render() {
    return ( 
      <div className="layout-container">
        <div className="layout-content box">
          <div className="logo-container box">
            <img className="logo-icon-image" alt='' src={this.context.currentLogo()} />
          </div>
          <div className={"content-container box " + (this.props.containerClassName !== undefined ? this.props.containerClassName  : "") }>
              { this.props.errmsg === undefined ? null : (
                  <div className="error-container">
                    <AlertDismissable bsStyle="danger" className="error-msg">{this.props.errmsg}</AlertDismissable>
                  </div>
                )
              }
            {this.props.children}
          </div>
          <div className="sub-container box">
            {this.props.sub}
          </div>
        </div> 
      </div>
    );
  }
}

export class FloatLayout extends Component {
  static contextType = AppContext;

  componentDidMount(){
    this.context.changeBackground(this.context.theme.default_background_color);
    this.context.toggleBGI(false);
  }

  render() {
    return ( 
      <div className="layout-container">
        <div className="layout-content float">
          <div className="logo-container float">
            <img className="logo-icon-image" alt='' src={this.context.currentLogo('dark')} />
          </div>
          <div className={"content-container float " + (this.props.containerClassName !== undefined ? this.props.containerClassName  : "") }>
              { this.props.errmsg === undefined ? null : (
                  <div className="error-container">
                    <AlertDismissable bsStyle="danger" className="error-msg">{this.props.errmsg}</AlertDismissable>
                  </div>
                )
              }
            {this.props.children}
          </div>
          <div className="sub-container float">
            {this.props.sub}
          </div>
        </div> 
      </div>
    );
  }
}


export class FillLayout extends Component {
  static contextType = AppContext;

  componentDidMount(){
    this.context.changeBackground(this.context.theme.default_background_color);
    this.context.toggleBGI(false);
  }

  render() {
    return ( 
      <div className="container-fluid layout-container" >
        <div className="row layout-header fill">
          <Header fluid {...this.props.headerProps} headerClassName={this.props.headerClassName}/>
          <PageHeader />
          {this.context.ieCheck() ? 
              <AlertDismissable bsStyle="warning" className="error-msg"><strong>{this.context.i18n.t("Warning!")}</strong> {this.context.i18n.t("Your browser version is not supported, please update to the latest version.")}</AlertDismissable>
          : false}
        </div>
        <div className="row layout-content fill">
          <div className={"content-container fill "+ (this.props.containerClassName !== undefined ? this.props.containerClassName  : "")}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }

}

export class FakeModalLayout extends Component {
  static contextType = AppContext;

  componentDidMount(){
    this.context.changeBackground(this.context.theme.default_background_color);
    this.context.toggleBGI(false);
  }

  render() {
    return ( 
      <div className="layout-container">
        <div className="layout-content fakemodal">
          <div className={"content-container fakemodal " + (this.props.containerClassName !== undefined ? this.props.containerClassName  : "")}>
              <div className="content-header fakemodal">{this.props.title === undefined ? "" : this.props.title}</div>
              { this.props.errmsg === undefined ? null : (
                  <div className="error-container">
                    <AlertDismissable bsStyle="danger" className="error-msg">{this.props.errmsg}</AlertDismissable>
                  </div>
                )
              }
            {this.props.children}
          </div>
        </div> 
      </div>
    );
  }
}


export class SlidingPaneLayout extends React.Component {

  render(){
      return [
        <div key='cover' className={"layout-cover slidingpane " + ((this.props.active !== undefined? this.props.active: false) ? " active": '')}>&nbsp;</div>,
          <div key='pane' className={"layout-container sliding-pane-container "+ ((this.props.half !== undefined? this.props.half: false) ? " half": '') + ((this.props.right !== undefined? this.props.right: false) ? " right": '') + ((this.props.active !== undefined? this.props.active: false) ? " active": '')}>
            <div className="layout-content slidingpane">
              <div className={"content-container slidingpane "+ (this.props.containerClassName !== undefined ? this.props.containerClassName  : "")}>
                {this.props.close !== undefined ? <span className="slidingpane-close" onClick={this.props.close !== undefined ? this.props.close : false}><FontAwesomeIcon icon="times" /></span>  : false }
                {this.props.children}
              </div>
            </div>
          </div>
      ]
  }
}
