import React from 'react';
import { Trans } from 'react-i18next';

import AppContext from '../../appcontext';

export class ComponentLoadingPlaceholder extends React.PureComponent {
    static contextType = AppContext;

    constructor(props){
        super(props)
        this.state = {
          ticks: 1
        }
      }

    componentDidMount(){
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount(){
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            ticks: this.state.ticks < 3 ? this.state.ticks + 1 : 1
        });
    }

    render(){
        return(
            <div className="component-loading-placeholder">
                <div className="component-loading-text">{this.props.text !==undefined? typeof this.props.text === "string"? (<Trans>{this.props.text}</Trans>) : this.props.text : (<Trans>Loading</Trans>)} {this.state.ticks < 3 ? this.state.ticks < 2 ? <span>.&nbsp;&nbsp;</span> : <span>..&nbsp;</span> : <span>...</span>}</div>
            </div>
        )
    }

}

export class TableLoadingPlaceholder extends React.PureComponent {
    constructor(props){
        super(props)
        this.state = {
          ticks: 1
        }
      }

    componentDidMount(){
        this.timerID = setInterval(
            () => this.tick(),
            1000
        );
    }

    componentWillUnmount(){
        clearInterval(this.timerID);
    }

    tick() {
        this.setState({
            ticks: this.state.ticks < 3 ? this.state.ticks + 1 : 1
        });
    }

    render(){
        return(
            <div className="table-loading-placeholder">
                <div className="table-loading-text"><Trans>Loading</Trans>{this.state.ticks < 3 ? this.state.ticks < 2 ? <span>.&nbsp;&nbsp;</span> : <span>..&nbsp;</span> : <span>...</span>}</div>
            </div>
        )
    }

}