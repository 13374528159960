import React from "react";
import moment from "moment";
import LoaderButton from "../../containers/components/loaderbutton";
import Dropzone from "react-dropzone";
import axios from "axios";

import {
  Button,
  DropdownButton,
  Modal,
  FormGroup,
  FormControl,
  ControlLabel,
  ProgressBar,
  OverlayTrigger,
  Popover,
  Label,
  HelpBlock,
  Checkbox,
} from "react-bootstrap";

import { FillLayout } from "../layout/layout.js";
import { invokeApi } from "../../libs/authlib";

import { Trans } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import AppContext from "../../appcontext";
import config from "../../config";

import "./documents.css";
import mime from "mime";

/* Documents with Container */
class Documents extends React.Component {
  static contextType = AppContext;

  render() {
    return (
      <div className="documents-container">
        <FillLayout containerClassName="documents-box">
          <DocumentsContainer />
        </FillLayout>
      </div>
    );
  }
}

export default Documents;

class DocumentsContainer extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      documents_loading: true,
      loading_error: false,
      document_types: [
        { name: "All Documents", type: "all" },
        { name: "New Documents", type: "new_documents" },
        { name: "Monthly Account Statements", type: "nbin_documents" },
        { name: "Performance Reports", type: "performance_report" },
        { name: "Financial Planning", type: "financial_planning" },
        { name: "Tax Documents", type: "tax_documents" },
        { name: "Wills & Estates", type: "wills_estates" },
        { name: "Insurance", type: "insurance_documents" },
        { name: "Account Documents", type: "administative_documents" },
        { name: "Your Added Documents", type: "user_uploaded" },
      ],
      documents: [],
      selected_type: "new_documents",
      type_info: { name: "New Documents", type: "new_documents" },
      show_documents_modal: false,
      dropdownVisible: false,
    };
  }

  componentDidMount() {
    this.signal = this.context.axiosCancel();

    if (this.context.user_account !== undefined) {
      this.getDocuments();
    } else {
      this.setState({
        loading_error: this.context.i18n.t("No account provided."),
      });
    }
  }

  async getDocuments() {
    this.setState({ documents_loading: true });
    try {
      // TODO: Change to dynamic Data
      await invokeApi({
        path: "/client/" + this.context.user_account + "/document/list",
        method: "GET",
        onSuccess: (r) =>
          this.setState({ documents: r, documents_loading: false }),
        onError: (e) =>
          this.setState({ documents_loading: false, loading_error: e }),
      });
    } catch (e) {
      console.error(e);
    }
  }

  componentWillUnmount() {
    this.signal.cancel();
  }

  updateDocumentType = (info) => {
    this.setState({ selected_type: info.type, type_info: info });
  };

  showUploadDocumentModal = () => {
    this.setState({ show_documents_modal: true });
  };

  hideUploadDocumentModal = () => {
    this.getDocuments();
    this.setState({ show_documents_modal: false });
  };

  render() {
    return (
      <div className="documents-grid">
        <div className="documents-sidebar">
          <div className="documents-sidebar-type-container largeScreen">
            <div className="document-type-item-container">
              {this.state.document_types.map((t) => {
                return (
                  <div
                    key={t.type + "_item"}
                    className={
                      "document-type-item" +
                      (this.state.selected_type === t.type ? " active" : "")
                    }
                  >
                    <span onClick={() => this.updateDocumentType(t)}>
                      <Trans>{t.name}</Trans>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="documents-sidebar-type-container smallScreen">
            <DropdownButton
              id="dropdown-basic-button"
              title={this.context.i18n.t(this.state.type_info.name)}
            >
              {this.state.document_types.map((t) => {
                return (
                  <div
                    key={t.type + "_item"}
                    onClick={() => this.updateDocumentType(t)}
                    className={
                      "document-type-item" +
                      (this.state.selected_type === t.type ? " active" : "")
                    }
                  >
                    <span onClick={() => this.updateDocumentType(t)}>
                      <Trans>{t.name}</Trans>
                    </span>
                  </div>
                );
              })}
            </DropdownButton>
            {/* <Dropdown id="documentMobileOption" variant="primary" title={this.context.i18n.t(this.state.type_info.name)}>
              <Dropdown.Toggle id="dropdown-custom-components" as={CustomToggle}>
                <span>{this.context.i18n.t(this.state.type_info.name)}</span>
              </Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                {this.state.document_types.map((t) => {
                  return (
                    <div
                      key={t.type + "_item"}
                      className={
                        "document-type-item" +
                        (this.state.selected_type === t.type ? " active" : "")
                      }
                    >
                      <span onClick={() => this.updateDocumentType(t)}>
                        <Trans>{t.name}</Trans>
                      </span>
                    </div>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown> */}
          </div>
          <div className="documents-sidebar-upload-container">
            <button
              className="btn btn-inverse-grey documents-sidebar-upload-btn"
              onClick={() => this.showUploadDocumentModal()}
            >
              <Trans>Add Document</Trans>
            </button>
          </div>
        </div>
        <DocumentsContent
          isLoading={this.state.documents_loading}
          documents={this.state.documents}
          typeInfo={this.state.type_info}
          updateDocuments={() => {
            this.getDocuments();
            this.context.updateUserStatus();
          }}
        />
        <UploadDocumentModal
          show={this.state.show_documents_modal}
          handleUploadClose={() => this.hideUploadDocumentModal()}
          updateDocumentList={() => this.getDocuments()}
        />
      </div>
    );
  }
}

class DocumentsContent extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      to_delete: null,
      is_deleting: false,
      isChecked: [],
      downloadDisabled: true,
      selectAllChecked: false,
    };
  }

  getSortedDocuments = () => {
    let sorted_documents = {};
    let bool_new;

    for (let i = 0; i < this.props.documents.length; i++) {
      if (this.props.typeInfo.type === "new_documents") {
        bool_new =
          this.props.documents[i]["attrs"]["status"] !== undefined &&
          this.props.documents[i]["attrs"]["status"] === "clean" &&
          this.props.documents[i]["attrs"]["read"] !== undefined &&
          !this.props.documents[i]["attrs"]["read"];
      }
      if (
        this.props.documents[i]["attrs"].type !== undefined &&
        (this.props.documents[i]["attrs"].type === this.props.typeInfo.type ||
          this.props.typeInfo.type === "all" ||
          (this.props.typeInfo.type === "new_documents" && bool_new))
      ) {
        if (this.props.documents[i]["attrs"].uploaded_date !== undefined) {
          let doc_date = moment
            .unix(this.props.documents[i]["attrs"].uploaded_date)
            .format("YYYY-MM");

          if (sorted_documents[doc_date] === undefined) {
            sorted_documents[doc_date] = [];
          }

          sorted_documents[doc_date].push(this.props.documents[i]);
        }
      }

      let keys = Object.keys(sorted_documents);
      for (let i = 0; i < keys.length; i++) {
        sorted_documents[keys[i]].sort((a, b) => {
          let dateA = moment.unix(a["attrs"].uploaded_date).toDate();
          let dateB = moment.unix(b["attrs"].uploaded_date).toDate();
          return dateB - dateA;
        });
      }
    }

    return sorted_documents;
  };

  async getDownloadUrl(did, filename, bool_new_tab) {
    return invokeApi({
      path:
        "/client/" +
        this.context.user_account +
        "/document/" +
        did +
        "/download",
      method: "GET",
      onSuccess: (r) => this.downloadDocument(r, filename, bool_new_tab),
      onError: (e) => this.handleError(e),
    });
  }

  showErrorModal = (e, message) => {
    this.setState({ showError: true, error: message });
  };

  handleError = (e) => {
    this.setState({ is_deleting: false });
    console.error(e.data !== undefined ? e.data.message : e);
  };

  downloadDocument = (url, filename, bool_new_tab) => {
    axios({
      url: url,
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      //added mime.getType so object can opened by safari
      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: mime.getType(filename) })
      );
      const link = document.createElement("a");
      link.href = url;
      if (bool_new_tab) {
        link.target = "_blank";
      } else {
        link.download = filename;
      }
      link.click();
      // Added for safari issues, revoke needed to be delayed so it doesn't cause errors
      setTimeout(function () {
        window.URL.revokeObjectURL(url);
      }, 10000);
      this.props.updateDocuments();
    });
  };

  async sendDeleteDocument() {
    if (!this.state.is_deleting && this.state.to_delete !== null) {
      this.setState({ is_deleting: true });
      return invokeApi({
        path:
          "/client/" +
          this.context.user_account +
          "/document/" +
          this.state.to_delete +
          "/delete",
        method: "GET",
        onSuccess: (r) => this.handleDeletedDocument(),
        onError: (e) => this.handleError(e),
      });
    }
  }

  handleDeletedDocument() {
    document.getElementById("documents-content-container").click();
    this.props.updateDocuments();
    this.setState({ is_deleting: false });
  }

  handleCheckbox(e) {
    const value = e.target.value;

    // Changing value of nested attribute within the state
    var isChecked = [...this.state.isChecked];
    const index = isChecked.indexOf(`${value}`);
    if (index > -1) {
      isChecked.splice(index, 1);
    } else {
      isChecked.push(`${value}`);
    }

    this.setState({ isChecked });
  }

  handleSelectAll(e) {
    const documents = this.getSortedDocuments();
    var isChecked = [];
    var selectAllChecked = this.state.selectAllChecked;

    if (!selectAllChecked) {
      Object.keys(documents)
        .sort((a, b) => {
          let dateA = moment(a, "YYYY-DD").toDate();
          let dateB = moment(b, "YYYY-DD").toDate();
          return dateB - dateA;
        })
        .map((ac) => {
          for (let i = 0; i < documents[ac].length; i++) {
            isChecked.push(`${documents[ac][i]["uuid"]}`);
          }
        });
    }

    selectAllChecked = !selectAllChecked;

    this.setState({
      isChecked,
      selectAllChecked,
    });
  }

  getAccountNumberSpan(doc_attrs) {
    // If document is an nbin document
    if (doc_attrs["type"] === "nbin_documents") {
      const arr = /PRTFSTM_([\w~]+)_\d{8}\..{3}/g.exec(doc_attrs["filename"]);
      // If document is formatted as: PRTFSTM_{acc#}~{acc#}~{acc#}_{date}.{extension} (any number of '~' separated account numbers)
      if (arr) {
        // Create string of comma separated account numbers
        const acc_nums = arr[1].split("~").join(", ");
        return (
          <span className="document-list-item-field-subtitle">
            <Trans>{acc_nums}</Trans>
          </span>
        );
      }
    }
    return null;
  }

  handleZipDownload(e) {
    // Get all document names that have been selected and send them to be downloaded
    const docs = [...this.state.isChecked];

    // Send docs to an API request
    if (docs.length) {
      return invokeApi({
        path: "/client/" + this.context.user_account + "/document/downloadzip",
        method: "post",
        body: {
          documents: docs,
        },
        onSuccess: (r) => this.downloadDocument(r, "documents.zip", false),
        onError: (e) =>
          this.showErrorModal(
            e,
            "Problem retrieving one or more selected documents"
          ),
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isChecked !== prevState.isChecked) {
      if (this.state.isChecked.length > 0) {
        this.setState({ downloadDisabled: false });
      } else {
        this.setState({ downloadDisabled: true });
      }
    }
  }

  render() {
    const status_list = {
      clean: "Available",
      infected: "Quarantined",
      uploaded: "Pending",
      encrypted: "Encrypted",
    };

    const documents = this.getSortedDocuments();

    const popoverDeleteDocument = (
      <Popover
        id="popover-delete-document"
        title={
          <span>
            <Trans>Delete Document</Trans>?
          </span>
        }
      >
        <p>
          <Trans>The document will no longer be accessible.</Trans>
        </p>
        <p>
          <Trans>
            Are you sure you wish to <b>permanently</b> remove this file?
          </Trans>
        </p>
        <div className="popover-delete-document-btn-container">
          <LoaderButton
            disabled={this.state.is_deleting || this.props.isLoading}
            className="btn-red popover-delete-document-btn"
            type="button"
            onClick={() => this.sendDeleteDocument()}
            isLoading={this.state.is_deleting || this.props.isLoading}
            text={<Trans>Delete Document</Trans>}
            loadingText={<Trans>Delete Document</Trans>}
          />
        </div>
      </Popover>
    );

    return (
      <div
        id="documents-content-container"
        className="documents-content-container"
      >
        <div className="documents-content-header">
          <Trans>{this.props.typeInfo.name}</Trans>
          <button
            className="btn btn-inverse document-modal-btn"
            onClick={(e) => this.handleZipDownload(e)}
            disabled={this.state.downloadDisabled}
          >
            <Trans>Download Selected</Trans>
          </button>
        </div>
        <div className="documents-content-document-list">
          <div className="documents-content-document-list-header">
            <div className="document-list-header-item">
              <Checkbox
                name="Select All"
                onChange={(e) => this.handleSelectAll(e)}
              />
            </div>
            <div className="document-list-header-item">
              <Trans>Date</Trans>
            </div>
            <div className="document-list-header-item">
              <Trans>Name</Trans>
            </div>
            <div className="document-list-header-item">
              <Trans>Status</Trans>
            </div>
          </div>
          <div className="documents-content-document-item-container">
            {Object.keys(documents)
              .sort((a, b) => {
                let dateA = moment(a, "YYYY-DD").toDate();
                let dateB = moment(b, "YYYY-DD").toDate();
                return dateB - dateA;
              })
              .map((ac) => {
                let document_items = [];

                for (let i = 0; i < documents[ac].length; i++) {
                  let bool_new =
                    documents[ac][i]["attrs"]["status"] !== undefined &&
                    documents[ac][i]["attrs"]["status"] === "clean" &&
                    documents[ac][i]["attrs"]["read"] !== undefined &&
                    !documents[ac][i]["attrs"]["read"];
                  document_items.push(
                    <div key={ac + "-" + i} className="document-list-item">
                      <div className={"document-list-item-field"}>
                        <Checkbox
                          name="Select"
                          onChange={(e) => this.handleCheckbox(e)}
                          value={documents[ac][i]["uuid"]}
                          checked={
                            this.state.isChecked &&
                            this.state.isChecked.includes(
                              `${documents[ac][i]["uuid"]}`
                            )
                          }
                        />
                      </div>
                      <div
                        className={
                          "document-list-item-field uploaded_date-field"
                        }
                      >
                        <span className="document-list-item-field-title">
                          {documents[ac][i]["attrs"]["uploaded_date"] !==
                          undefined
                            ? moment
                                .unix(
                                  documents[ac][i]["attrs"]["uploaded_date"]
                                )
                                .format("MMMM YYYY")
                            : ""}
                        </span>
                      </div>
                      <div
                        className={
                          "document-list-item-field display_name-field"
                        }
                      >
                        {documents[ac][i]["attrs"]["display_name"] !==
                        undefined ? (
                          <span
                            className="document-list-item-field-title document-list-item-field"
                            title={this.context.i18n.t("View Document")}
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                              this.getDownloadUrl(
                                documents[ac][i]["uuid"],
                                documents[ac][i]["attrs"]["filename"],
                                true
                              )
                            }
                          >
                            {documents[ac][i]["attrs"]["display_name"]}
                          </span>
                        ) : (
                          <span className="document-list-item-field-title">
                            <Trans>Unknown</Trans>{" "}
                          </span>
                        )}
                        <br />
                        <span className="document-list-item-field-subtitle">
                          {documents[ac][i]["attrs"]["filename"] !==
                          undefined ? (
                            documents[ac][i]["attrs"]["filename"]
                          ) : (
                            <Trans>Unknown</Trans>
                          )}
                        </span>
                        <br />
                        {this.getAccountNumberSpan(documents[ac][i]["attrs"])}
                      </div>
                      <div className={"document-list-item-field status-field"}>
                        <span className="document-list-item-field-title">
                          {documents[ac][i]["attrs"]["status"] !== undefined &&
                          status_list[documents[ac][i]["attrs"]["status"]] !==
                            undefined ? (
                            <Trans>
                              {status_list[documents[ac][i]["attrs"]["status"]]}
                            </Trans>
                          ) : (
                            ""
                          )}
                        </span>
                        <span className="document-list-item-field-subtitle">
                          {bool_new ? (
                            <Label bsStyle="primary">
                              <Trans>New</Trans>
                            </Label>
                          ) : (
                            false
                          )}
                        </span>
                      </div>
                      {documents[ac][i]["attrs"]["status"] !== undefined &&
                      documents[ac][i]["attrs"]["status"] === "clean" ? (
                        <div className="document-list-item-field download_field">
                          <span
                            title={this.context.i18n.t("Download Document")}
                          >
                            <FontAwesomeIcon
                              icon={["far", "file-download"]}
                              onClick={() =>
                                this.getDownloadUrl(
                                  documents[ac][i]["uuid"],
                                  documents[ac][i]["attrs"]["filename"],
                                  false
                                )
                              }
                            />
                          </span>
                        </div>
                      ) : (
                        false
                      )}
                      {documents[ac][i]["can_delete"] !== undefined &&
                      documents[ac][i]["can_delete"] ? (
                        <div className="document-list-item-field delete_field">
                          <OverlayTrigger
                            trigger="click"
                            placement="left"
                            rootClose
                            overlay={popoverDeleteDocument}
                            onEnter={() =>
                              this.setState({
                                to_delete: documents[ac][i]["uuid"],
                              })
                            }
                            onExit={() => this.setState({ to_delete: null })}
                          >
                            <span
                              title={this.context.i18n.t("Delete Document")}
                            >
                              <FontAwesomeIcon icon={["fal", "trash-alt"]} />
                            </span>
                          </OverlayTrigger>
                        </div>
                      ) : (
                        false
                      )}
                    </div>
                  );
                }
                return (
                  <div
                    key={ac + "-month"}
                    className="documents-content-document-month-container"
                  >
                    {document_items}
                  </div>
                );
              })}
            {Object.keys(this.getSortedDocuments()).length === 0 ? (
              <div className="documents-content-document-no-data">
                <Trans>No Documents</Trans>
              </div>
            ) : (
              false
            )}
          </div>
        </div>
        <Modal show={this.state.showError}>
          <Modal.Header>
            <Modal.Title>
              <Trans>Error</Trans>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Trans>{this.state.error}</Trans>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={function (e) {
                this.setState({ showError: false });
              }.bind(this)}
            >
              <Trans>Close</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

class UploadDocumentModal extends React.Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);

    this.state = {
      flow_state: "prompt",
      show_modal: this.props.show,
      file: undefined,
      document_name: "",
      document_content: "",
      upload_percent: 0,
      status: undefined,
      status_txt: "",
    };
  }

  componentDidUpdate() {
    if (this.state.show_modal !== this.props.show) {
      this.setState({
        flow_state: "prompt",
        show_modal: this.props.show,
        file: undefined,
        document_name: "",
        document_content: "",
        upload_percent: 0,
        status: undefined,
        status_txt: "",
      });
    }
  }

  sanitizeName = name => name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  handleChange = (event) => {
    this.setState({
      [event.target.id]: event.target.value,
    });
  };

  async handleAddDocument() {
    this.setState({
      flow_state: "prompt",
      show_modal: this.props.show,
      file: undefined,
      document_name: "",
      document_content: "",
      upload_percent: 0,
      status: undefined,
      status_txt: "",
    });
  }

  async handleSubmitDocument() {
    this.setState({
      flow_state: "uploading",
      upload_percent: 0,
      status: undefined,
      status_txt: "",
    });
    try {
      let response = await this.prepDocumentUpload(this.state.file);
      let options = {
        headers: response.headers,
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader("content-length") ||
              progressEvent.target.getResponseHeader(
                "x-decompressed-content-length"
              );
          if (totalLength !== null) {
            this.setState({
              upload_percent: Math.round(
                (progressEvent.loaded * 100) / totalLength
              ),
            });
          }
        },
      };
      await axios.put(response.url, this.state.file, options);
      this.setState({ flow_state: "scanning" });

      let resp = await this.checkDocumentStatus(response.doc_uuid);
      if (resp === "clean") {
        this.setState({
          status: resp,
          status_txt: this.context.i18n.t("Upload successful"),
        });
      } else if (resp === "infected") {
        this.setState({
          status: resp,
          status_txt: this.context.i18n.t(
            "Document quarantined, virus detected"
          ),
        });
      } else if (resp === "encrypted") {
        this.setState({
          status: resp,
          status_txt: this.context.i18n.t(
            "Document quarantined, encypted document detected"
          ),
        });
      } else if (resp === "failed") {
        this.setState({
          status: resp,
          status_txt: this.context.i18n.t(
            "Document quarantined, virus scanner failed. Please try again."
          ),
        });
      } else {
        this.setState({
          status: resp,
          status_txt: this.context.i18n.t(
            "Document is still being scanned, check back later for the status of your upload"
          ),
        });
      }
    } catch (e) {
      console.error(e);
      this.setState({
        status: "failed",
        status_txt: this.context.i18n.t("Upload failed.  Please try again"),
      });
    }

    this.setState({ flow_state: "complete" });
  }

  prepDocumentUpload(file) {
    return invokeApi({
      path: "/client/" + this.context.user_account + "/document/uploadprep",
      method: "post",
      body: {
        file_name: this.sanitizeName(file.name),
        file_type: file.type,
        doc_name: this.state.document_name,
      },
    });
  }

  async checkDocumentStatus(doc_uuid) {
    let i = 0;
    let resp = undefined;
    resp = await this.getDocumentStatus(doc_uuid);
    while (
      i <= config.docUpload.statusDuration &&
      resp.attrs.status === "uploaded"
    ) {
      await this.sleep(config.docUpload.statusInterval * 1000);
      resp = await this.getDocumentStatus(doc_uuid);
      i = i + config.docUpload.statusInterval;
    }
    return resp.attrs.status;
  }

  getDocumentStatus(doc_uuid) {
    return invokeApi({
      path: "/client/" + this.context.user_account + "/document/" + doc_uuid,
      method: "get",
    });
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  popoverAcceptedFiles = (
    <Popover id="accepted-file-popover" title={<Trans>Accepted Files</Trans>}>
      <ul>
        <li>
          <Trans>Less than 1GB in size</Trans>
        </li>
        <li>
          <Trans>Microsoft Office Documents</Trans>
        </li>
        <li>
          <Trans>PDFs</Trans>
        </li>
        <li>
          <Trans>Images</Trans>
        </li>
        <li>
          <Trans>Zip archives</Trans>
        </li>
      </ul>
      <span>
        <Trans>Files must not be password protected or encrypted</Trans>
      </span>
    </Popover>
  );

  render() {
    let dropzoneRef;
    return (
      <Modal
        show={this.state.show_modal}
        animation={false}
        onHide={this.props.handleUploadClose}
        container={this}
        aria-labelledby="modal-title"
        className="document-upload-modal"
        backdrop="static"
      >
        <Modal.Header>
          <Modal.Title id="modal-title">
            <Trans>Upload Document</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="document-upload-modal-content">
            <div className="document-upload-input">
              <FormGroup controlId="document_name">
                <ControlLabel className="sv-input-label">
                  <Trans>Document Name</Trans>
                </ControlLabel>
                <div className="sv-input">
                  <FormControl
                    type="text"
                    value={this.state.document_name}
                    onChange={this.handleChange}
                    disabled={this.state.flow_state === "prompt" ? false : true}
                  />
                  <HelpBlock>
                    <Trans>Required</Trans>
                  </HelpBlock>
                </div>
              </FormGroup>
              {this.state.flow_state === "prompt" ? (
                <div>
                  <div className="document-upload-dropzone-container largeScreen">
                    <Dropzone
                      ref={(node) => {
                        dropzoneRef = node;
                      }}
                      multiple={false}
                      noClick={true}
                      noKeyboard={true}
                      maxSize={1024000000}
                      accept={
                        ".doc,.docx,.xls,.xlsx,.png,.jpeg,.jpg,.gif,.pdf,.zip,.tar,.gz,.7z"
                      }
                      onDrop={(acceptedFiles) => {
                        let file = acceptedFiles[0];

                        this.setState({ file: file });
                      }}
                      className="document-upload-dropzone"
                      activeClassName="active"
                      acceptClassName="accept"
                      rejectClassName="reject"
                      disabledClassName="disabled"
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                      }) => (
                        <section>
                          <div
                            {...getRootProps()}
                            className={
                              "dropzone-prop-container" +
                              (isDragActive
                                ? " active"
                                : isDragAccept
                                ? " accepted"
                                : isDragReject
                                ? " rejected"
                                : this.state.file === undefined
                                ? " selected"
                                : "")
                            }
                          >
                            <input {...getInputProps()} />
                            <div className="dropzone-prop-container-image">
                              <FontAwesomeIcon icon={["far", "copy"]} />
                            </div>
                            {this.state.file === undefined ? (
                              <p>
                                <Trans>Drop a file here to upload</Trans>
                              </p>
                            ) : (
                              <p>{this.state.file.path}</p>
                            )}
                            <div className="dropzone-prop-container-accepted">
                              <OverlayTrigger
                                trigger="click"
                                placement="top"
                                rootClose
                                overlay={this.popoverAcceptedFiles}
                              >
                                <span>
                                  <FontAwesomeIcon icon={["fal", "question"]} />{" "}
                                  <Trans>See Accepted Files</Trans>
                                </span>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  <div
                    className="document-upload-dropzone-container smallScreen"
                    onClick={() => {
                      dropzoneRef.open();
                    }}
                  >
                    <Dropzone
                      ref={(node) => {
                        dropzoneRef = node;
                      }}
                      multiple={false}
                      noClick={true}
                      noKeyboard={true}
                      maxSize={1024000000}
                      accept={
                        ".doc,.docx,.xls,.xlsx,.png,.jpeg,.jpg,.gif,.pdf,.zip,.tar,.gz,.7z"
                      }
                      onDrop={(acceptedFiles) => {
                        let file = acceptedFiles[0];

                        this.setState({ file: file });
                      }}
                      className="document-upload-dropzone"
                      activeClassName="active"
                      acceptClassName="accept"
                      rejectClassName="reject"
                      disabledClassName="disabled"
                    >
                      {({
                        getRootProps,
                        getInputProps,
                        isDragActive,
                        isDragAccept,
                        isDragReject,
                      }) => (
                        <section>
                          <div
                            {...getRootProps()}
                            className={
                              "dropzone-prop-container" +
                              (isDragActive
                                ? " active"
                                : isDragAccept
                                ? " accepted"
                                : isDragReject
                                ? " rejected"
                                : this.state.file === undefined
                                ? " selected"
                                : "")
                            }
                          >
                            <input {...getInputProps()} />
                            <div className="dropzone-prop-container-image">
                              <FontAwesomeIcon icon={["far", "copy"]} />
                            </div>
                            {this.state.file === undefined ? (
                              <p>
                                <Trans>Tap here to upload a file</Trans>
                              </p>
                            ) : (
                              <p>{this.state.file.path}</p>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  <div className="dropzone-prop-container-accepted smallScreen">
                    <OverlayTrigger
                      trigger="click"
                      placement="top"
                      rootClose
                      overlay={this.popoverAcceptedFiles}
                    >
                      <span>
                        <FontAwesomeIcon icon={["fal", "question"]} />{" "}
                        <Trans>See Accepted Files</Trans>
                      </span>
                    </OverlayTrigger>
                  </div>
                  <div className="old-document-upload-dropzone-container largeScreen">
                    <Trans>
                      or click{" "}
                      <span
                        onClick={() => {
                          dropzoneRef.open();
                        }}
                      >
                        here
                      </span>{" "}
                      to select a file from your computer
                    </Trans>
                  </div>
                  <div className="document-modal-btn-container">
                    <button
                      className="btn btn-inverse document-modal-btn"
                      onClick={(e) => this.props.handleUploadClose(e)}
                    >
                      <Trans>Cancel</Trans>
                    </button>
                    <button
                      className="btn btn-default document-modal-btn"
                      onClick={(e) => this.handleSubmitDocument()}
                      disabled={
                        this.state.document_name.trim() === "" ||
                        this.state.file === undefined
                      }
                    >
                      <Trans>Upload</Trans>
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div
                    className={
                      this.state.flow_state !== "complete"
                        ? "document-upload-dropzone-container"
                        : "document-upload-dropzone-container complete"
                    }
                  >
                    {this.state.flow_state === "uploading" ? (
                      <div>
                        <Trans>Uploading Document...</Trans>{" "}
                        <ProgressBar now={this.state.upload_percent} />
                      </div>
                    ) : (
                      false
                    )}
                    {this.state.flow_state === "scanning" ? (
                      <div>
                        <FontAwesomeIcon
                          icon={["far", "circle-notch"]}
                          className="fa-ease-in-out-spin"
                        />{" "}
                        <Trans>Scanning Document...</Trans>
                      </div>
                    ) : (
                      false
                    )}
                    {this.state.flow_state === "complete" ? (
                      <div
                        className={
                          "document-modal-upload-status-container" +
                          (this.state.status !== "clean" ? " error" : "")
                        }
                      >
                        <p className="document-upload-dropzone-complete-message">
                          {this.state.status !== "clean" ? (
                            <FontAwesomeIcon
                              icon={["far", "exclamation-triangle"]}
                              size="lg"
                            />
                          ) : (
                            <FontAwesomeIcon
                              icon={["far", "check-circle"]}
                              size="lg"
                            />
                          )}{" "}
                          {this.state.status_txt}
                        </p>
                      </div>
                    ) : (
                      false
                    )}
                  </div>
                  <div className="document-modal-btn-container">
                    <button
                      className="btn btn-inverse document-modal-btn"
                      onClick={(e) => this.props.handleUploadClose(e)}
                      disabled={this.state.flow_state !== "complete"}
                    >
                      <Trans>Close</Trans>
                    </button>
                    {this.state.flow_state === "prompt" ? (
                      <button
                        className="btn btn-default document-modal-btn"
                        onClick={(e) => this.handleAddDocument()}
                        disabled={this.state.flow_state !== "complete"}
                      >
                        <Trans>Add Document</Trans>
                      </button>
                    ) : (
                      false
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}
