import React from 'react';
import moment from 'moment';

import { FillLayout } from '../layout/layout.js';
import { invokeApi } from '../../libs/authlib';

import LoaderButton from '../components/loaderbutton';
import PasswordCriteria from '../components/passwordCriteriaWidget/passwordCriteriaWidget';
import { ComponentLoadingPlaceholder } from '../components/loadingplaceholder';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Trans } from 'react-i18next';
import AppContext from '../../appcontext';

import {
  FormGroup,
  FormControl,
  ControlLabel,
  Glyphicon,
  Modal,
  HelpBlock,
  Popover,
  OverlayTrigger
} from 'react-bootstrap';

import "./securitysettings.css";

/* SecuritySettings with Container */
class SecuritySettings extends React.Component {
  static contextType = AppContext;

  render() {
    return (  
        <div className="securitysettings-container">
          <FillLayout containerClassName="securitysettings-box">
            <SecuritySettingsContainer />
          </FillLayout>
        </div>
    );
  }
}

export default SecuritySettings;

class SecuritySettingsContainer extends React.Component {
    static contextType = AppContext;
  
    constructor(props) {
      super(props);
      
      this.state = {
        is_updating: false,
        is_loading_sq: true,
        is_loading_dts: true,
        is_revoking: false,
        show_password_modal: false,
        show_sq_modal: false,
        show_deactivate: false,
        deactivate_target: undefined,
        deactivate_token: null,
        u_sq: {},
        a_sq: [],
        dts: [],
      }
    }
  
    componentDidMount(){
      this.signal = this.context.axiosCancel();
  
      if ( this.context.user_account !== undefined) {
        this.getUserSqs();
        this.getAllSqs();
        this.getDeviceTokens();
      } else {
        this.setState({ loading_error: this.context.i18n.t("No account provided.")  });
      }
    }
  
    async getUserSqs(){
      try{
        let path = '/client/' + this.context.user_account + "/sq";
  
        await invokeApi({
           path: path,
           method: 'GET',
           onSuccess: r => this.setState({ is_updating: false, u_sq: r }),
           onError: e => this.setState({ is_updating : false, loading_error : e })
        });
      }catch(e){
        console.error(e);
      }
    }

    async getAllSqs(){
      try{
        let path = '/client/app/securityquestions';
  
        await invokeApi({
           path: path,
           method: 'GET',
           onSuccess: r => this.setState({ is_loading_sq: false, a_sq: r }),
           onError: e => this.setState({ is_loading_sq : false, loading_error : e })
        });
      }catch(e){
        console.error(e);
      }
    }

    async getDeviceTokens(){
      try{
        let path = '/client/' + this.context.user_account + "/devices/tokens";
  
        await invokeApi({
           path: path,
           method: 'GET',
           onSuccess: r => this.setState({ is_loading_dts: false, dts: r.sort((a,b)=> b.last_login - a.last_login ) }),
           onError: e => this.setState({ is_loading_dts : false, loading_error : e })
        });
      }catch(e){
        console.error(e);
      }
    }

    async revokeDeviceToken(id){
      this.setState({ is_revoking: true });
      try{
        let path = '/client/' + this.context.user_account + "/devices/token/"+id;
  
        await invokeApi({
           path: path,
           method: 'DELETE',
           onSuccess: r => this.finishRevoke(),
           onError: e => this.setState({ is_revoking : false, loading_error : e })
        });
      }catch(e){
        console.error(e);
      }
    }

    componentWillUnmount() {
      this.signal.cancel();
    }

    finishRevoke(){
      this.setState({ is_revoking: false, is_loading_dts: true });
      this.getDeviceTokens();
      document.body.click();
    }

    render(){
      const DeactivatePopover = ({id, ...props}) => {
        console.log(id, props);
        return (
          <Popover id="securitysettings-deactivate-popover" title={this.context.i18n.t('Confirm Deactivation')} {...props} >
            <p><Trans>Are you sure you wish to <b>revoke the device token</b>?</Trans></p>
            <LoaderButton
              block
              className="revoke-token-btn btn-red"
              type="button"
              onClick={()=>this.revokeDeviceToken(id)}
              isLoading={this.state.is_revoking}
              text={this.context.i18n.t("Revoke Token")}
              loadingText={this.context.i18n.t("Revoking Token")} />
          </Popover>
      )};

      return (
        <div className="securitysettings-grid">
            <div className="securitysettings-sidebar"></div>
            <div className="securitysettings-content">
                <div className="securitysettings-security-container">
                  <div className="securitysettings-info-image"><FontAwesomeIcon icon={["fas", "user-circle"]} /></div>
                  <div className="securitysettings-info-content">
                      <div className="securitysettings-info-name">{this.context.user_info !== undefined && this.context.user_info.first_name !== undefined? this.context.user_info.first_name : false} {this.context.user_info !== undefined && this.context.user_info.last_name !== undefined? this.context.user_info.last_name : false}</div>
                      <div className="securitysettings-info-email">{this.context.user_info !== undefined && this.context.user_info.email !== undefined? this.context.user_info.email : false}</div>
                  </div>
                  <div className="securitysettings-security-content">
                      <div className="securitysettings-security-item"><span onClick={()=>this.setState({ show_password_modal: true })}><FontAwesomeIcon icon={["fal", "pencil"]} /> <Trans>Update Password</Trans></span></div>
                      <div className="securitysettings-security-item"><span  onClick={()=>this.setState({ show_sq_modal: true })}><FontAwesomeIcon icon={["fal", "pencil"]} /> <Trans>Update Security Question</Trans></span></div>
                  </div>
                  <div className="securitysettings-devices-content">
                      <div className="securitysettings-devices-header"><Trans>Authorized Devices</Trans></div>
                      <div className="securitysettings-devices-items">
                        {!this.state.is_loading_dts ? this.state.dts.map((dt,i)=>{
                          return (
                          <div key={'dt-item-'+i} className="securitysettings-devices-item">
                            <div className="securitysettings-devices-item-info">
                              <div className="securitysettings-devices-item-info-row">
                                <div className="securitysettings-devices-item-info-location">{dt.city !== null && dt.city[this.context.currentLanguage()] !== undefined ? dt.city[this.context.currentLanguage()]+', ' : false}{dt.region_code !== null? dt.region_code+', ' : false}{dt.country_code !== null? dt.country_code : this.context.i18n.t('Unknown')}</div>
                                <div className="securitysettings-devices-item-info-group">
                                  <div className="securitysettings-devices-item-info-os">{dt.os !== null? <>{this.context.checkIconImported(["fab", dt.os.toLowerCase()]) ? <FontAwesomeIcon icon={["fab", dt.os.toLowerCase()]} /> : <FontAwesomeIcon icon={["fal", 'question']} />} <span>{dt.os}</span></> : this.context.i18n.t('Unknown')}</div>
                                  <div className="securitysettings-devices-item-info-browser">{dt.browser !== null? <>{this.context.checkIconImported(["fab", dt.browser.toLowerCase()]) ? <FontAwesomeIcon icon={["fab", dt.browser.toLowerCase()]} /> : <FontAwesomeIcon icon={["fal", 'question']} />} <span>{dt.browser}</span></> : this.context.i18n.t('Unknown')}</div>
                                  <div className="securitysettings-devices-item-info-type">{dt.device_type !== null? <>{this.context.checkIconImported(["fal", dt.device_type.toLowerCase()]) ? <FontAwesomeIcon icon={["fal", dt.device_type.toLowerCase()]} /> : <FontAwesomeIcon icon={["fal", 'question']} />} <span>{dt.device_type}</span></> : this.context.i18n.t('Unknown')}</div>
                                </div>
                              </div>
                              <div className="securitysettings-devices-item-info-row">
                                <div className="securitysettings-devices-item-info-time">{dt.last_login !== null? moment.unix(dt.last_login).format('MMM D YYYY, h:mm a'): this.context.i18n.t('Unknown')}</div>
                              </div>
                            </div>
                            <div className="securitysettings-devices-item-options">
                              <div className="securitysettings-devices-item-option-item">
                                <OverlayTrigger
                                  placement="left"
                                  overlay={<DeactivatePopover id={dt.uuid} />}
                                  trigger="click"
                                  rootClose
                                >
                                  <FontAwesomeIcon title={this.context.i18n.t('Deactivate')} icon={["fal", 'trash-alt']} />
                                </OverlayTrigger>
                              </div>
                            </div>
                          </div>)
                        }): (<ComponentLoadingPlaceholder text={(<><Trans>Loading</Trans> <Trans>Device Tokens</Trans></>)} />)}
                      </div>
                  </div>
                </div>
            </div>
            <UpdatePasswordModal show={this.state.show_password_modal} handleClose={()=>this.setState({ show_password_modal: false })} usqs={this.state.u_sq} />
            <UpdateSQModal show={this.state.show_sq_modal} handleClose={()=>{this.getUserSqs(); this.setState({ show_sq_modal: false })}} usqs={this.state.u_sq} asqs={this.state.a_sq} />
        </div>
      );
    }
  }

  class UpdatePasswordModal extends React.Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
  
      this.state = {
        show_modal: this.props.show,
        isLoading: false,
        inputType: 'password',
        password: '',
        question: '',
        answer: '',
        loading_error: null
      }
    }
  
    componentDidUpdate( ){
      if(this.state.show_modal!==this.props.show){
        this.setState({
          show_modal: this.props.show,
          isLoading: false,
          inputType: 'password',
          password: '',
          question: Object.keys(this.props.usqs)[0],
          answer: '',
          loading_error: null
        });
      }
    }

    validateForm(){
      return this.validatePassword()
      && this.state.question.length > 0
      && this.state.answer.length > 0
    }

    validatePassword(){
      // Does the password include 1 uppercase
      return /.*[A-Z]{1,}.*/.test(this.state.password)
      // Does the password include 1 lowercase
      && /.*[a-z]{1,}.*/.test(this.state.password)
      // Does the password include special characters
      && /.*[!$%^&*()_=|~`{}[\]:";'<>?,./\\@#]{1,}.*/.test(this.state.password)
      // Does the password include numbers
      && /.*[0-9]{1,}.*/.test(this.state.password)
      // Is the password at least 8 characters
      && this.state.password.length >= 8 
    }
  
    handleChange = (event) => {
      this.setState({
        [event.target.id]: event.target.value,
        loading_error: null
      });
    }

    
    flipPasswordInputState(){
      if(this.state.inputType === "password"){
        this.setState({inputType: "text"});
      }else{
        this.setState({inputType: "password"});
      }
    }


    updatePassword = async (event) =>{
      event.preventDefault();
      
      try{
        let path = '/client/' + this.context.user_account + '/password';
  
        await invokeApi({
           path: path,
           method: 'POST',
           body: { sq: this.state.question[1], sqa: this.state.answer, password: this.state.password },
           onSuccess: r => this.props.handleClose(),
           onError: e => this.setState({ isLoading : false, loading_error : e })
        });
      }catch(e){
        console.error(e);
      }
  }

    render() {
      return(
        <Modal
          show={this.state.show_modal}
          animation={false}
          onHide={this.props.handleClose}
          container={this}
          aria-labelledby="modal-title"
          className="update-password-modal"
          >
          <Modal.Header closeButton>
            <Modal.Title id="modal-title"><Trans>Update Password</Trans></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="update-password-modal-content">
              <form onSubmit={this.updatePassword}>
                <FormGroup controlId="password" className="form-input reset-email-input">
                  <FormControl
                    autoFocus
                    type={this.state.inputType}
                    placeholder={this.context.i18n.t("New Password")}
                    value={this.state.password}
                    onChange={this.handleChange} />
                    <Glyphicon 
                      glyph={ ((this.state.inputType === "password") ? "eye-open" : "eye-close") }
                      onClick={() => this.flipPasswordInputState()}
                      className="password-eye-icon"
                      />
                </FormGroup>
                <PasswordCriteria className="password-criteria-container unselectable" password={this.state.password} />
                <div className="update-password-mfa-text"><Trans>To update your password you must also answer a security question</Trans></div>
                <FormGroup controlId="question">
                    <FormControl
                    componentClass="select"
                    value={this.state.question}
                    onChange={this.handleChange} >
                    {Object.entries(this.props.usqs).map(sq=>{
                        return <option key={ sq[0] } value={sq[0]}>{this.context.i18n.t(sq)}</option>
                    }, this)}
                    </FormControl>
                </FormGroup>
                <FormGroup controlId="answer" validationState={this.state.loading_error === 'forbidden'? 'error': null}>
                    <FormControl
                    placeholder={this.context.i18n.t('Answer')}
                    value={this.state.answer}
                    onChange={this.handleChange} />
                    <HelpBlock>{ this.state.loading_error === 'forbidden' ? <Trans>Unable to update password with provided answer, please try again</Trans> : false }</HelpBlock>
                </FormGroup>
                <LoaderButton
                  block
                  className="update-password-submit-btn"
                  disabled={ ! this.validateForm() }
                  type="submit"
                  isLoading={this.state.isLoading}
                  text={this.context.i18n.t("Update Password")}
                  loadingText={this.context.i18n.t("Updating Password")} />
              </form>
            </div>
          </Modal.Body>
      </Modal>
      );
    }

  }

  class UpdateSQModal extends React.Component {
    static contextType = AppContext;

    constructor(props) {
      super(props);
  
      this.state = {
        show_modal: this.props.show,
        isLoading: false,
        old_question: 'q1',
        new_question: '',
        new_answer: '',
        question: 'q2',
        answer: '',
        loading_error: null
      }
    }
  
    componentDidUpdate( ){
      if(this.state.show_modal!==this.props.show){
        this.setState({
          show_modal: this.props.show,
          isLoading: false,
          old_question: 'q1',
          new_question: this.props.usqs['q1'],
          new_answer: '',
          question: 'q2',
          answer: '',
          loading_error: null
        });
      }
    }

    validateForm(){
      return this.state.old_question.length > 0
        && this.state.new_question.length > 0
        && this.state.new_answer.length > 0
        && this.state.question.length > 0
        && this.state.answer.length > 0
        && this.state.question !== this.state.old_question
        && this.props.usqs[this.state.question] !== this.state.new_question
    }

    handleChange = (event) => {
      this.setState({
        [event.target.id]: event.target.value,
        loading_error: null
      });
    }

    handleOldSqChange = (event) => {
      this.setState({
        old_question: event.target.value,
        question: event.target.value === 'q1' ? 'q2': 'q1',
        loading_error: null
      })
    }

    updateSQ = async (event) =>{
      event.preventDefault();
      
      try{
        let path = '/client/' + this.context.user_account + '/sq/update';
  
        await invokeApi({
           path: path,
           method: 'POST',
           body: {
            current_question: this.state.old_question[1],
            new_question: this.state.new_question,
            new_answer: this.state.new_answer,
            sq: this.state.question[1],
            sqa: this.state.answer },
           onSuccess: r => this.props.handleClose(),
           onError: e => this.setState({ isLoading : false, loading_error : e })
        });
      }catch(e){
        console.error(e);
      }
    }

    render() {
      return(
        <Modal
          show={this.state.show_modal}
          animation={false}
          onHide={this.props.handleClose}
          container={this}
          aria-labelledby="modal-title"
          className="update-sq-modal"
          >
          <Modal.Header closeButton>
            <Modal.Title id="modal-title"><Trans>Update Security Question</Trans></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="update-sq-modal-content">
              <form onSubmit={this.updateSQ}>
                <FormGroup controlId="question">
                    <ControlLabel><Trans>Current Question</Trans></ControlLabel>
                    <FormControl
                    componentClass="select"
                    value={this.state.old_question}
                    onChange={this.handleOldSqChange} >
                    {Object.entries(this.props.usqs).map(sq=>{
                        return <option key={ sq[0] } value={sq[0]}>{this.context.i18n.t(sq)}</option>
                    }, this)}
                    </FormControl>
                </FormGroup>
                <FormGroup controlId="new_question">
                  <ControlLabel><Trans>New Question</Trans></ControlLabel>
                  <FormControl
                    componentClass="select"
                    value={this.state.new_question}
                    onChange={this.handleChange} >
                    {this.props.asqs.map(function(sq, index){
                      if(this.props.usqs[this.state.question] !== sq) {
                        return <option key={ index } value={sq}>{this.context.i18n.t(sq)}</option>
                      } else {
                        return false;
                      }
                    }, this)}
                  </FormControl>
                </FormGroup>
                <FormGroup controlId="new_answer">
                    <FormControl
                    placeholder={this.context.i18n.t('Answer')}
                    value={this.state.new_answer}
                    onChange={this.handleChange} />
                </FormGroup>
                <div className="update-sq-mfa-text"><Trans>To update you must also answer the following question</Trans></div>
                <div className="update-sq-question-text"><Trans>{this.props.usqs[this.state.question]}</Trans></div>
                <FormGroup controlId="answer" validationState={this.state.loading_error === 'forbidden'? 'error': null}>
                    <FormControl
                    placeholder={this.context.i18n.t('Answer')}
                    value={this.state.answer}
                    onChange={this.handleChange} />
                    <HelpBlock>{ this.state.loading_error === 'forbidden' ? <Trans>Unable to update with provided answer, please try again</Trans> : false }</HelpBlock>
                </FormGroup>
                <LoaderButton
                  block
                  className="update-sq-submit-btn"
                  disabled={ ! this.validateForm() }
                  type="submit"
                  isLoading={this.state.isLoading}
                  text={this.context.i18n.t("Update Security Question")}
                  loadingText={this.context.i18n.t("Updating Security Question")} />
              </form>
            </div>
          </Modal.Body>
      </Modal>
      );
    }

  }
