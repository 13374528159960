import React from 'react';
import * as recharts from 'recharts';
import * as d3 from 'd3';

import numeral from 'numeral';

import AppContext from '../../appcontext';


export class PortfolioAssetBucketRadialBar extends React.Component  {
  static contextType = AppContext;

	render () {
    let data = [
    ]

    let width = this.props.size !== undefined? this.props.size : 500
    let height = this.props.size !== undefined? this.props.size : 500

    if (this.props.data['EQUITY'] !== undefined) {
      let d = { name: 'EQUITY', w: this.props.data['EQUITY'], fill: '#8884d8', value: null }

      if (this.props.dataValues !== undefined && this.props.dataValues['EQUITY'] !== undefined){
        d['value'] = this.props.dataValues['EQUITY'];
      }

      data.push(d);
    }
    if (this.props.data['BALANCED FUNDS'] !== undefined) {
      let d = { name: 'BALANCED FUNDS', w: this.props.data['BALANCED FUNDS'], fill: '#83a6ed', value: null }

      if (this.props.dataValues !== undefined && this.props.dataValues['BALANCED FUNDS'] !== undefined){
        d['value'] = this.props.dataValues['BALANCED FUNDS'];
      }

      data.push(d);
    }
    if (this.props.data['INCOME INVESTMENTS'] !== undefined) {
      let d = { name: 'INCOME INVESTMENTS', w: this.props.data['INCOME INVESTMENTS'], fill: '#8dd1e1', value: null }

      if (this.props.dataValues !== undefined && this.props.dataValues['INCOME INVESTMENTS'] !== undefined){
        d['value'] = this.props.dataValues['INCOME INVESTMENTS'];
      }

      data.push(d);
    }
    if (this.props.data['ALTERNATIVE INVESTMENTS'] !== undefined) {
      let d = { name: 'ALTERNATIVE INVESTMENTS', w: this.props.data['ALTERNATIVE INVESTMENTS'], fill: '#a4de6c', value: null }

      if (this.props.dataValues !== undefined && this.props.dataValues['ALTERNATIVE INVESTMENTS'] !== undefined){
        d['value'] = this.props.dataValues['ALTERNATIVE INVESTMENTS'];
      }
  
      data.push(d);
    }
    if (this.props.data['Unassigned'] !== undefined) {
      data.push({ name: 'Unassigned', w: this.props.data['Unassigned'], fill: '#ffc658', value: null });
    }

    //data.sort(function(a,b){ return  a.w - b.w })

  	return (
        <recharts.RadialBarChart data={data} 
                                 margin={{top: 10, right: 30, left: 0, bottom: 0}} 
                                 height={height}
                                 width={width}
                                 barSize={15}
                                 startAngle={180} 
                                 endAngle={0}
                                 innerRadius='50%'
                                 outerRadius='100%'>
              
            <recharts.PolarAngleAxis type="number" 
                                     domain={[0, 100]} 
                                     dataKey={'w'} 
                                     angleAxisId={0} 
                                     tick={false} />
            
            <recharts.RadialBar minAngle={15} 
                                label={false} 
                                angleAxisId={0} 
                                background 
                                clockWise={false} 
                                dataKey='w' 
                                legendType='circle'/>

            <recharts.Legend iconSize={10} 
                             width={250} 
                             height={140} 
                             formatter={(v, e, i)=>{ return v.toLowerCase()}}
                             wrapperStyle={{left: width+25, top: (width/2)/3, fontSize: 12}} 
                             layout='vertical' 
                             verticalAlign='middle'/>

            <recharts.Tooltip cursor={{ strokeWidth: 13.5, opacity: 0.15 }}
                              position={{ x: width/2, y: height/2 }}
                              content={<CustomRadialBarTooltip />}
                              //formatter={(v, n, p)=>{ return [numeral(v/100).format('0.0%'), this.context.i18n.t('Percent'), ] }}
                              //labelFormatter={i=>{ return data[i].name }} 
                              />
        </recharts.RadialBarChart>
    );
  }
}

export class PortfolioAssetBucketHalfPie extends React.Component  {
  static contextType = AppContext;

	render () {
    let data = []

    let width = this.props.size !== undefined? this.props.size : 500
    let height = this.props.size !== undefined? this.props.size : 500

    if (this.props.data['CASH & EQUIVALENT'] !== undefined) {
      let d = { name: this.context.i18n.t('CASH & EQUIVALENT'), w: this.props.data['CASH & EQUIVALENT'], fill: '#a4de6c', value: null }

      if (this.props.dataValues !== undefined && this.props.dataValues['CASH & EQUIVALENT'] !== undefined){
        d['value'] = this.props.dataValues['CASH & EQUIVALENT'];
      }
  
      data.push(d);
    }
    if (this.props.data['INCOME INVESTMENTS'] !== undefined) {
      let d = { name: this.context.i18n.t('INCOME INVESTMENTS'), w: this.props.data['INCOME INVESTMENTS'], fill: '#8dd1e1', value: null }

      if (this.props.dataValues !== undefined && this.props.dataValues['INCOME INVESTMENTS'] !== undefined){
        d['value'] = this.props.dataValues['INCOME INVESTMENTS'];
      }

      data.push(d);
    }
    if (this.props.data['ALTERNATIVE INVESTMENTS'] !== undefined) {
      let d = { name: this.context.i18n.t('ALTERNATIVE INVESTMENTS'), w: this.props.data['ALTERNATIVE INVESTMENTS'], fill: '#a4de6c', value: null }

      if (this.props.dataValues !== undefined && this.props.dataValues['ALTERNATIVE INVESTMENTS'] !== undefined){
        d['value'] = this.props.dataValues['ALTERNATIVE INVESTMENTS'];
      }
  
      data.push(d);
    }
    if (this.props.data['BALANCED FUNDS'] !== undefined) {
      let d = { name: this.context.i18n.t('BALANCED FUNDS'), w: this.props.data['BALANCED FUNDS'], fill: '#83a6ed', value: null }

      if (this.props.dataValues !== undefined && this.props.dataValues['BALANCED FUNDS'] !== undefined){
        d['value'] = this.props.dataValues['BALANCED FUNDS'];
      }

      data.push(d);
    }
    if (this.props.data['EQUITY'] !== undefined) {
      let d = { name: this.context.i18n.t('EQUITY'), w: this.props.data['EQUITY'], fill: '#8884d8', value: null }

      if (this.props.dataValues !== undefined && this.props.dataValues['EQUITY'] !== undefined){
        d['value'] = this.props.dataValues['EQUITY'];
      }

      data.push(d);
    }
    if (this.props.data['Unassigned'] !== undefined) {
      data.push({ name: this.context.i18n.t('Unassigned'), w: this.props.data['Unassigned'], fill: '#ffc658', value: null });
    }

  	return (
        <recharts.PieChart margin={{top: 0, right: 0, left: 0, bottom: 0}} 
                           height={height}
                           width={width}
                           barSize={15}>
            
            <recharts.Pie data={data} 
                          cx='50%' cy='50%'
                          innerRadius={100 * (Math.ceil(width * 0.55)/width)}
                          outerRadius={100 * (Math.ceil(width * 0.95)/width)}
                          minAngle={5}
                          startAngle={180} 
                          endAngle={0}
                          label={false}
                          dataKey='w' 
                          legendType='circle'/>

            <recharts.Legend iconSize={10} 
                             width={250} 
                             height={140} 
                             formatter={(v, e, i)=>{ return v.toLowerCase()}}
                             wrapperStyle={{left: width+25, top: (width/2)/2, fontSize: 12}} 
                             layout='vertical' 
                             verticalAlign='middle'/>

            <recharts.Tooltip cursor={{ strokeWidth: 13.5, opacity: 0.15 }}
                              position={{ x: width/2, y: height/2 }}
                              content={<CustomRadialBarTooltip />}
                              //formatter={(v, n, p)=>{ return [numeral(v/100).format('0.0%'), this.context.i18n.t('Percent'), ] }}
                              //labelFormatter={i=>{ return data[i].name }} 
                              />
        </recharts.PieChart>
    );
  }
}

const CustomRadialBarTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-radialbar-tooltip">
        <p className="label-pct" style={{color: payload[0].payload.fill}}>{numeral(payload[0].value).format('0.0')}<span className="small-symbol">%</span></p>
        <p className="label-cat">{`${payload[0].payload.name.toLowerCase()}`}</p>
        <p className="label-value">{payload[0].payload.value !== null? (<span>{numeral(payload[0].payload.value).format('$ 0,0')}</span>) : false }</p>
      </div>
    );
  }

  return null;
};

export class PortfolioBreakdownBar extends React.Component  {
  static contextType = AppContext;

	render () {
    let raw_data = [];

    let keys = Object.keys(this.props.data);
    let sum = this.props.data['summary'];

    for (let i = 0; i < keys.length; i++) {
      if(keys[i] !== 'summary'){
        let rec = this.props.data[keys[i]];
        if (rec !== null) {
          raw_data.push({ name: rec['pfName'], cvalue: rec['pfTotalValue'], value: (rec['pfTotalValue'] / sum['pfTotalValue']), id: rec['pf_uuid'] });
        }
      }
    }
    raw_data.sort(function(a,b){ return  b.value - a.value });

    let min_bar = this.props.minBar !== undefined? this.props.minBar : 0.01;
    let balance = { under: 0, over: 0, amount: 0 }
    for (let i = 0; i < raw_data.length; i++){
      if (raw_data[i]['value'] < min_bar ) {
        balance['under'] ++;
        balance['amount'] +=  (min_bar - raw_data[i]['value']);
        raw_data[i]['dvalue'] = min_bar;
      }else{
        balance['over'] += raw_data[i]['value'];
        raw_data[i]['dvalue'] = raw_data[i]['value'];
      }
    }

    let domainmax=(1+balance['amount']);

    let data = []

    let d = {}
    let id_map = {}
    for (let i = 0; i < raw_data.length; i++){
      d[raw_data[i]['name']] = raw_data[i]['dvalue'];
      id_map[raw_data[i]['name']] = raw_data[i]['id'];
    }

    data.push(d);

    let width = this.props.width !== undefined? this.props.width : 300
    let height = this.props.height !== undefined? this.props.height : 85

    let color = d3.scaleLinear().domain([0,raw_data.length])
      .interpolate(d3.interpolateHclLong)
      .range([d3.rgb("#b6c5df"), d3.rgb('#023959')]);

  
  	return (
        <recharts.BarChart data={data}
                          className={'portfolio-bar-breakdown'}
                          layout="vertical"
                          margin={{top: 20, right: 0, left: 0, bottom: 20}}
                          height={height}
                          width={width}
                          barSize={height-55}
                          barGap={5}
                          barCategoryGap={5}>
                <recharts.XAxis hide type="number" domain={[0, domainmax]} />
                <recharts.YAxis hide type="category" />
                <recharts.Customized key={'t-label'} component={(props) => {
                    let pfname = this.props.activeAccount === 'summary'? this.context.i18n.t('All Accounts') : this.props.data[this.props.activeAccount].pfName 
                    let pfpct = this.props.activeAccount === 'summary'? 1 : this.props.data[this.props.activeAccount].pfTotalValue / Math.max(1, sum.pfTotalValue);
                    return this.props.activeAccount !== 'summary'? (<text className="portfolio-bar-breakdown-label-top"><tspan dy="1em">{pfname}</tspan><tspan dx="1em">{numeral(pfpct * 100).format('0.0')}</tspan><tspan className="small-symbol" dy="0.2em" dx="0.1em">%</tspan></text>)
                                                                 : (<text className="portfolio-bar-breakdown-label-top"><tspan dy="1em">{pfname}</tspan></text>)
                  }} />
                <recharts.Customized key={'b-label'} component={(props) => {
                    let pfval = this.props.activeAccount === 'summary'? sum.pfTotalValue : this.props.data[this.props.activeAccount].pfTotalValue;
                    return (<text className="portfolio-bar-breakdown-label-bottom"><tspan>{numeral(pfval).format('$s 0,0')}</tspan></text>)
                  }} />
                {/* <recharts.Customized key={'t-marker'} component={(props) => <path d="M 0 0 L 4 4 L 8 0" className="portfolio-bar-breakdown-marker-top" />} />*/}
                { 
                    (raw_data.map(v=>v.name)).map( 
                      (v,i) => <recharts.Bar key={v} 
                                            dataKey={v} 
                                            stackId="single-stack"
                                            isAnimationActive={false} 
                                            fill={id_map[v] === this.props.activeAccount || this.props.activeAccount === 'summary' ? color(i) : '#eeeeee'}/>
                    )
                }
        </recharts.BarChart>
    );
  }
}

export class PortfolioAssetBucketDoublePie extends React.Component  {
  static contextType = AppContext;

	render () {
    let data = []
    let sub_data = []

    let width = this.props.size !== undefined? this.props.size : 300
    let height = this.props.size !== undefined? this.props.size : 300


    if (this.props.data['CASH & EQUIVALENT'] !== undefined) {
      let d = { name: this.context.i18n.t('CASH & EQUIVALENT'), w: this.props.data['CASH & EQUIVALENT']['pct'], fill: '#a4de6c', value: this.props.data['CASH & EQUIVALENT']['value'] }
  
      let keys = Object.keys(this.props.data['CASH & EQUIVALENT']['sub']);
      let color = d3.color('#a4de6c');

      for(let i=0; i<keys.length; i++){
        let dv = this.props.data['CASH & EQUIVALENT']['sub'][keys[i]];

        sub_data.push({ name: this.context.i18n.t(keys[i]), w: dv['pct'], fill: color.darker((i+1)/3).hex(), value: dv['value'] })
      }

      data.push(d);
    } else {

    }
    if (this.props.data['INCOME INVESTMENTS'] !== undefined) {
      let d = { name: this.context.i18n.t('INCOME INVESTMENTS'), w: this.props.data['INCOME INVESTMENTS']['pct'], fill: '#8dd1e1', value: this.props.data['INCOME INVESTMENTS']['value'] }

      let keys = Object.keys(this.props.data['INCOME INVESTMENTS']['sub']);
      let color = d3.color('#8dd1e1');
    
      for(let i=0; i<keys.length; i++){
        let dv = this.props.data['INCOME INVESTMENTS']['sub'][keys[i]];

        sub_data.push({ name: this.context.i18n.t(keys[i]), w: dv['pct'], fill: color.darker((i+1)/3).hex(), value: dv['value'] })
      }

      data.push(d);
    }
    if (this.props.data['ALTERNATIVE INVESTMENTS'] !== undefined) {
      let d = { name: this.context.i18n.t('ALTERNATIVE INVESTMENTS'), w: this.props.data['ALTERNATIVE INVESTMENTS']['pct'], fill: '#a4de6c', value: this.props.data['ALTERNATIVE INVESTMENTS']['value'] }

      let keys = Object.keys(this.props.data['ALTERNATIVE INVESTMENTS']['sub']);
      let color = d3.color('#a4de6c');

      for(let i=0; i<keys.length; i++){
        let dv = this.props.data['ALTERNATIVE INVESTMENTS']['sub'][keys[i]];

        sub_data.push({ name: this.context.i18n.t(keys[i]), w: dv['pct'], fill: color.darker((i+1)/3).hex(), value: dv['value'] })
      }

      data.push(d);
    }
    if (this.props.data['BALANCED FUNDS'] !== undefined) {
      let d = { name: this.context.i18n.t('BALANCED FUNDS'), w: this.props.data['BALANCED FUNDS']['pct'], fill: '#83a6ed', value: this.props.data['BALANCED FUNDS']['value']}

      let keys = Object.keys(this.props.data['BALANCED FUNDS']['sub']);
      let color = d3.color('#83a6ed');
  
      for(let i=0; i<keys.length; i++){
        let dv = this.props.data['BALANCED FUNDS']['sub'][keys[i]];

        sub_data.push({ name: this.context.i18n.t(keys[i]), w: dv['pct'], fill: color.darker((i+1)/3).hex(), value: dv['value'] })
      }

      data.push(d);
    }
    if (this.props.data['EQUITY'] !== undefined) {
      let d = { name: this.context.i18n.t('EQUITY'),  w: this.props.data['EQUITY']['pct'], fill: '#8884d8', value: this.props.data['EQUITY']['value'] }

      let keys = Object.keys(this.props.data['EQUITY']['sub']);
      let color = d3.color('#8884d8');

      for(let i=0; i<keys.length; i++){
        let dv = this.props.data['EQUITY']['sub'][keys[i]];

        sub_data.push({ name: this.context.i18n.t(keys[i]), w: dv['pct'], fill: color.darker((i+1)/3).hex(), value: dv['value'] })
      }

      data.push(d);
    }
    if (this.props.data['UNKNOWN'] !== undefined) {
      let d = { name: this.context.i18n.t('UNKNOWN'),  w: this.props.data['UNKNOWN']['pct'], fill: '#ffcc62', value: this.props.data['UNKNOWN']['value'] }

      let keys = Object.keys(this.props.data['UNKNOWN']['sub']);
      let color = d3.color('#ffcc62');

      for(let i=0; i<keys.length; i++){
        let dv = this.props.data['UNKNOWN']['sub'][keys[i]];

        sub_data.push({ name: this.context.i18n.t(keys[i]), w: dv['pct'], fill: color.darker((i+1)/3).hex(), value: dv['value'] })
      }

      data.push(d);
    }

  	return (
        <recharts.PieChart margin={{top: 0, right: 0, left: 0, bottom: 0}} 
                           height={height}
                           width={width} >
            
            <recharts.Pie data={data} 
                          cx='50%' cy='50%'
                          outerRadius={100 * (Math.ceil(width * 0.65)/width)}
                          label={false}
                          dataKey='w' 
                          legendType='circle'
                          minAngle={1}
                          animationBegin={0}
                          animationDuration={750}/>

            <recharts.Pie data={sub_data} 
                          cx='50%' cy='50%'
                          innerRadius={100 * (Math.ceil(width * 0.75)/width)}
                          outerRadius={100 * (Math.ceil(width * 0.95)/width)}
                          label={false}
                          dataKey='w' 
                          minAngle={1}
                          legendType='circle'
                          animationBegin={0}
                          animationDuration={750}/>

            <recharts.Tooltip position={{ x: width/2, y: height }}
                              content={<CustomTwoAreaTooltip />} 
                              />

        </recharts.PieChart>
    );
  }
}

const CustomTwoAreaTooltip = ({ active, payload, label }) => {
  if (active) {
    return (
      <div className="custom-radialbar-tooltip">
        <p className="label-pct"><span style={{color: payload[0].payload.fill}}>{numeral(payload[0].payload.value).format('$ 0,0')}</span> <span className="label-pct-sm" style={{color: 'var(--text-secondary)'}}>{numeral(payload[0].value).format('0.0')}<span className="small-symbol">%</span></span></p>
        <p className="label-cat">{`${payload[0].payload.name.toLowerCase()}`}</p>
      </div>
    );
  }

  return null;
};