import React from 'react';
import axios from 'axios';

import { FillLayout } from '../layout/layout.js';
import { invokeApi } from '../../libs/authlib';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Trans } from 'react-i18next';
import AppContext from '../../appcontext';

import {formatPhoneNumberIntl} from 'react-phone-number-input';


import 'react-phone-number-input/style.css';
import "./accountsettings.css"

/* AccountSettings with Container */
class AccountSettings extends React.Component {
  static contextType = AppContext;

  render() {
    return (  
        <div className="accountsettings-container">
          <FillLayout containerClassName="accountsettings-box">
            <AccountSettingsContainer />
          </FillLayout>
        </div>
    );
  }
}

export default AccountSettings;

class AccountSettingsContainer extends React.Component {
    static contextType = AppContext;
  
    constructor(props) {
      super(props);
      
      this.state = {
        is_updating: true,
        edit_mobile: false,
        sms_notif: false,
        phone_number: '',
        is_updating_avatar: false,
        updating_avatar_status: null,
      }
    }
  
    componentDidMount(){
      this.signal = this.context.axiosCancel();
  
      if ( this.context.user_account !== undefined) {
          this.getBasics();
          this.setState({ 
              phone_number: this.context.user_info !== undefined && this.context.user_info.phone_number !== undefined && this.context.user_info.phone_number !== null ? this.context.user_info.phone_number : ''
           });
      } else {
        this.setState({ loading_error: this.context.i18n.t("No account provided.")  });
      }
    }
  
  
    componentWillUnmount() {
      this.signal.cancel();
    }
  
    async getBasics(){
        try{
          let path = '/client/' + this.context.user_account + "/basics";
    
          await invokeApi({
             path: path,
             method: 'GET',
             onSuccess: r => this.setState({ is_updating: false, ...r}),
             onError: e => this.setState({ is_updating : false, loading_error : e })
          });
        }catch(e){
          console.error(e);
        }
    }

    async update_sms_setting(toggle){
        try{
          let path = '/client/' + this.context.user_account + "/basics";
    
          await invokeApi({
             path: path,
             method: 'POST',
             body: { sms_notif: toggle },
             onSuccess: r => this.setState({ is_updating: false }),
             onError: e => this.setState({ is_updating : false, loading_error : e })
          });
        }catch(e){
          console.error(e);
        }
    }

    toggle_sms = (toggle) => {
        if (!this.state.is_updating){
            this.update_sms_setting(toggle);
            this.setState({ sms_notif: toggle });
        }
    }

    uploadAvatar = async (filelist) => {
      if (filelist.length > 0) {
        let file = filelist[0];
        let prep_data = await this.getAvatarUploadUrl(file);

        this.setState({ is_updating_avatar: true, updating_avatar_status: 'uploading' });
        let options = { 
          headers: prep_data.headers,
        }
        await axios.put(prep_data.url, file, options);
        let avtr = await this.checkAvatarStatus();

        this.setState({ is_updating_avatar: false, updating_avatar_status: null, avatar: avtr.data });
      }
    }

    sanitizeName = name => name.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

    getAvatarUploadUrl(file){
      return invokeApi({
        path: "/client/" + this.context.user_account + "/avatar/upload", 
        method: "post",
        body: {
          file_name: this.sanitizeName(file.name),
          file_type: file.type
        }
      }); 
    }

    async checkAvatarStatus() {
      let i = 0;
      let resp = undefined;
      resp = await this.getAvatarStatus();
      while( i <= 30 && resp.status === "uploading" ) {
        await new Promise(resolve => setTimeout(resolve, 2000));
        resp = await this.getAvatarStatus();
        i = i + 2;
      }
      return resp
    }

    getAvatarStatus() {
      return invokeApi({
        path: "/client/" + this.context.user_account + "/avatar", 
        method: "get"
      });
    }

    render(){
      return (
        <div className="accountsettings-grid">
            <div className="accountsettings-sidebar"></div>
            <div className="accountsettings-content">
                <div className="accountsettings-info-container">
                    <div className="accountsettings-info-image largeScreen">
                      <div className="accountsettings-change-avatar-img">
                        {this.state.is_updating_avatar ? <FontAwesomeIcon icon={["far", "circle-notch"]}  className="fa-ease-in-out-spin" /> 
                        : this.state.avatar !== undefined && this.state.avatar !== null ? <img src={this.state.avatar} alt="" />
                        : <FontAwesomeIcon icon={["fas", "user-circle"]} /> }
                      </div>
                      <div className="accountsettings-change-avatar-container">
                        {this.state.is_updating_avatar? <div className="accountsettings-change-avatar-text"><Trans>Uploading</Trans></div> : <button className="btn btn-link accountsettings-change-avatar-btn" onClick={(e)=>document.getElementById('avatar_file').click()}><Trans>Update Image</Trans></button> }
                        <input type="file" name="avatar_file" id="avatar_file" style={{ display: 'none' }} accept=".jpeg,.png" onChange={(e)=>{this.uploadAvatar(e.target.files)}}/>
                      </div>
                    </div>
                    <div className="accountsettings-info-image smallScreen" onClick={(e)=>document.getElementById('avatar_file').click()}>
                      <div className="accountsettings-change-avatar-img">
                        {this.state.is_updating_avatar ? <FontAwesomeIcon icon={["far", "circle-notch"]}  className="fa-ease-in-out-spin" /> 
                        : this.state.avatar !== undefined && this.state.avatar !== null ? <img src={this.state.avatar} alt="" />
                        : <FontAwesomeIcon icon={["fas", "user-circle"]} /> }
                      </div>
                      <div className="accountsettings-change-avatar-container">
                        {this.state.is_updating_avatar? <div className="accountsettings-change-avatar-text"><Trans>Uploading</Trans></div> : <button className="btn btn-link accountsettings-change-avatar-btn" onClick={(e)=>document.getElementById('avatar_file').click()}><Trans>Update Image</Trans></button> }
                        <input type="file" name="avatar_file" id="avatar_file" style={{ display: 'none' }} accept=".jpeg,.png" onChange={(e)=>{this.uploadAvatar(e.target.files)}}/>
                      </div>
                    </div>
                    <div className="accountsettings-info-content">
                        <div className="accountsettings-info-name">{this.context.user_info !== undefined && this.context.user_info.first_name !== undefined? this.context.user_info.first_name : false} {this.context.user_info !== undefined && this.context.user_info.last_name !== undefined? this.context.user_info.last_name : false}</div>
                        <div className="accountsettings-info-email">{this.context.user_info !== undefined && this.context.user_info.email !== undefined? this.context.user_info.email : false}</div>
                        {/* <div className="accountsettings-info-notifications">
                            <div className="accountsettings-notifications-header"><Trans>Notifications</Trans></div>
                            <div className="accountsettings-notifications-mobile">
                                <div className="accountsettings-notifications-mobile-header unselectable"><Trans>Mobile Phone</Trans></div>
                                <div className="accountsettings-notifications-mobile-toggle-container">
                                    <div className="accountsettings-notifications-mobile-number">{formatPhoneNumberIntl(this.state.phone_number)}</div>
                                </div>
                            </div>
                            <div className="accountsettingsnotifications-notifications-sms  unselectable">
                                <div className="accountsettingsnotifications-notifications-sms-message"><Trans>Recieve notifications via SMS</Trans></div>
                                <div className="accountsettingsnotifications-notifications-sms-toggle">{!this.state.sms_notif ? <FontAwesomeIcon onClick={()=>this.toggle_sms(true)} icon={["fal", "square"]} /> : <FontAwesomeIcon onClick={()=>this.toggle_sms(false)} icon={["fal", "check-square"]} /> }</div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
      );
    }
  }
