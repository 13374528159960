import React, { Component } from 'react';
import {
  Alert
} from 'react-bootstrap';
class  AlertDismissable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      alertVisible: true
    }
  }
  
  handleAlertDismiss = () => {
    this.setState({ alertVisible: false });
  }

  handleAlertShow = () => {
    this.setState({ alertVisible: true });
  }

  render() {
    if (this.state.alertVisible) {
      return (
        <Alert bsStyle={this.props.bsStyle} onDismiss={this.handleAlertDismiss}>
          {this.props.children}
        </Alert>
      );
    }

    return null;
  }

  
}

export default AlertDismissable;