import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import './passwordCriteriaWidget.css';

import { Trans } from 'react-i18next';

class PasswordCriteria extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasUppercase: false,
      hasLowercase: false,
      isLongEnough: false,
      hasSpecialChar: false,
      hasNumber: false
    }

  }

  componentWillReceiveProps(nextProps){
    this.getPasswordCompliance(nextProps.password);
  }

  getPasswordCompliance(password){

    let stateObj = {};

    // Does the password include 1 uppercase
    if(/.*[A-Z]{1,}.*/.test(password)){
      stateObj['hasUppercase'] = true;
    }else{
      stateObj['hasUppercase'] = false;
    }

    // Does the password include 1 lowercase
    if(/.*[a-z]{1,}.*/.test(password)){
      stateObj['hasLowercase'] = true;
    }else{
      stateObj['hasLowercase'] = false;
    }

    // Does the password include special characters, whitespace or numbers
    if(/.*[$*.\^[\]{}()?"!@#%&/\\,><':;|_=\-+~`]{1,}.*/.test(password)){
      stateObj['hasSpecialChar'] = true;
    }else{
      stateObj['hasSpecialChar'] = false;
    }
    
    // Does the password include numbers
    if(/.*[0-9]{1,}.*/.test(password)){
      stateObj['hasNumber'] = true;
    }else{
      stateObj['hasNumber'] = false;
    }

    // Is the password at least 8 characters
    if(password.length >= 8){
      stateObj['isLongEnough'] = true;
    }else{
      stateObj['isLongEnough'] = false;
    }

    stateObj['validPassword'] = stateObj.hasLowercase && stateObj.hasUppercase && stateObj.hasSpecialChar && stateObj.isLongEnough && stateObj.hasNumber;
    
    this.setState(stateObj);
    
  }

  render(){
    return(
      <div className={(typeof this.props.className !== 'undefined') ? this.props.className : ''} >
        <div className="password-criteria-row">
          <div className="password-criteria-row-check">
            <FontAwesomeIcon icon={["far", (this.state.isLongEnough ? "check-circle" : "circle")]} />
          </div>
          <div className="password-criteria-row-text">
            <Trans>At least 8 characters long</Trans>
          </div>
        </div>

        <div className="password-criteria-row">
          <div className="password-criteria-row-check">
            <FontAwesomeIcon icon={["far", (this.state.hasLowercase ? "check-circle" : "circle")]} />
          </div>
          <div className="password-criteria-row-text">
            <Trans>One lowercase character</Trans>
          </div>
        </div>

        <div className="password-criteria-row">
          <div className="password-criteria-row-check">
            <FontAwesomeIcon icon={["far", (this.state.hasUppercase ? "check-circle" : "circle")]} />
          </div>
          <div className="password-criteria-row-text">
            <Trans>One uppercase character</Trans>
          </div>
        </div>

        <div className="password-criteria-row">
          <div className="password-criteria-row-check">
            <FontAwesomeIcon icon={["far", (this.state.hasSpecialChar? "check-circle" : "circle")]} />
          </div>
          <div className="password-criteria-row-text">
            <Trans>One of these special characters</Trans><span id='validSpecChars'>{" (ex. ^$*.[]{}()?\"!@#%&/\\,><':;|_=+-~`)"}</span>
          </div>
        </div>

        <div className="password-criteria-row">
          <div className="password-criteria-row-check">
            <FontAwesomeIcon icon={["far", (this.state.hasNumber ? "check-circle" : "circle")]} />
          </div>
          <div className="password-criteria-row-text">
            <Trans>One number</Trans>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordCriteria;
